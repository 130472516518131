import LiftSBCLInstallationProvider from "Providers/LiftSBCLInstallationProvider";
import LiftSBCLLogsProvider, {
  LiftSBCLLogsContext,
} from "Providers/LiftSBCLLogsProvider";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from "./LiftSBCLInstallation.module.scss";
import LiftSBCLLogsTable from "./LiftSBCLLogsTable";

const LiftSBCLInstallationInner = () => {
  const liftSBCLLogsProvider = useContext(LiftSBCLLogsContext);
  const [loading, setLoading] = useState(false);
  const [sbclLogs, setSBCLLogs] = useState<any>({});
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);

    liftSBCLLogsProvider
      .fetch()
      .then((response) => {
        setSBCLLogs(response);
        console.log(response, "logs response");
      })
      .catch(() => setError(true));

    setLoading(false);
  }, []);
  return (
    <>
      {loading ? (
        <>Loading</>
      ) : error ? (
        <h2 style={{color: 'red'}}>
          <FormattedMessage id="sbcl.error" />
        </h2>
      ) : (
        <div className={classes.sbclInstallationWrapper}>
          <div className={classes.grid}>
            {/* Logs */}
            <div>
              {sbclLogs ? <LiftSBCLLogsTable logData={sbclLogs} /> : <></>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const LiftSBCLInstallation = () => {
  return (
    <LiftSBCLInstallationProvider>
      <LiftSBCLLogsProvider>
        <LiftSBCLInstallationInner />
      </LiftSBCLLogsProvider>
    </LiftSBCLInstallationProvider>
  );
};
export default LiftSBCLInstallation;
