import { LiftStatusBaseApi } from "Api/Generic";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { createContext, useContext, useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { LiftStatusStorage } from "Utils/Storage";
import { EGlobalStateCases, GlobalStateContext } from "./GlobalStateProvider";
import { useIntl } from "react-intl";

// TO:DO NEEDS A REWORK, REACT QUERY ERROR HANDLING IS NOT WORKING CORRECTLY
interface ILoginProviderProps {
  children: any;
}

export const LoginContext = createContext(null);

const LoginProvider = ({ children }: ILoginProviderProps) => {
  const [, globalStateDispatch] = useContext(GlobalStateContext);
  const [fetchingCurrentUser, setFetchingCurrentUser] = useState(false);
  const history = useHistory();
  const [loginPayload, setLoginPayload] = useState();
  const alert = useAlert();
  const intl = useIntl();

  // Enabled here will only trigger the login function when loginPayload changes
  // or has a value.
  //
  const { status, data } = useQuery(
    ["login", { payload: loginPayload }],
    callLogin,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
      enabled: !!loginPayload
    }
  );

  async function callLogin(_key, { payload }) {
    const response = await LiftStatusBaseApi.post({
      uri: "/liftstatus/auth",
      payload,
    });

    const responseData = await response?.data;

    if (!responseData) {
      // Unset payload so we can try again
      //
      setLoginPayload(null);

      if ( response?.response?.status === 401 ) {
        alert.show(intl.formatMessage({ id: "login.failed" }), { type: "error", position: "top left" });
      } else {
        alert.show(`Login error: ${response}`, { type: "error", position: "top left" });
      }
    }

    return responseData;
  }

  const fetchCurrentUser = React.useCallback(async () => {
    setFetchingCurrentUser(true);
    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/user`,
      });

      const data: LiftStatus.Schemas.IUser = response?.data;

      // If no organisation is selected go with the one from the login
      //
      if (!LiftStatusStorage.organisationId) {
        globalStateDispatch({
          type: EGlobalStateCases.setOrganisationId,
          payload: data?.organisation_id,
        });
      }

      // Store username
      //
      globalStateDispatch({
        type: EGlobalStateCases.userName,
        payload: data?.username,
      });

      // Store user roles
      //
      globalStateDispatch({
        type: EGlobalStateCases.userRoles,
        payload: data?.roles,
      });

      const isClientLight = data.roles.find(
        (role) => role.name === "Client light"
      );

      if ( isClientLight ) {
        globalStateDispatch({
          type: EGlobalStateCases.userClientLight,
          payload: true,
        });
      }

      const isMechanic = data.roles.find(
        (role) => role.name === "Monteur"
      );

      if ( isMechanic ) {
        globalStateDispatch({
          type: EGlobalStateCases.userMechanic,
          payload: true,
        });
      }

      // Set initial dates in localstorage when nothing is set
      //
      if (!LiftStatusStorage.startDate || !LiftStatusStorage.endDate) {
        globalStateDispatch({
          type: EGlobalStateCases.setDatesLocal,
          payload: {
            startDate: moment().startOf("year").toDate(),
            endDate: moment().endOf("year").toDate(),
          },
        });
      }

      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      setFetchingCurrentUser(true);
      const deepLinkEventId = localStorage.getItem("deep-link-event-id");
      if (deepLinkEventId) {
        history.push(`events/${deepLinkEventId}`);
      } else {
        history.push("dashboard");
      }
    }
  }, [history, globalStateDispatch]);


  // Recalls this function
  const QueryLogin = React.useCallback(async () => {
    if (status === "loading") return status;

    if (!isEmpty(data) && !fetchingCurrentUser) {
      LiftStatusStorage.userToken = data.accessToken;

      // Store token
      //
      globalStateDispatch({
        type: EGlobalStateCases.logIn,
        payload: data.accessToken,
      });

      await fetchCurrentUser();

      return data;
    }
  }, [
    status,
    data,
    fetchingCurrentUser,
    globalStateDispatch,
    fetchCurrentUser,
  ]);

  return (
    <LoginContext.Provider
      value={{
        response: QueryLogin(),
        setPayload: setLoginPayload,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
