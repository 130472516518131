import { CircularProgress } from "@material-ui/core";
import BarChart from "Components/BarChart/BarChart";
import ErrorComponent from "Components/ErrorComponent/ErrorComponent";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import { IGetModuleSensorData } from "Providers/SenseModuleProvider";
import { SenseModuleSeries } from "Providers/SenseModuleSeriesProvider";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classes from "./EnergyUsageChart.module.scss";

interface IEnergyRow {
  date_value: string;
  duration: number;
  next_ts: string;
  next_val: number;
  pos: number;
  prev_duration: number;
  prev_ts: string;
  prev_val: string;
  ts: string;
  val: number;
}

interface IEnergyChartProps {
  liftId: number;
  from: string;
  to: string;
}



// eslint-disable-next-line react/display-name
const EnergyUsageChart = React.memo(({ from, to, liftId }: IEnergyChartProps) => {
  const seriesModuleProvider = useContext(SenseModuleSeries);
  const [energyData, setEnergyData] = useState<IEnergyRow[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);

    seriesModuleProvider
      .fetchSensorSeriesData({
        liftId: liftId,
        sensors: ["109"],
        from: moment(from).year(),
        to: moment(to).year() + 1
      })
      .then((data: IGetModuleSensorData[]) => {
        if (!isEmpty(data)) {
          // In this case index 0 will always be sensor 109
          // since this is the one we put in the sensors array for the request
          // the reason I made it an array is to handle
          // multiple sensors in the future, but for this component
          // 1 sensor is all we need and there for its always 0
          //
          const filteredData = data[0]?.rows?.filter(
            (val) => !isNil(val.next_val) && val.next_val > -1
          );
          setEnergyData(filteredData);
        } else {
          setEnergyData([]);
        }
      })
      .catch((e: any) => {
        console.error("[ENERGYUSEAGECHART] Error fetching data", e);
        // Handle error and set data to empty
        setEnergyData(null);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, liftId, to]);

  return (
    <React.Fragment>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) : energyData ? (
        energyData.length > 0 ? (
          <div
            style={{
              width: "100%",
              minHeight: "225px",
              marginTop: "16px",
            }}
          >
            <BarChart
              data={{
                labels: energyData?.map(
                  (data) =>
                    `${moment(data.date_value)
                      .startOf("week")
                      .format("DD MMM YY")} - ${moment
                      .utc(data.date_value)
                      .endOf("week")
                      .format("DD MMM YY")}`
                ),
                datasets: [
                  {
                    label: intl.formatMessage({ id: "energy.usage" }),
                    // data is in Wh, plot in kWh with 2 decimals max
                    data: energyData?.map(
                      (val) => Math.round((val.next_val - val.val) / 10) / 100
                    ),
                    backgroundColor: energyData?.map(() => "#86b29c"),
                    type: "bar",
                  },
                ],
              }}
              legend={{
                display: true,
              }}
            />
          </div>
        ) : (
          ""
        )
      ) : (
        <ErrorComponent />
      )}
    </React.Fragment>
  );
});
 
export default EnergyUsageChart;