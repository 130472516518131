import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import { useSenseModuleSummary } from "Providers/SenseModuleSummaryProvider";
import React, { useEffect, useState } from "react";
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./ElevatorSummary.module.scss";

export interface IGetModuleSensorSummaryData {
  rows: {
    end: number;
    from_ts: string;
    pos: number;
    start: number;
    to_ts: string;
  }[];
  sensor: {
    high_volume: string;
    id: string;
    internal: string;
    name: string;
    type: string;
  };
}

const ElevatorSummary = () => {
  const sensorModuleSummaryProvider = useSenseModuleSummary();
  const [stops, setStops] = React.useState<number[]>([]);
  const [frontMovement, setFrontMovement] = React.useState<number[]>([]);
  const [frontMovementRatio, setFrontMovementRatio] = React.useState<number[]>(
    []
  );
  const [frontMovementPerStop, setFrontMovementPerStop] = React.useState<
    number[]
  >([]);
  const [frontBounce, setFrontBounce] = useState<number[]>([]);
  const [totalRides, setTotalRides] = useState<number>(null);
  const [directionChanges, setDirectionChanges] = useState<number>(null);
  const [energyUsage, setEnergyUsage] = useState<number>(null);
  const [frontDoorCycles, setFrontDoorCycles] = useState<number>(null);
  const [
    totalFrontDoorMovements,
    setTotalFrontDoorMovements,
  ] = useState<number>(null);
  const [frontDoorSwings, setFrontDoorSwings] = useState<number>(null);
  const [rearDoorSwings, setRearDoorSwings] = useState<number>(null);
  const [rearDoorMovements, setRearDoorMovements] = useState<number>(null);
  const [rearSwingDoorsQnt, setRearSwingDoorsQnt] = useState<number>(null);
  const [loading, setLoading] = useState(false);

  const fetchElevatorSummaryData = React.useCallback(() => {
    setLoading(true);
    if (!isEmpty(sensorModuleSummaryProvider)) {
      sensorModuleSummaryProvider
        .fetchModuleSensorData({
          from: moment(LiftStatusStorage.startDate),
          to: moment(LiftStatusStorage.endDate),
          sensors: [
            100,
            109,
            507,
            508,
            509,
            510,
            511,
            512,
            516,
            517,
            518,
            520,
            521,
            522,
          ],
        })
        .then((data: IGetModuleSensorSummaryData[]) => {
          fetchDataForState(data);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [sensorModuleSummaryProvider]);

  useEffect(() => {
    fetchElevatorSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LiftStatusStorage.startDate, LiftStatusStorage.endDate]);

  const fetchDataForState = (data: IGetModuleSensorSummaryData[]) => {
    if (!isEmpty(data)) {
      // Get the total stops (floors)
      // sensor 512
      const sensor = data?.find((item) => item.sensor.id === "512");
      const stopValues = [];

      if (sensor) {
        sensor.rows.forEach((row) => {
          const amountOfStopsForStop = row.end - row.start;
          stopValues.push(amountOfStopsForStop);
        });

        setStops(stopValues);
      }

      // Calculate front movments
      // sensor 510
      const frontValues = [];
      const frontRatioValues = [];
      const frontMovementSensor = data.find((item) => item.sensor.id === "510");

      frontMovementSensor.rows.forEach((row, index) => {
        if (!isNil(row.start)) {
          const rowValue = row.end - row.start;
          frontValues.push(rowValue);
          frontRatioValues.push(rowValue / stopValues[index]);
        }
      });

      setFrontMovementRatio(frontRatioValues);
      setFrontMovement(frontValues);

      // Calculate front movements per stop
      // sensor 518
      const frontPerStopValues = [];
      const frontMovementPerStopSensor = data.find(
        (item) => item.sensor.id === "518"
      );

      frontMovementPerStopSensor.rows.forEach((row) => {
        if (!isNil(row.start)) {
          const rowValue = row.end - row.start;
          frontPerStopValues.push(rowValue);
        }
      });

      // Get the total front bounce rates
      // sensor 522
      const frontBounceStop = [];
      const frontBounceRateSensor = data.find(
        (item) => item.sensor.id === "522"
      );

      frontBounceRateSensor.rows.forEach((row) => {
        if (!isNil(row.start)) {
          const bounceStopValue = row.end - row.start;
          frontBounceStop.push(bounceStopValue);
        }
      });

      setFrontMovementPerStop(frontPerStopValues);
      setFrontBounce(frontBounceStop);

      // Get the total amount of rides
      // sensor 100
      const serviceCounterSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "100"
      );

      const totalRides =
        serviceCounterSensor.rows[0]?.end - serviceCounterSensor.rows[0]?.start;
      setTotalRides(totalRides);

      // Get the total amount of direction changes
      // sensor 507
      const directionChangesSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "507"
      );

      const totalAmountOfDirectionChanges =
        directionChangesSensor.rows[0]?.end -
          directionChangesSensor.rows[0]?.start ?? 0;

      setDirectionChanges(totalAmountOfDirectionChanges);

      // Get total amount of Energy Use
      // sensor 109
      const energyUsageSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "109"
      );

      const totalEnergyUsage =
        energyUsageSensor.rows[0]?.end - energyUsageSensor.rows[0]?.start ??
        null;

      setEnergyUsage(totalEnergyUsage);

      // Get Total amount of Door Cycles (Front)
      //
      const frontDoorCyclesSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "508"
      );

      const frontDoorCycles =
        frontDoorCyclesSensor.rows[0]?.end -
          frontDoorCyclesSensor.rows[0]?.start ?? 0;

      setFrontDoorCycles(frontDoorCycles);

      // Get Total front door movements
      // sensor 516
      const frontDoorMovementsSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "516"
      );

      const frontDoorMovements =
        frontDoorMovementsSensor.rows[0]?.end -
          frontDoorMovementsSensor.rows[0]?.start ?? 0;

      setTotalFrontDoorMovements(frontDoorMovements);

      // Get total number of swing doors (?)
      // sensor 520

      const frontDoorSwingsSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "520"
      );

      const frontDoorSwings =
        frontDoorSwingsSensor.rows[0]?.end -
          frontDoorSwingsSensor.rows[0]?.start ?? 0;

      setFrontDoorSwings(frontDoorSwings);

      // Get total number of rear door swing
      // sensor 509
      const rearDoorSwingSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "509"
      );

      const rearDoorSwing =
        rearDoorSwingSensor.rows[0]?.end - rearDoorSwingSensor.rows[0]?.start ??
        0;

      setRearDoorSwings(rearDoorSwing);

      // Get total number of rear door movements
      // sensor 517
      const rearDoorMovementSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "517"
      );

      const rearDoorMovement =
        rearDoorMovementSensor.rows[0]?.end -
          rearDoorMovementSensor.rows[0]?.start ?? 0;

      setRearDoorMovements(rearDoorMovement);

      // Get total number of rear door swings (?)
      // sensor 521
      const rearSwingDoorQntSensor = data.find(
        (item: IGetModuleSensorSummaryData) => item.sensor.id === "521"
      );

      const rearSwingDoorQnt =
        rearSwingDoorQntSensor.rows[0]?.end -
          rearSwingDoorQntSensor.rows[0]?.start ?? 0;

      setRearSwingDoorsQnt(rearSwingDoorQnt);
    }
  };

  return (
    <React.Fragment>
      <TableContainer className={classes.tableContainer}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>
        ) : stops.length > 0 ? (
          <React.Fragment>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Stop</TableCell>
                  <TableCell align="right">Stops</TableCell>
                  <TableCell align="right">Deurcycli (voor)</TableCell>
                  <TableCell align="right">Deurcycli ratio (voor)</TableCell>
                  <TableCell align="right">Deurbewegingen (voor)</TableCell>
                  <TableCell align="right">Deurpendeling (voor) </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {frontMovement &&
                  frontMovementPerStop &&
                  frontBounce &&
                  stops?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{item}</TableCell>
                      <TableCell align="center">
                        {frontMovement[index]}
                      </TableCell>
                      <TableCell align="center">
                        {frontMovementRatio[index]?.toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        {frontMovementPerStop[index]}
                      </TableCell>
                      <TableCell align="center">{frontBounce[index]}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </React.Fragment>
        ) : (
          <></>
        )}
        <div className={classes.summaryData}>
          {totalRides ? (
            <span className={classes.textWrap}>
              <p>Aantal ritten:</p>
              <p>{totalRides}</p>
            </span>
          ) : (
            <></>
          )}

          {directionChanges ? (
            <span>
              <p>Aantal richtingsveranderingen:</p>
              <p>{directionChanges}</p>
            </span>
          ) : (
            <></>
          )}

          {energyUsage ? (
            <span>
              <p>Energie verbruik (wH):</p>
              <p>{energyUsage}</p>
            </span>
          ) : (
            <></>
          )}

          {frontDoorCycles ? (
            <span>
              <p>Aantal deurcycli (voor):</p>
              <p>{frontDoorCycles}</p>
            </span>
          ) : (
            <></>
          )}

          {totalFrontDoorMovements ? (
            <span>
              <p>Aantal deurbewegingen (voor):</p>
              <p>{totalFrontDoorMovements}</p>
            </span>
          ) : (
            <></>
          )}

          {frontDoorSwings ? (
            <span>
              <p>Aantal deurpendelingen (voor):</p>
              <p>{frontDoorSwings}</p>
            </span>
          ) : (
            <></>
          )}

          {rearDoorSwings ? (
            <span>
              <p>Aantal deurcycli (achter):</p>
              <p>{rearDoorSwings}</p>
            </span>
          ) : (
            <></>
          )}

          {rearDoorMovements ? (
            <span>
              <p>Aantal deurbewegingen (achter):</p>
              <p>{rearDoorMovements}</p>
            </span>
          ) : (
            <></>
          )}

          {rearSwingDoorsQnt ? (
            <span>
              <p>Aantal deurpendelingen (achter):</p>
              <p>{rearSwingDoorsQnt}</p>
            </span>
          ) : (
            <></>
          )}
        </div>
      </TableContainer>
    </React.Fragment>
  );
};

export default ElevatorSummary;
