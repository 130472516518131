import React from "react";
import { Card, CardContent, Button } from "@material-ui/core";
import classes from "./Attachment.module.scss";
import moment from 'moment';

export interface IAttachmentProps {
  attachment: LiftStatus.Schemas.IAttachment;
  doDownload: Function;
}

const Attachment = (props: IAttachmentProps) => {
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.leftColumn}>
          <span className={classes.title}>{props.attachment.description}</span>
          <span className={classes.subtitle}>{props.attachment.name} - {props.attachment.role}, geupload op: {moment(props.attachment.created_at).format('DD-MM-YYYY')}</span>
        </div>
        <Button onClick={ () => {
          props.doDownload();
        } }>
          Download
        </Button>
      </CardContent>
    </Card>
  );
};

export default Attachment;
