import React, { useContext, useEffect } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, TextField } from "@material-ui/core";
import { isEmpty, isFunction } from 'lodash';
import { EGlobalStateCases, GlobalStateContext, IGlobalState } from "Providers/GlobalStateProvider";
import { LiftStatusStorage } from "Utils/Storage";

export interface IOrganisationSelectorProps {
  onChange: (organisation: LiftStatus.Schemas.IOrganisation) => void;
}
const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 12,
    marginRight: 12,

  },
  option: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {}
}));

function OrganisationSelector(props: IOrganisationSelectorProps) {

  const classes = useStyles();

  const [options, setOptions] = React.useState<LiftStatus.Schemas.IOrganisation[]>([]);

  const [, setSearchQuery] = React.useState('');

  const [selectedOrganisation, setSelectedOrganisation] = React.useState<LiftStatus.Schemas.IOrganisation>();

  // Get context privders
  //
  const [globalState, globalDispatch] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

  /**
   * When organisation selected changed, regardless from where
   * we update the stored organisation id
   */
  useEffect(() => {
    if (selectedOrganisation && selectedOrganisation.id) {
      if (LiftStatusStorage.organisationId !== selectedOrganisation.id) {
        if (isFunction(props.onChange)) {
          props.onChange(selectedOrganisation);
        }
        globalDispatch({
          type: EGlobalStateCases.setOrganisationId,
          payload: selectedOrganisation.id,
        });
      }
    } else if (selectedOrganisation === null) {
      globalDispatch({
        type: EGlobalStateCases.setOrganisationId,
        payload: null,
      });
      if (isFunction(props.onChange)) {
        props.onChange(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  /**
   * Set the selected organisation initially
   */
  useEffect(() => {
    if (selectedOrganisation === undefined && options.length > 0) {
      // Scenario A: There is an organisation in the global state, no organisation has been selected yet
      // and there are options to choose from.
      //
      if (globalState.organisationId) {
        const hit = options.find(item => item.id === globalState.organisationId);
        if (hit) {
          setSelectedOrganisation(hit);
        }
        return;
      // Scenario B: There is no organisation in the global state, no organisation has been selected and there
      // are still options to choose from
      //
      } else {
        setSelectedOrganisation(null);
      }
    }

  }, [globalState.organisationId, selectedOrganisation, options]);


  useEffect(() => {
    if ( globalState.organisationId === null ) {
      setSelectedOrganisation(null);
    }
  }, [globalState.organisationId])
  /**
   * Handle API response from Lifts route
   */
  useEffect(() => {
    if (!isEmpty(globalState.organisationList) && isEmpty(options)) {
      if (globalState.organisationList?.length > 0) {
        setOptions([...globalState.organisationList]);
      }
    }
  }, [globalState.organisationList, options]);

  return(
    <>
      {options.length > 0 && selectedOrganisation !== undefined && (
        <Autocomplete

          // Removing the id disabled browser auto-fill
          // See: https://github.com/mui-org/material-ui/issues/18556
          // id="organisatie-search"
          options={options}
          className={classes.root}
          classes={{
            option: classes.option,
          }}
          defaultValue={selectedOrganisation}
          key={selectedOrganisation ? "loaded" : "not loaded"} // Small hack to force re-render of default value
          autoHighlight
          getOptionLabel={(option: LiftStatus.Schemas.IOrganisation) => option.name}
          renderOption={(option: LiftStatus.Schemas.IOrganisation) => option.name}
          onChange={(_event, newValue: LiftStatus.Schemas.IOrganisation) => {
            if (newValue) {
              setSelectedOrganisation({...newValue});
            } else {
              setSelectedOrganisation(null);
            }
          }}
          onInputChange={(_event, newInputValue) => {
            setSearchQuery(newInputValue);
          }}
          getOptionSelected={(option, value) => option.id === value.id }
          renderInput={(params) => (
            <TextField
            variant="standard"
              {...params}
              label="Kies een organisatie"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) }
    </>
  );
}

export default OrganisationSelector;