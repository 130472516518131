import { LiftStatusBaseApi } from "Api/Generic";
// eslint-disable-next-line no-unused-vars
import Axios, {
  AxiosRequestConfig,
  CancelToken,
  ResponseType
} from "axios";
import { each, get } from "lodash";
import moment from "moment";
import { publish } from "pubsub-js";
import io from "socket.io-client";
import env from "../Assets/env.json";

export interface ILiftAccessToken {
  token: string;
  liftId: number;
}

interface SocketDetails {
  socket?: SocketIOClient.Socket;
  accessToken: string;
  resubscriberTimer?: NodeJS.Timeout;
  listeningForModules: string[];
}

export interface ILiftStatusSocketApiOptions {
  liftId: number;
  uri: string;
  responseType?: ResponseType;
  payload?: any;
  urlParameters?: any;
  cancelToken?: CancelToken;
}

class SocketClient {
  /**
   * The URL of the Socket IO server of the LS Classic platform
   *
   * @type {string}
   * @memberof SocketClient
   */
  serverUrl: string;

  /**
   * The URL of the Socket IO server of the LS Next platform
   *
   * @type {string}
   * @memberof SocketClient
   */
  nextServerUrl: string;

  sockets: {
    [liftId: number]: SocketDetails;
  };

  localAccess = localStorage.getItem("socket-access");
  lastAccessTokenAndLift: ILiftAccessToken = JSON.parse(this.localAccess);

  constructor(serverUrl: string, nextServerUrl: string) {
    this.sockets = {};
    this.serverUrl = serverUrl;
    this.nextServerUrl = nextServerUrl;
  }

  /**
   * Generic function for assembling the external id for a lift
   * @param liftId The lift for which we want the external id
   */
  getExternalIdForLift(liftId: number) {
    return `liftstatus-mi-${liftId}`;
  }

  /**
   * This function will create a access token for the external services on the LS NG Portal API
   * @param liftId The lift id for which to get the access token
   */
  public async generateExternalIdAccessToken(liftId: number) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      const bodyData = {
        identifier: this.getExternalIdForLift(liftId),
        identifierType: "ext",
      };

      try {
        const response = await LiftStatusBaseApi.post({
          uri: "/liftstatus/auth/sense-liftstatus",
          payload: bodyData,
        });

        if (response) {
          localStorage.setItem(
            "socket-access",
            JSON.stringify({
              token: response?.data?.accessToken,
              liftId: liftId,
            })
          );

          res(response.data?.accessToken);
        }
      } catch (error) {
        rej(error);
      }
    });
  }

  private async getTokenDate(token) {
    try {
      const value = await JSON.parse(atob(token.split(".")[1]));

      const expDate = moment.unix(value.exp).format();

      return expDate;
    } catch (e) {
      console.error(e);
    }
  }

  async generateTokenOrCheckValidity(liftId: number) {
    // Checking if the last created token was has an hour or less left,
    // if true, we will create a new token with a new date stamp.
    // we do this because the tokens are only valid for 24 hours.
    let token;

    if (this.lastAccessTokenAndLift?.liftId === liftId) {
      const expDate = await this.getTokenDate(
        this.lastAccessTokenAndLift.token
      );

      const now = moment();

      const secondsDifference = moment(expDate).diff(now, "seconds");

      if (secondsDifference < 30) {
        token = await this.generateExternalIdAccessToken(liftId);
      } else {
        token = this.lastAccessTokenAndLift.token;
      }

      return token;
    } else {
      token = await this.generateExternalIdAccessToken(liftId);
      return token;
    }
  }

  /**
   * This function will connect a websocket for the specific lift if there isn't one yet. Resolves on socket connection
   * @param liftId The lift which you want to connect the websocket for
   * @param accessToken An optional already generated accessToken. If not provided one will be generated for you
   */
  public connect(liftId: number, accessToken?: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      accessToken = await this.generateTokenOrCheckValidity(liftId);

      // we already have a socket for the lift
      //
      if (this.sockets[liftId]) {
        resolve(this.sockets[liftId].socket);
      }

      // If there is still no access token we can't continue
      //
      if (!accessToken) {
        throw Error("No access token to communicate with external Api");
      }

      // if no socket for this lift exists create an object with the access token on it
      // the socket itself will be assigned later
      //
      if (!this.sockets[liftId]) {
        this.sockets[liftId] = {
          accessToken,
          listeningForModules: [],
        };
      }

      // lets assign the details to a variable so we don't have to keep accessing this.sockets
      //
      const socketDetails = this.sockets[liftId];

      socketDetails.accessToken = accessToken;

      this.lastAccessTokenAndLift = {
        liftId,
        token: accessToken,
      };

      // if no socket has been created yet for this lift id but we do have an access token (this should always be the case)
      // create a socket
      //
      if (!socketDetails.socket && socketDetails.accessToken) {
        this.setSocketOnSocketDetails(socketDetails);
        // if we already have a socket but our accessToken changed close the current socket for this lift and update the access token
        // then create a new socket with the new access token
        //
      } else if (
        socketDetails.socket &&
        accessToken &&
        socketDetails.accessToken !== accessToken
      ) {
        socketDetails.socket.close();

        socketDetails.accessToken = accessToken;
        this.setSocketOnSocketDetails(socketDetails);
      }

      // if for some reason we don't have a socket here throw an error as it will anyway later but this way
      // we control the error message
      //
      if (!socketDetails.socket) {
        reject("No socket available");
      }

      // from here start listening on the different events and act accordingly
      //
      socketDetails?.socket?.on("connect", (e) => {
        console.log("[SOCKET-CLIENT] socket connected", e);
        socketDetails.resubscriberTimer = setInterval(() => {
          this.setSocketOnSocketDetails(socketDetails);
        }, 60 * 1000);

        resolve(socketDetails.socket);
      });

      socketDetails?.socket?.on("error", async (e) => {
        console.log("[SOCKET-CLIENT] Socket error", e);
        publish("socket.error", e);

        // Remove and reset a token in case of a corrupt token
        localStorage.removeItem("socket-access");
        this.lastAccessTokenAndLift = null;
        reject(e);
      });

      socketDetails?.socket?.on("disconnect", (e) => {
        console.log("[SOCKET-CLIENT] Socket disconnect", e);
        publish("socket.disconnect", e);
        clearTimeout(socketDetails.resubscriberTimer);
        socketDetails.socket = null;
        reject(e);
      });

      socketDetails?.socket?.on("module", (data) => {
        // console.log('[SOCKET-CLIENT] Module data', data);
        const esNumber = data.es;
        const messageType = data.message.f;
        publish(`module.${esNumber}.${messageType}`, data);
      });

      socketDetails?.socket?.on("provisioning", (data) => {
        // console.log( "[SOCKET-CLIENT] Provisioning", data );
        const esNumber = data.es;
        const messageType = data.message.e;
        publish(`provisioning.${esNumber}.${messageType}`, data);
      });
    });
  }

  /**
   * Private function to create the connection to the websocket.
   * @param socketDetails The details of the socket that we defined earlier (including accesstoken)
   */
  private setSocketOnSocketDetails(socketDetails: SocketDetails) {
    const connectURL = `${this.serverUrl}/?access_token=${socketDetails.accessToken}`;

    socketDetails.socket = io.connect(connectURL, {
      path: "/liftstatus/socket.io",
      reconnectionAttempts: 10,
      transports: ["websocket"],
    });
  }

  /**
   * Generic function to retrieve the socketDetails for a lift
   * @param liftId The lift id of which you want the details
   */
  private getSocketDetails(liftId: number) {
    const socketDetails = this.sockets[liftId];

    if (!socketDetails) {
      throw new Error("Socket not created");
    }

    if (!socketDetails.socket) {
      throw new Error("Socket not connected");
    }

    return socketDetails;
  }

  /**
   * Retrieves the external id access token for the lift
   * @param liftId
   */
  public getAccessToken(liftId: number) {
    const socketDetails = this.getSocketDetails(liftId);
    return socketDetails?.accessToken;
  }
  /**
   * Function to send something over a socket connection. probably will be rarely called directly
   * @param liftId The lift id for which you want to call the websocket
   * @param eventName The name of the event
   * @param data The data for the event
   */
  public send(liftId: number, eventName: string, data: any) {
    return new Promise((resolve, reject) => {
      try {
        const socketDetails = this.getSocketDetails(liftId);

        const socket = socketDetails.socket;

        socket.emit(eventName, data, (error, data) => {
          if (error) {
            reject(error);
          } else if (get(data, "error")) {
            reject(data.error);
          } else {
            resolve(data);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  ///////////////////////////////////////////////////////////
  // Gate Module related calls
  ///////////////////////////////////////////////////////////
  //
  public listenModule(liftId: number, esNumber: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const socketDetails = this.getSocketDetails(liftId);

        // Check if already subscribed
        //
        if (socketDetails.listeningForModules.includes(esNumber)) {
          resolve("Already listening");
        } else {
          const sendResponse = await this.send(liftId, "listen", {
            es_number: esNumber,
          });
          socketDetails.listeningForModules.push(esNumber);
          resolve(sendResponse);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  unlistenModule(
    liftId: number,
    esNumber: string,
    forceUnlisten: boolean = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const socketDetails = this.getSocketDetails(liftId);

        // Check if subscribed
        //
        if (
          !socketDetails.listeningForModules.includes(esNumber) &&
          !forceUnlisten
        ) {
          resolve(undefined);
        } else {
          const sendResponse = await this.send(liftId, "unlisten", {
            es_number: esNumber,
          });
          socketDetails.listeningForModules.splice(
            socketDetails.listeningForModules.indexOf(esNumber),
            1
          );
          resolve(sendResponse);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  getSensorData(liftId: number, esNumber: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const sendResponse = await this.send(liftId, "getSensorData", {
          es_number: esNumber,
        });
        resolve(sendResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  getModuleStatus(liftId: number, esNumber: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const sendResponse = await this.send(liftId, "getModuleStatus", {
          es_number: esNumber,
        });
        resolve(sendResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  //////////////////////////////////

  public connectToNextSocket = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const token = window.localStorage.getItem("liftstatus-user-token");

      const socket = io(this.nextServerUrl, {
        transports: ["websocket"],
        path: "/liftstatus/socket.io",
        reconnectionAttempts: 5,
        query: {
          access_token: token,
        },
      });

      if (socket) {
        if (!socket.connected) {
          socket.on("connect", () => {
            resolve(socket);
          });
        } else {
          resolve(socket);
        }

        socket.on("disconnect", (e) => {
          console.log("Disconnected next socket");
          reject("discounnected");
        });
      }
    });
  };

  ///////////////////////////////////////////////////////////
  // LS NG Portal API related calls
  ///////////////////////////////////////////////////////////
  //
  /**
   * Headers are always the same for all calls
   */
  getHeaders(accessToken: string) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
  }

  /**
   * If URL parameters are provided as an object
   * Create nice URL parameters for them
   */
  getUrlParameters(input: any) {
    let optionalParams = undefined;
    if (input) {
      const params = new URLSearchParams();
      each(input, (value, prop) => {
        params.append(prop, value);
      });
      optionalParams = params;
    }
    return optionalParams;
  }

  /**
   * Generic Axios post method wrapper
   * @param options: LiftStatusBaseApiOptions
   */
  async post(options: ILiftStatusSocketApiOptions) {
    // First try with the last used access token
    const accessToken = await this.generateTokenOrCheckValidity(options.liftId);

    if ( this.lastAccessTokenAndLift ) {
      this.lastAccessTokenAndLift.liftId = options?.liftId;
      this.lastAccessTokenAndLift.token = accessToken;
    }
 

    // If there is still no access token we can't continue
    //
    if (!accessToken) {
      throw Error("No access token to communicate with external Api");
    }

    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(accessToken),
      cancelToken: options.cancelToken,
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }

    try {
      const response = await Axios.post(
        `${this.serverUrl}${options.uri}`,
        options.payload,
        config
      );

      return response;
    } catch (error) {
      if (Axios.isCancel(error)) {
        // We dont throw here because we dont want the consumer to get an error
        // and display that to the user since we only get to this 
        // statement if the user has canceled a previous running request
        // to trigger a new request.
        // we're logging for our own purposes
        console.error("Request canceled", error.message);
      } else {
        if (error.response) {
          // Request made and server responded
          throw error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          throw error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          throw error.message;
        }
      }
    }
  }

  async get(options: ILiftStatusSocketApiOptions) {
    const accessToken: string = await this.generateTokenOrCheckValidity(
      options.liftId
    );

    // Check if there is a list used access token
    //

    if (!accessToken) {
      throw Error("No access token to communicate with external Api");
    }

    this.lastAccessTokenAndLift = {
      token: accessToken,
      liftId: options.liftId,
    };

    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(accessToken),
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }
    return Axios.get(`${this.serverUrl}${options.uri}`, config).catch(
      (error) => error
    );
  }
}

export const socketClient = new SocketClient(env.socket.endpoint, env.endpoint);
