import { LiftStatusBaseApi } from "Api/Generic";
import moment from "moment";
import React, { createContext } from "react";

interface ILiftCounterDataProviderProps {
  children: any;
}

interface ILiftStatsRequest {
  liftId?: number;
  from?: string;
  to?: string;
  type: "daily" | "weekly";
}

export const LiftCounterDataContext = createContext(null);

const LiftCounterDataProvider = ({
  children,
}: ILiftCounterDataProviderProps) => {

  const dispatchLiftCounterDataRequest = async (request: ILiftStatsRequest) => {

    if (!request.liftId) {
      return;
    }

    const urlParameters: ILiftStatsRequest = {
      type: request.type,
    };


    if (request.from) {
      urlParameters.from = moment(request.from).toISOString();
    }

    if (request.to) {
      urlParameters.to = moment(request.to).toISOString();
    }

    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${request.liftId}/service-counter`,
        urlParameters,
      });

      return response?.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return (
    <LiftCounterDataContext.Provider
      value={{
        dispatchLiftCounterDataRequest: dispatchLiftCounterDataRequest,
      }}
    >
      {children}
    </LiftCounterDataContext.Provider>
  );
};

export function useLiftCounterData() {
  const context = React.useContext(LiftCounterDataContext);
  if (context === undefined) {
    throw new Error(
      "The useLiftCounterData hook must be used within a LiftCounterDataContext.Provider"
    );
  }
  return context;
}

export default LiftCounterDataProvider;
