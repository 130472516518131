import { Grid } from "@material-ui/core";
import { useSenseModule } from "Providers/SenseModuleProvider";
import React, { useContext } from "react";
import { get, first, keys, values } from "lodash";
import statusCodes from "../../Assets/status-codes.json";
import { timeAgoFromMilliseconds } from "Utils/Helpers";
import ElevatorInfoField from "./ElevatorInfoField";
import moment from "moment";
import {
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import { FormattedMessage } from "react-intl";

export interface IElevatorRealtimeStatus {
  value: string;
  id: string;
  level: string;
  datetime: string;
  ts: number;
}

interface IElevatorRealtimeStatusProps {
  liftData?: LiftStatus.Schemas.ILift;
}

const ElevatorRealtimeStatuses = ({
  liftData,
}: IElevatorRealtimeStatusProps) => {
  const [globalState] = useContext<[IGlobalState, React.Dispatch<any>]>(
    GlobalStateContext
  );
  const senseModuleProvider = useSenseModule();
  const [statuses, setStatuses] = React.useState<IElevatorRealtimeStatus[]>([]);

  const processSensor513Update = React.useCallback((value: string) => {
    const result: IElevatorRealtimeStatus[] = [];
    if (value) {
      const items = value.split(",");
      for (const item of items) {
        const parts = item.split(":");
        const identifier = get(parts, "0");
        const ts = parseInt(get(parts, "1"), 10);
        const active = parseInt(get(parts, "2"));
        const status = get(statusCodes, identifier);

        if (active === 1) {
          result.push({
            ts,
            value: status?.description ?? identifier,
            id: identifier,
            datetime: moment(ts).format("D MMM YYYY H:mm:ss"),
            level: status?.level,
          });
        }

        setStatuses(result);
      }
    }
  }, []);

  // Initial sensor values
  //
  React.useEffect(() => {
    const values = get(senseModuleProvider, "senseModuleSensorData.values");
    if (values) {
      for (const sensorId in values) {
        if (`${sensorId}` === "513") {
          if (
            globalState?.userOrganisationType?.match(/^(admin|main)$/) ||
            liftData?.alarm_organisation_id === globalState.organisationId
          ) {
            processSensor513Update(values[sensorId]);
          } else {
            console.log(
              "[ElevatorRealtimeStatuses]: Not main, admin or same alarm organisation, not displaying s513."
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.senseModuleSensorData]);

  // when ever the socket emits data handle the sensor data update
  //
  React.useEffect(() => {
    if (senseModuleProvider && senseModuleProvider.movementPoints) {
      const update = get(senseModuleProvider.movementPoints, "message.data");
      const sensorId = first(keys(update));
      const value = first(values(update));
      if (`${sensorId}` === "513") {
        processSensor513Update(value);
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.movementPoints]);

  return (
    <Grid container>
      <h2>
        <FormattedMessage id="current.statuses" />
      </h2>
      {statuses.length > 0 ? (
        <React.Fragment>
          {statuses.map((status, index) => (
            <Grid key={index} item xs={12}>
              <ElevatorInfoField
                level={status.level as any}
                label={status.id}
                value={status.value}
                dateTime={status.datetime}
                badge={timeAgoFromMilliseconds(status.ts)}
                invertBadgeColor={true}
                hr={false}
              />
            </Grid>
          ))}
        </React.Fragment>
      ) : (
        <Grid item xs={12}>
          <b>
            <FormattedMessage id="no.statuses" />
          </b>
        </Grid>
      )}
    </Grid>
  );
};

export default ElevatorRealtimeStatuses;
