import { LiftStatusBaseApi } from "Api/Generic";
import { useCallback, useState } from 'react';
import { LiftStatusStorage } from "Utils/Storage";

const useCallReportService = (options: { subjectId: number, report: string }): [ boolean, () => Promise<void> ] => {
  const [loading, setLoading] = useState(false);

  const fetchReport = useCallback( async (): Promise<void> => {
    setLoading(true);

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await LiftStatusBaseApi.post({
        uri: `/liftstatus/report/pdf`,
        responseType: 'blob',
        payload: {
          subjectId: options.subjectId,
          report: options.report,
          from: LiftStatusStorage.startDate,
          to: LiftStatusStorage.endDate,
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `liftstatus-${options.report}-report-${options.subjectId}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('[useCallReportService] error', error);
    }

    setLoading(false);
  }, [options.subjectId, options.report]);

  return [loading, fetchReport];
};

export default useCallReportService;