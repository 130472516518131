import { socketClient } from "Api/Socket";
import { isEmpty } from "lodash";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { IGetModuleSensorDataResponse } from "./SenseModuleProvider";

interface ISenseModuleProviderHistoryProps {
  esNumber: string;
  children: any;
}

export interface IGetModuleSensorDataRequest {
  from: moment.Moment;
  to: moment.Moment;
  sensors: number[];
}

export const SenseModuleSummary = createContext(null);

const SenseModuleSummaryProvider = ({
  children,
  esNumber,
}: ISenseModuleProviderHistoryProps) => {
  const { liftId } = useParams();

  const fetchModuleSensorData = async (
    request: IGetModuleSensorDataRequest
  ) => {
    /**
     * There must be a request to do this
     */
    if (!request) {
      return;
    }

    try {
      const response = (await socketClient.post({
        liftId,
        uri: `/liftstatus/external/${socketClient.getExternalIdForLift(
          liftId
        )}/sensor/summary`,
        urlParameters: {
          from: request.from.format(),
          to: request.to.format(),
          sensors: request.sensors,
        },
      })) as IGetModuleSensorDataResponse;

      if (!isEmpty(response)) {
        return response?.data;
      }
    } catch (error) {
      return error;
    }
  };


  return (
    <SenseModuleSummary.Provider
      value={{
        fetchModuleSensorData,
        esNumber,
      }}
    >
      {children}
    </SenseModuleSummary.Provider>
  );
};

export function useSenseModuleSummary() {
  const context = React.useContext(SenseModuleSummary);
  if (context === undefined) {
    throw new Error(
      "The useSenseModule hook must be used within a SenseModuleContext.Provider"
    );
  }
  return context;
}

export default SenseModuleSummaryProvider;
