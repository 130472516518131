import { LiftStatusBaseApi } from "Api/Generic";
import Axios from "axios";
import React, { createContext, useState } from "react";

interface IPeriodicalCounterSumProps {
  children: any;
}

interface IPeriodicalCounterSumRequest {
  liftId: number;
  from: string; // YYYY-MM
  to: string; // YYYY-MM 
  sensorId: number;
}

export const PeriodicalCounterSumContext = createContext(null);

const PeriodicalCounterSumStatsProvider = ({ children }: IPeriodicalCounterSumProps) => {
  const [cancelToken, setCancelToken] = useState(null);

  const fetchPeriodicalCounterSumStats = async (request: IPeriodicalCounterSumRequest) => {
    // Cancel the previous call (if there is a token) before triggering a new call
    if (cancelToken) {
      cancelToken.cancel("Cancel");
      setCancelToken(null);
    }

    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelToken(source);

    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${request.liftId}/${request.sensorId}/counter-summary/`,
        urlParameters: {
          from: request.from,
          to: request.to,
        },
        cancelToken: source?.token,
      });

      const data: LiftStatus.Schemas.IStatsOverviewDto = response?.data;
      return data;
    } catch (error) {
      console.error("[DASHBOARDSTATSPROVIDER]", error);
      throw error;
    }
  };

  return (
    <PeriodicalCounterSumContext.Provider
      value={{
        fetch: fetchPeriodicalCounterSumStats,
      }}
    >
      {children}
    </PeriodicalCounterSumContext.Provider>
  );
};

export default PeriodicalCounterSumStatsProvider;
