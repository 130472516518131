import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext, useContext } from "react";
import { GlobalStateContext, IGlobalState } from "./GlobalStateProvider";

interface ILiftStatsProviderProps {
  children: any;
}

interface ILiftStatsRequest {
  liftId?: number;
  from: string;
  to: string;
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
}

export const LiftStatsContext = createContext(null);

const LiftStatsProvider = ({ children }: ILiftStatsProviderProps) => {
  const [globalState] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

  const fetchLiftStats = async (request: ILiftStatsRequest) => {
    if (!request.liftId) {
      return;
    }
    let response;
    try {
      if (globalState.viewIsCurrent) {
        response = await LiftStatusBaseApi.get({
          uri: `/liftstatus/current-state`,
          urlParameters: {
            liftId: request.liftId,
          },
        });
      } else {
        response = await LiftStatusBaseApi.get({
          uri: `/liftstatus/lift/${request.liftId}/stats`,
          urlParameters: {
            from: request.from,
            to: request.to,
            startHour: request.startHour ?? 0,
            endHour: request.endHour ?? 0,
          },
        });
      }

      const data: LiftStatus.Schemas.IStatsOverviewDto = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return (
    <LiftStatsContext.Provider
      value={{
        fetch: fetchLiftStats,
      }}
    >
      {children}
    </LiftStatsContext.Provider>
  );
};

export default LiftStatsProvider;
