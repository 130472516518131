import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext } from "react";

interface ISingleEventDataProviderProps {
  children: any;
}

interface IEventDataRequest {
    eventId: number
}

export const SingleEventDataContext = createContext(null);

const SingleEventDataProvider = ({
  children,
}: ISingleEventDataProviderProps) => {

  const dispatchSingleEventDataRequest = async (request: IEventDataRequest) => {

    if (!request.eventId) {
      return;
    }

    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/event/${request.eventId}`
      });

      return response?.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return (
    <SingleEventDataContext.Provider
      value={{
        dispatchSingleEventDataRequest: dispatchSingleEventDataRequest,
      }}
    >
      {children}
    </SingleEventDataContext.Provider>
  );
};

export function useSingleEventData() {
  const context = React.useContext(SingleEventDataContext);
  if (context === undefined) {
    throw new Error(
      "The useSingleEventData hook must be used within a SingleEventDataContext.Provider"
    );
  }
  return context;
}

export default SingleEventDataProvider;
