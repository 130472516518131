import { GlobalStateContext } from "Providers/GlobalStateProvider";
import React, { Fragment, useContext } from "react";
import { Redirect  } from "react-router-dom";
import { useAlert } from "react-alert";

const PrivateRoutes = (props: React.ComponentProps<any>) => {
  const [globalState] = useContext(GlobalStateContext);
  const token = globalState.userToken;
  const alert = useAlert();


  if ( window.location.pathname.includes('events/') && !token) {
    const indexOfS = window.location.pathname.indexOf('s') + 2;
    const eventId = window.location.pathname.substring(indexOfS)
    localStorage.setItem('deep-link-event-id', eventId)
    alert.show('Please login to see requested lift event', { type: 'error' });
  }

  return <Fragment>{token ? props.children : <Redirect to={`/`} />}</Fragment>;
};

export default PrivateRoutes;
