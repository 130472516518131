import { CircularProgress } from "@material-ui/core";
import Visit from "Components/Visit/Visit";
import EventSearchDataProvider from "Providers/EventDataProvider";
import React from "react";
import VisitPayload from "Utils/VisitPayload";
import classes from "./Visits.module.scss";
interface VisitsProps {
  liftId?: number | string;
  visits: any;
  loading?: boolean;
  roleFilter?: 'maintenanceParties' | 'approvingAuthorities';
}

const VisitsInternal = (props: VisitsProps) => {
  let noResultLabel = 'Geen bezoeken';
  const visitPayload = new VisitPayload();

  switch ( props.roleFilter ) {
    case 'approvingAuthorities':
      noResultLabel = 'Geen keurings bezoeken';
      break;

    case 'maintenanceParties':
      noResultLabel = 'Geen onderhouds bezoeken';
      break;
  }

  const visits = props.visits.filter((visit: any) => {
    if (visit.payload) {
      visitPayload.setPayload(visit.payload);

      if ( props.roleFilter === 'approvingAuthorities' && visitPayload.role === 'ApprovingAuthority' ) {
        return true;
      }

      if ( props.roleFilter === 'maintenanceParties' && ( visitPayload.role === 'Mechanic' || visitPayload.role === 'Inspector' ) ) {
        return true;
      }
    }

    return false;
  });

  return (
    <>
      {props.loading ? (
        <div className={classes.progressIndicator}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.cardContent}>
          {!visits?.length && <p>{noResultLabel}</p>}

          {visits?.map((event: LiftStatus.Schemas.IEvent) => {
            return <Visit key={`event-${event.id}`} event={event}></Visit>;
          })}
        </div>
      )}
    </>
  );
};

const Visits = (props: VisitsProps) => (
  <EventSearchDataProvider>
    <VisitsInternal {...props} />
  </EventSearchDataProvider>
);

export default Visits;
