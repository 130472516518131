import { Chart } from "chart.js";
import React from "react";

export interface IBarChartDataSet {
  labels: string[];
  maintainAspectRatio?: boolean;
  datasets: {
    label: string;
    data: number[];
    type?: string;
    backgroundColor?: string[];
    borderColor?: string;
    barWidthPercentage?: number
  }[];
}

const BarChart = ({
  data,
  legend,
  maintainAspectRatio,
  barWidthPercentage,
}: {
  maintainAspectRatio?;
  barWidthPercentage?,
  data: IBarChartDataSet;
  legend?: {
    display?: boolean;
    position?: "top" | "left" | "bottom" | "right";
    align?: "start" | "center" | "end";
  };
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>();

  React.useEffect(() => {
    if (canvasRef.current) {
      new Chart(canvasRef.current, {
        type: "bar",
        options: {
          legend,
          maintainAspectRatio: maintainAspectRatio ?? true,
          scales: {
            xAxes: [
              {
                barPercentage: barWidthPercentage ?? 1.0,
                ticks: {
                  maxRotation: 35,
                  minRotation: 25,
                  fontSize: 10,
                },
              }
            ],
            yAxes: [
              {
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                  fontSize: 10,
                },
              },
            ],
          },
        },
        data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef]);

  return <canvas height="100px" ref={canvasRef} />;
};

export default BarChart;
