import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { CallEnd, PhoneCallback, PhoneInTalk } from "@material-ui/icons";
import AlertSenseDataModal from "Components/AlertSenseDataModal/AlertSenseDataModal";
import moment from "moment";
import { IExtendedAlarmData } from "Pages/CurrentCalls/CurrentCalls";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classes from "./ActiveAlertCard.module.scss";
import { isEmpty } from "lodash";

interface IAlarmCard {
  alarm: IExtendedAlarmData;
}

const ActiveAlertCard = ({ alarm }: IAlarmCard) => {
  const [timer, setTimer] = useState(0);
  const history = useHistory();
  let current = timer;

  const createTimer = () => {
    return current++;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      // If call ended we dont need the timer anymore as we show a total amount
      // of seconds the process lasted.
      //
      if (alarm.status === "ended") {
        clearInterval(timer);
        return;
      }

      setTimer(createTimer());
    }, 1000);

    return () => {
      clearInterval(timer);
      setTimer(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.card} key={alarm?.lift_id}>
      <CardContent>
        <div className={classes.titleBar}>
          <h4>
            {alarm?.lift_data?.details?.city},
            {" " + alarm?.lift_data?.details?.address}
          </h4>

          <div className={classes.moreData}>
            {alarm.status === "answered" ? (
              <p>Aangenomen door: {alarm?.answered_by_description}</p>
            ) : alarm.status === "queued" ? (
              <p>Inkomend</p>
            ) : (
              ""
            )}
          </div>

          {alarm.status === "ended" ? (
            <div className={classes.timers}>
              <p>{"Wachttijd: " + alarm.queued_duration + " sec"}</p>
              <p>{"Gespreksduur: " + alarm.answered_duration + " sec"}</p>
            </div>
          ) : alarm.status === "answered" ? (
            <div className={classes.timers}>
              <p>{"Wachttijd: " + alarm.queued_duration + " sec"}</p>
              <p>
                {moment.duration(moment().diff(alarm.answered_at)).seconds() +
                  " sec in gesprek"}
              </p>
            </div>
          ) : alarm.status === "queued" ? (
            moment.duration(moment().diff(alarm.queued_at)).seconds() +
            " sec wachten"
          ) : (
            <> </>
          )}

          {alarm.status === "ended" ? (
            <CallEnd className={classes.icon} style={{ color: "#F3776D" }} />
          ) : alarm.status === "answered" ? (
            <PhoneInTalk
              className={classes.icon}
              style={{ color: "#86B29C" }}
            />
          ) : alarm.status === "queued" ? (
            <PhoneCallback
              className={classes.icon}
              style={{ color: "#F3C46D" }}
            />
          ) : (
            <> </>
          )}
        </div>

        <p>
          {alarm?.lift_data?.details?.manufacturernr ? (
            "Installateursnr: " +
            alarm?.lift_data?.details?.manufacturernr +
            ","
          ) : (
            <></>
          )}

          {alarm?.lift_data?.details?.advicernr ? (
            "Advieseursnr: " + alarm?.lift_data?.details?.advicernr
          ) : (
            <></>
          )}
        </p>

        <CardActions className={classes.actions}>
          <Button
            size="small"
            onClick={() => {
              history.push(`/lifts/${alarm?.lift_id}`);
            }}
          >
            <FormattedMessage id="lift.details" />
          </Button>

          {!isEmpty(alarm?.lift_data?.sense_link) ? (
            <AlertSenseDataModal alarmData={alarm} />
          ) : (
            <></>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default ActiveAlertCard;
