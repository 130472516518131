import { socketClient } from "Api/Socket";
import { isString } from "lodash";
import { subscribe } from "pubsub-js";
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ISenseModule } from "../../typings/sense/collect";

interface ISenseModuleProviderProps {
  esNumber: string;
  children: any;
}

export interface ISenseSensorUpdate {
  es: string;
  message: {
    data: { [key: number]: string };
    f: string;
    mc: number;
    mid: string;
    rts: number;
    ts: number;
  };
}

export interface IGetModuleSensorDataRequest {
  from: moment.Moment;
  to: moment.Moment;
  sensors: number[];
}

export interface IElevatorCapabilities {
  hasBackDoor: boolean;
}

export interface ISenseModuleSensorData {
  lastUpdate: number;
  updates: { [key: number]: number };
  values: { [sensor: number]: number | string | null };
}

export interface IGetModuleSensorData {
  rows: {
    date_value: string;
    duration: number;
    next_ts: string;
    next_val: number;
    pos: number;
    prev_duration: number;
    prev_ts: string;
    prev_val: string;
    ts: string;
    val: number;
  }[];
  sensor: {
    high_volume: string;
    id: string;
    internal: string;
    name: string;
    type: string;
  };
}

export interface IGetModuleSensorDataResponse {
  data: IGetModuleSensorData[];
}

export interface IModuleCommandRequest {
  command: string;
}

export const SenseModuleContext = createContext(null);

const SenseModuleProvider = ({
  children,
  esNumber,
}: ISenseModuleProviderProps) => {
  const { liftId } = useParams();
  const [movementPoints, setMovementPoints] = useState([]);
  const [senseModule, setSenseModule] = useState<ISenseModule>();
  const [
    senseModuleSensorData,
    setSenseModuleSensorData,
  ] = useState<ISenseModuleSensorData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);

  const MovementPoints = React.useCallback(() => {
    return movementPoints;
  }, [movementPoints]);

  const SenseModule = React.useCallback(() => {
    return senseModule;
  }, [senseModule]);

  const setSenseModuleData = async () => {
    const sensorData = (await socketClient.getSensorData(
      liftId,
      esNumber
    )) as ISenseModuleSensorData;

    if ( sensorData ) {
      setSenseModuleSensorData(sensorData);
    } else {
      console.error("No sensor data");
    }
  };

  const sendCommand = async (request: IModuleCommandRequest) => {
    try {
      const response: any = await socketClient.post({
        liftId,
        uri: `/liftstatus/command/external/${socketClient.getExternalIdForLift(
          liftId
        )}/${request.command}`,
      });

      const data = response?.data;

      return data;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const fetchModuleSensorData = async (
    request: IGetModuleSensorDataRequest
  ) => {
    /**
     * There must be a request to do this
     */
    if (!request) {
      setLoading(false);
      return;
    }

    try {
      const response = (await socketClient.post({
        liftId,
        uri: `/liftstatus/external/${socketClient.getExternalIdForLift(
          liftId
        )}/sensor`,
        urlParameters: {
          from: request.from.format(),
          to: request.to.format(),
          sensors: request.sensors,
        },
      })) as IGetModuleSensorDataResponse;


      setSenseModuleData();
      const data = response?.data;
      return data;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  // when the liftId changes connnect the websocket
  //
  const handleSocket = async (disconnect?: boolean) => {
    try {
      setLoading(true);
      if (!socketClient.sockets[liftId]) {
        const connectedSocket = await socketClient.connect(liftId);

        await socketClient.listenModule(liftId, esNumber);

        const moduleStatus = (await socketClient.getModuleStatus(
          liftId,
          esNumber
        )) as ISenseModule;

        if (isString(moduleStatus?.capability)) {
          try {
            moduleStatus.capability = moduleStatus?.capability ? JSON.parse(moduleStatus?.capability) : undefined;
          } catch (e) {
            console.warn(
              "[SENSEMODULEPROVIDER] Problem parsing capability of module",
              moduleStatus,
              e
            );
          } finally {
            setLoading(false);
          }
        }

        setSenseModule(moduleStatus);
        setSocketConnected(true);

        // Subscribe here to the channels that you want the data from
        // in the callback call the setFunction of your variable so that the value changes
        // and the callback is triggered
        //
        const sub = subscribe(`module.${esNumber}.sensorData`, (_msg, data) => {
          setMovementPoints(data);
          setSenseModuleData();
        });
        if (disconnect) {
          PubSub.unsubscribe(sub);
          setSenseModule(undefined);
        }
        return connectedSocket;
      } else {
        // If its the same lift socketClient.sockets[liftId] is always true
        // but since we need to re-subscribe.
        //
        const sub = subscribe(`module.${esNumber}.sensorData`, (_msg, data) => {
          setMovementPoints(data);
          setSenseModuleData();
        });
        setSocketConnected(true);

        if (disconnect) {
          PubSub.unsubscribe(sub);
          setSenseModule(undefined);
        }
      }
    } catch (e) {
      console.error("socket connection error", e);
      return e;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    handleSocket();

    return () => handleSocket(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liftId]);
  return (
    <SenseModuleContext.Provider
      value={{
        connectSocket: handleSocket, // This is just to kick it off. doesn't have to be called from component
        movementPoints: MovementPoints(),
        fetchModuleSensorData,
        sendCommand,
        senseModule: SenseModule(),
        senseModuleSensorData,
        socketConnected,
        esNumber,
        loading,
      }}
    >
      {children}
    </SenseModuleContext.Provider>
  );
};

export function useSenseModule() {
  const context = React.useContext(SenseModuleContext);
  if (context === undefined) {
    throw new Error(
      "The useSenseModule hook must be used within a SenseModuleContext.Provider"
    );
  }
  return context;
}

export default SenseModuleProvider;
