/* eslint-disable react/prop-types */
import { CircularProgress, Grid } from "@material-ui/core";
import Elevator3dScene from "Components/Elevator3dScene/Elevator3dScene";
import ElevatorRealtimeInfo from "Components/ElevatorRealtimeInfo/ElevatorRealtimeInfo";
import ElevatorRealtimeStatuses from "Components/ElevatorRealtimeInfo/ElevatorRealtimeStatuses";
import ElevatorStatisticsGraph from "Components/ElevatorStatisticsGraphs/ElevatorStatisticsGraphs";
import { useSenseModule } from "Providers/SenseModuleProvider";
import React, { useContext, useEffect, useState } from "react";
import CurrentStopIndicator from "./CurrentStopIndicator";
import LastStopIndicator from "./LastStopIndicator";
import classes from "./RealTimeLiftData.module.scss";
import StopsGraph from "./StopsGraph";
import { isEmpty } from "lodash";
import {
  IGlobalState,
  GlobalStateContext,
} from "Providers/GlobalStateProvider";

// Object.assign Polyfill.
// eslint-disable-next-line prefer-rest-params
const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

const Blur = function ({ children, radius = "0px", transition = "0s" }) {
  const styles = {
    transition: "filter " + transition,
    WebkitFilter: "blur(" + radius + ")",
    filter: "blur(" + radius + ")",
  };

  return React.Children.count(children) > 1
    ? React.createElement("span", { style: styles }, children)
    : addStylesToElement(React.Children.only(children), styles);
};

const addStylesToElement = function (el, styles) {
  return React.cloneElement(el, { style: _extends({}, el.style, styles) });
};

interface IRecentMovementProps {
  lift: LiftStatus.Schemas.ILift;
  width: number;
  liftFlowAvailable: boolean;
}

const RealTimeLiftData = (props: IRecentMovementProps) => {
  const senseModuleProvider = useSenseModule();
  const [globalState] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [blur, setBlur] = useState<boolean>(false);
  const [unhealthy, setUnhealthy] = useState<boolean>(false);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [sTenConnected, setSTenConnected] = useState(true);
  const [sFiveTwoFourConnected, setSFiveTwoFourConnected] = useState(true);
  // Will check socket state & data of sensor 524 to make sure its healthy
  //
  useEffect(() => {
    const sensors = senseModuleProvider?.senseModuleSensorData?.values;

    if (!senseModuleProvider?.socketConnected) {
      // Socket is disconnected
      setDisconnected(true);
      setBlur(true);
      return;
    } else {
      // Connected
      setBlur(false);
      setDisconnected(false);
    }

    if (!isEmpty(sensors)) {
      if (sensors[10] !== 1) {
        // If it's not 1 its unhealthy
        setBlur(true);
        setUnhealthy(true);
        setSTenConnected(false);
        return;
      } else {
        setUnhealthy(false);
        setBlur(false);
        setSTenConnected(true);
      }

      if (sensors[524] !== 1) {
        // If it's not 1 its unhealthy
        setBlur(true);
        setUnhealthy(true);
        setSFiveTwoFourConnected(false);
        return;
      } else {
        setUnhealthy(false);
        setBlur(false);
        setSFiveTwoFourConnected(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    senseModuleProvider.senseModuleSensorData,
    senseModuleProvider.socketConnected,
  ]);

  const yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).toISOString();

  const today = new Date().toISOString();

  return (
    <>
      {senseModuleProvider?.loading ? (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Grid container style={{ marginBottom: "16px" }}>
          <Grid item xs={12} className={classes.stopsGraphContainer}>
            {disconnected || unhealthy ? (
              <></>
            ) : (
              <React.Fragment>
                { props.liftFlowAvailable ? (
                <>
                <h3 className={classes.recentStops}>Recente stopplaatsen</h3>
                <StopsGraph width={props.width} />
                </>
                ) : '' }

              </React.Fragment>
            )}
              { /* Show the 15 min bars regardless if the lift flow was released */ }

              <ElevatorStatisticsGraph
                liftId={props.lift?.id}
                from={yesterday}
                to={today}
                width={props.width}
                onlyDaily={true}
              />

          </Grid>

          { props.liftFlowAvailable ? (
            <>
            <Grid item xs={( globalState.userClientLight || globalState.userMechanic ) ? 12 : 6}>
              <div className={classes.scene}>
                <CurrentStopIndicator className={classes.currentStop} />
                <LastStopIndicator className={classes.lastStop} />
                <Blur radius={blur ? "5px" : "0px"}>
                  <div>
                    <Elevator3dScene />
                  </div>
                </Blur>
                <h2
                  className={classes.disconnected}
                  style={{ opacity: disconnected ? "1" : "0" }}
                >
                  Geen verbinding met socket server.
                </h2>
                <h2
                  className={classes.unhealthy}
                  style={{ opacity: !sTenConnected ? "1" : "0" }}
                >
                  Geen verbinding met Sensecloud.
                </h2>
                <h2
                  className={classes.unhealthy}
                  style={{ opacity: !sFiveTwoFourConnected ? "1" : "0" }}
                >
                  Geen verbinding met besturing.
                </h2>
              </div>

              {disconnected || unhealthy ? <></> : <ElevatorRealtimeInfo />}
            </Grid>
            { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
              <Grid item xs={6}>
                <ElevatorRealtimeStatuses liftData={props.lift} />
              </Grid>
            ) : (
              <></>
            )}
          </>
          ) : ''}
        </Grid>
      )}
    </>
  );
};

export default RealTimeLiftData;
