import { cloneDeep, find, isEmpty } from 'lodash';

/**
 * Small visit payload parse utility class
 *
 * @export
 * @class VisitPayload
 */
export default class VisitPayload {
  private _visit: any;

  constructor(payload?: string) {
    payload && this.setPayload(payload);
  }

  setPayload( payload: string) {
    try {
      this._visit = JSON.parse(payload);
    } catch ( e ) {
      console.error('[VISITPAYLOAD] Problem parsing visit payload', e);
    }
  }

  get labelConfigurationVersion(): string {
    return this._visit.Message?.LabelConfigurationVersion;
  }

  get visit(): any {
    return cloneDeep(this._visit);
  }

  get role(): string {
    const role = this._visit.Message?.Visitor?.Role;

    return ( !isEmpty(role) ? role : '' ).substr(5);
  }

  get state(): string {
    const state = this._visit.Message?.State;

    return state;
  }

  get questions(): any[] {
    return this._visit.Message?.Questions?.Question;
  }

  get visitReason(): string {
    let visitReason = this.answerForKey(`Q-${this.role}-VisitReason`);

    if ( visitReason ) {
      // Poor mans labeling strategy, this should come from the label configuration,
      // but since it never changes let's do it like this.
      //
      if ( visitReason.includes('Failure')) {
        visitReason = 'Storing';
      } else if ( visitReason.includes('Maintenance')) {
        visitReason = 'Onderhoud';
      } else if ( visitReason.includes('Repair')) {
        visitReason = 'Reparatie';
      } else if ( visitReason.includes('Modernization')) {
        visitReason = 'Modernisering';
      } else if ( visitReason.includes('Other')) {
        visitReason = 'Anders';
      }
    } else  {
      switch ( this.role ) {
        case 'ApprovingAuthority':
          visitReason = 'Keuring';
          break;
        case 'Inspector':
          visitReason = 'Inspectie';
          break;
        default:
          visitReason = 'onbekend';
      }
    }

    return visitReason;
  }

  answerForKey( key: string ): string {
    const question = find( this._visit?.Message?.Questions?.Question, { QuestionKey: key } );

    return question ? question.Answer : '';
  }
}