import React, { createContext, useState } from "react";
import { useAlert } from "react-alert";
import { usePaginatedQuery } from "react-query";
import { LiftStatusBaseApi } from "Api/Generic";
import moment from "moment";

interface IEventDataProviderProps {
  children: any;
}

export interface IEventDataResponse {
  items: LiftStatus.Schemas.IEvent[];
  pagination: {
    limit: string;
  };
  total: number;
}

export interface IEventDataRequest {
  pagination?: {
    limit: number;
    offset: number;
  };

  liftId?: number;
  organisationId?: number;
  from?: string;
  to?: string;
  openOnly?: boolean;
  eventKey?: string[];
}

export const EventSearchDataContext = createContext(null);

const EventSearchDataProvider = ({ children }: IEventDataProviderProps) => {
  const alert = useAlert();

  const [eventDataRequest, setEventDataRequest] = useState<
    IEventDataRequest | undefined
  >();

  const fetch = async (eventDataRequest: IEventDataRequest) => {
    /**
     * There must be a request to do this
     */
    if (!eventDataRequest) {
      return;
    }

    // Convert any incoming date/date string to an iso string
    //
    eventDataRequest.from ? eventDataRequest.from = moment(eventDataRequest.from).toISOString() : eventDataRequest.from = null;
    eventDataRequest.to ? eventDataRequest.to = moment(eventDataRequest.to).toISOString() :  eventDataRequest.to = null;

    const response = await LiftStatusBaseApi.post({
      uri: `/liftstatus/event/search`,
      payload: eventDataRequest,
    });

    const data = await response?.data;
    return data;
  };

  const fetchEventData = async (_key, eventDataRequest) => {
    return await fetch(eventDataRequest);
  };

  // Setup query for event data
  //
  const { resolvedData: data, status, error } = usePaginatedQuery(
    ["events", eventDataRequest],
    fetchEventData,
    {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const QueryEventData = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      alert.show(`Error: ${error.message}`, { type: "error" });
      return status;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  return (
    <EventSearchDataContext.Provider
      value={{
        response: QueryEventData(),
        fetchEventSearchData: setEventDataRequest,
        fetch,
      }}
    >
      {children}
    </EventSearchDataContext.Provider>
  );
};

export default EventSearchDataProvider;
