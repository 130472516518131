import { Button, FormControl, Input, InputLabel } from "@material-ui/core";
import LoginProvider, { LoginContext } from "Providers/LoginProvider";
import VersionNumberProvider, {
  VersionNumberContext,
} from "Providers/VersionProvider";
import React, { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import elevatorPic from "../../Assets/bwbuilding.jpg";
import classes from "./Login.module.scss";
import logo from "../../Assets/Images/ednl-logo.png";
import PasswordResetProvider, { PasswordResetContext } from "Providers/PasswordResetProvider";
import { useIntl } from "react-intl";

export interface ILoginPayload {
  username: string;
  password: string;
}

const InnerLogin = () => {
  const login = useContext(LoginContext);
  const alert = useAlert();
  const intl = useIntl();

  const [passwordState, setPasswordState] = useState<string>("");
  const [usernameState, setUsernameState] = useState<string>("");
  const [emailState, setEmailState] = useState<string>("");
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  const versionData = useContext(VersionNumberContext);
  const passwordResetProvider = useContext(PasswordResetContext);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!passwordState) {
      alert.show(
        intl.formatMessage({ id: "login.password.required" }),
        { type: "error", position: "top left" });
      return;
    }
    const payload: ILoginPayload = {
      username: usernameState,
      password: passwordState,
    };

    login.setPayload(payload);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    name === "email"
      ? setEmailState(value)
      : name === "password"
      ? setPasswordState(value)
      : setUsernameState(value);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    try {
      await passwordResetProvider.requestPasswordReset(emailState);

      alert.show(
        "Indien het ingevoerde e-mail adres bekend is bij een gebruiker is daar zojuist een reset password e-mail heen gestuurd.",
        {
          type: "success",
          position: "top left",
          timeout: 5000
      });

      setResetPassword(false);

    } catch ( e ) {
      console.error(e);
      alert.show("Error requesting password reset", {
        type: "error",
      });
    }
  };

  return (
    <div className={classes.login}>
      <div className={classes.leftDiv}>
        <img src={logo} alt="ednl logo" />
        <h2>
          <FormattedMessage id="liftstatus" />
        </h2>
        <h3>
          <FormattedMessage id={resetPassword ? "reset.password" : "login"} />
        </h3>

          {!resetPassword ? (
            <>
              <form onSubmit={handleLogin} className={classes.inputsWrapper}>
                <FormControl className={classes.username}>
                  <InputLabel>
                    <FormattedMessage id="username" />
                  </InputLabel>
                  <Input onChange={handleChange} name="username" value={usernameState} />
                </FormControl>

                <FormControl>
                  <InputLabel>
                    <FormattedMessage id="password" />
                  </InputLabel>

                  <Input
                    onChange={handleChange}
                    name="password"
                    type="password"
                  />
                </FormControl>

                <Button variant="outlined" color="primary" type="submit">
                  <FormattedMessage id="login" />
                </Button>

                <span
                  className={classes.resetPassword}
                  onClick={() => setResetPassword(true)}
                >
                  <FormattedMessage id="forgot.password" />?
                </span>
              </form>
            </>
          ) : (
            <>
            <form onSubmit={handlePasswordReset} className={classes.inputsWrapper}>
              <FormControl className={classes.username}>
                <InputLabel>
                  <FormattedMessage id="email" />
                </InputLabel>
                <Input onChange={handleChange} name="email" type="email" value={emailState} />
              </FormControl>

              <Button
                variant="outlined"
                color="primary"
                onClick={(event) => handlePasswordReset(event)}
              >
                <FormattedMessage id="reset.password" />
              </Button>

              <span
                className={classes.resetPassword}
                onClick={() => setResetPassword(false)}
              >
                <FormattedMessage id="login" />
              </span>
            </form>
            </>
          )}

        <p className={classes.versionNumber}>{versionData.response?.version}</p>
      </div>

      <div className={classes.rightDiv}>
        <img src={elevatorPic} alt="black and white building" />
      </div>
    </div>
  );
};

const Login = () => (
  <VersionNumberProvider>
    <PasswordResetProvider>
      <LoginProvider>
        <InnerLogin />
      </LoginProvider>
    </PasswordResetProvider>
  </VersionNumberProvider>
);
export default Login;
