import { LiftStatusBaseApi } from "Api/Generic";
import moment from "moment";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import usePrevious from "Utils/Hooks/usePrevious";
import { LiftStatusStorage } from "Utils/Storage";

export interface IGlobalStateBootstrapOptions {
  organisationId: number;
  userToken: string;
  lockedLiftId: string;
}

export enum EGlobalStateCases {
  logIn = "LOGIN",
  logOut = "LOGOUT",
  userName = "USERNAME",
  userRoles = "USER_ROLES",
  userClientLight = "USER_CLIENT_LIGHT",
  userMechanic = "USER_MECHANIC",
  setUserOrganisationType = "SET_USER_ORGANISATION_TYPE",
  setUserOrganisationName = "SET_USER_ORGANISATION_NAME",
  setOrganisationLocal = "SET_ORGANISATION_LOCAL",
  setDateRange = "SET_DATE_RANGE",
  setDatesLocal = "SET_DATES_LOCAL",
  setOrganisationId = "SET_ORGANISATION_ID",
  setLockedLiftId = "SET_LOCKED_LIFT_ID",
  removedLockedLiftId = "REMOVE_LOCKED_LIFT_ID",
  organisations = "ORGANISATIONS",
  switchViewToCurrent = "SWITCH_VIEW_TO_CURRENT",
}

export interface IGlobalState {
  isLoggedIn: boolean;
  locale: string;
  organisationId: number;
  organisationList: LiftStatus.Schemas.IOrganisation[];
  setDates: Function;
  setorganisation: Function;
  userToken: string | undefined;
  username: string | undefined;
  userRoles: [] | undefined;
  userClientLight: boolean;
  userMechanic: boolean;
  userOrganisationType: string | undefined;
  userOrganisationName?: string;
  viewIsCurrent: boolean;
  lockedLiftId: string | undefined;
}

interface IGlobalStateProps {
  bootstrap: IGlobalStateBootstrapOptions;
  children: ReactNode;
}

export const GlobalStateContext = createContext(null);

const GlobalStateProvider = ({ bootstrap, children }: IGlobalStateProps) => {
  const setDates = (payload) => {
    LiftStatusStorage.startDate = moment(payload.startDate).format(
      "YYYY-MM-DD"
    );
    LiftStatusStorage.endDate = moment(payload.endDate).format("YYYY-MM-DD");
  };

  const setOrganisation = (payload) => {
    LiftStatusStorage.organisationId = payload.id;
  };

  const history = useHistory();

  const reducer = (
    state: Partial<IGlobalState>,
    action: { type: string; payload?: any }
  ) => {
    // Keep for debugging
    // console.log('[GENERAL-STATE]:', state, action.type, action.payload);
    switch (action.type) {
      case EGlobalStateCases.logIn:
        return {
          ...state,
          isLoggedIn: true,
          userToken: action.payload,
        };
      case EGlobalStateCases.logOut:
        localStorage.removeItem(LiftStatusStorage.storeKeyUserToken);
        localStorage.removeItem(
          LiftStatusStorage.storeKeyliftstatusOrganisation
        );
        localStorage.removeItem(LiftStatusStorage.storageKeyStartDate);
        localStorage.removeItem(LiftStatusStorage.storeKeyEndDate);
        localStorage.removeItem(LiftStatusStorage.storeKeyPeriod);
        return {
          ...state,
          isLoggedIn: false,
          userToken: null,
        };
      case EGlobalStateCases.userName:
        return {
          ...state,
          username: action.payload,
        };
      case EGlobalStateCases.userRoles:
        return {
          ...state,
          userRoles: action.payload,
        };
      case EGlobalStateCases.userClientLight:
        return {
          ...state,
          userClientLight: action.payload,
        };
      case EGlobalStateCases.userMechanic:
        return {
          ...state,
          userMechanic: action.payload,
        };
      case EGlobalStateCases.setUserOrganisationType:
        return {
          ...state,
          userOrganisationType: action.payload,
        };
      case EGlobalStateCases.setUserOrganisationName:
        return {
          ...state,
          userOrganisationName: action.payload,
        };
      case EGlobalStateCases.setDateRange:
        return {
          ...state,
          periodDateRange: action.payload,
        };
      case EGlobalStateCases.setDatesLocal:
        return {
          ...state,
          setDates: setDates(action.payload),
        };
      case EGlobalStateCases.setOrganisationLocal:
        return {
          ...state,
          setOrganisation: setOrganisation(action.payload),
        };
      case EGlobalStateCases.setOrganisationId:
        LiftStatusStorage.organisationId = action.payload;
        return {
          ...state,
          organisationId: action.payload,
        };
      case EGlobalStateCases.setLockedLiftId:
        LiftStatusStorage.lockedLift = action.payload;
        return {
          ...state,
          lockedLiftId: action.payload,
        };
      case EGlobalStateCases.removedLockedLiftId:
        LiftStatusStorage.removeLockedLift();
        return {
          ...state,
          lockedLiftId: null,
        };
      case EGlobalStateCases.organisations:
        return {
          ...state,
          organisationList: action.payload,
        };
      case EGlobalStateCases.switchViewToCurrent:
        localStorage.setItem("viewIsCurrent", action.payload);
        return {
          ...state,
          viewIsCurrent: action.payload,
        };
      default: {
        return state;
      }
    }
  };

  const initialState: IGlobalState = {
    isLoggedIn: false,
    locale: "NL-nl",
    organisationId: null,
    organisationList: [],
    setDates: setDates,
    setorganisation: setOrganisation,
    userToken: undefined,
    username: undefined,
    userRoles: undefined,
    userClientLight: false,
    userMechanic: false,
    userOrganisationType: undefined,
    userOrganisationName: undefined,
    viewIsCurrent: false,
    lockedLiftId: undefined,
  };

  const [initialized, setInitalized] = useState(false);

  /**
   * OnInit
   */
  useEffect(() => {
    console.log("[GENERAL-STATE]: Bootstrap general state with", bootstrap);

    if (bootstrap.organisationId) {
      initialState.organisationId = bootstrap.organisationId;
    }

    if (bootstrap.userToken) {
      initialState.userToken = bootstrap.userToken;
    }

    if (bootstrap.lockedLiftId) {
      initialState.lockedLiftId = bootstrap.lockedLiftId;
    }

    setInitalized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [globalState, globalStateDispatch] = useReducer(reducer, initialState);
  const previousToken = usePrevious(globalState.userToken);
  const [fetchorganisations, setFetchingorganisations] =
    useState<boolean>(false);
  const { Provider } = GlobalStateContext;

  useEffect(() => {
    const localValue = localStorage.getItem("viewIsCurrent");
    globalStateDispatch({
      type: EGlobalStateCases.switchViewToCurrent,
      payload: JSON.parse(localValue),
    });
  }, []);
  /**
   * Fetch all the organisations when the user token changes
   */
  useEffect(() => {
    if (globalState.userToken !== previousToken && !fetchorganisations) {
      setFetchingorganisations(true);

      LiftStatusBaseApi.get({
        uri: `/liftstatus/organisation`,
        urlParameters: {
          limit: 1000,
        },
      })
        .then((payload) => {
          const response =
            payload?.data as LiftStatus.Schemas.IPagedOrganisationResult;

          globalStateDispatch({
            type: EGlobalStateCases.organisations,
            payload: response?.items,
          });
        })
        .finally(() => setFetchingorganisations(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.userToken]);

  useEffect(() => {
    /**
     * When we're on the root of the app we should do something with the location
     */
    const goToStartPage = (location: string) => {
      if (location && ["", "/"].includes(document.location.pathname)) {
        history.push("/dashboard");
      }
    };

    // If there is a token let's fetch the user details initially
    //
    if (initialState.userToken && !initialState.username) {
      LiftStatusBaseApi.get({ uri: "/liftstatus/user" })
        .then((user) => {
          if (user?.data?.username) {
            // Store username
            //
            globalStateDispatch({
              type: EGlobalStateCases.userName,
              payload: user?.data?.username,
            });

            // Store user roles
            //
            globalStateDispatch({
              type: EGlobalStateCases.userRoles,
              payload: user?.data?.roles,
            });

            const isClientLight = user?.data.roles.find(
              (role) => role.name === "Client light"
            );

            const isMechanic = user?.data.roles.find(
              (role) => role.name === "Monteur"
            );

            if (isClientLight) {
              globalStateDispatch({
                type: EGlobalStateCases.userClientLight,
                payload: true,
              });
            }

            if (isMechanic) {
              globalStateDispatch({
                type: EGlobalStateCases.userMechanic,
                payload: true,
              });
            }
            goToStartPage("/dashboard");
          }
        })
        .catch((error) => {
          console.error(
            "[GENERAL-STATE]: Error while trying to restore active session",
            error
          );
          globalStateDispatch({
            type: "LOGOUT",
          });
          goToStartPage("/login");
        });
    }

    // If there is a token let's fetch the user organisation initially
    //
    if (initialState.userToken && !initialState.userOrganisationType) {
      LiftStatusBaseApi.get({ uri: "/liftstatus/user/organisation" })
        .then((user) => {
          if (user?.data?.type) {
            // Store username
            //
            globalStateDispatch({
              type: EGlobalStateCases.setUserOrganisationType,
              payload: user?.data?.type,
            });
            // Store organisatioin name
            //
            globalStateDispatch({
              type: EGlobalStateCases.setUserOrganisationName,
              payload: user?.data?.name,
            });
          }
        })
        .catch((error) => {
          console.error(
            "[GENERAL-STATE]: Error while trying to set user organisation",
            error
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider value={[globalState as IGlobalState, globalStateDispatch]}>
      {initialized && children}
    </Provider>
  );
};

export default GlobalStateProvider;
