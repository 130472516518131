import BarChartIcon from '@material-ui/icons/BarChart';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './SideMenuLiftDetail.module.scss';
import { LiftStatusStorage } from 'Utils/Storage';

const SideMenuLiftDetail = () => {
  const [stateLiftID, setStateLiftID] = useState('');
  const [liftData, setLiftData] = useState(null);
  const { liftID } = useParams();

  useEffect(() => {
    liftID ? setStateLiftID(liftID) : setStateLiftID('');
    const bearerToken = 'Bearer ' + LiftStatusStorage.userToken ?? '';
    if (liftID) {
      fetch(`https://api-next-tst.liftstatus.nl/liftstatus/lift/${liftID}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearerToken,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLiftData(data);
        });
    }
  }, [liftID, setLiftData]);

  return (
    <React.Fragment>
      {stateLiftID && liftData ? (
        <div className={classes.liftData}>
          <p>
            <b>{liftID}</b> -{' '}
            {liftData && liftData.details?.liftname
              ? liftData?.details?.liftname
              : 'Lift'}
          </p>
          <span className={classes.liftLocation}>
            <BarChartIcon className={classes.liftLocationIcon} />
            <p>{liftData?.details?.address}</p>
          </span>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default SideMenuLiftDetail;
