import { Button, Card } from "@material-ui/core";
import Events from "Components/Events/Events";
import EventsFilters, {
  IEventsFiltersState
} from "Components/EventsFilters/EventsFilters";
import SideMenu from "Components/SideMenu/SideMenu";
import StickyHeader from "Components/StickyHeader/StickyHeader";
import EventSearchDataProvider from "Providers/EventDataProvider";
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState
} from "Providers/GlobalStateProvider";
import LiftDetailsProvider, { LiftDetailsContext } from "Providers/LiftDetailsProvider";
import React, { useContext, useEffect, useState } from "react";
import classes from "./EventsPage.module.scss";

const EventsInternalPage = () => {
  const [globalState, globalDispatch] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [filtersState, setFiltersState] = useState<IEventsFiltersState>(null);
  const liftDetailsProvider = useContext(LiftDetailsContext);
  const [liftData, setLiftData] = useState(null);
  const [liftMatchesOu, setLiftMatchesOu] = useState(false);


  // Handle matching the OU to the lockedLiftId if it exists and populate the sticky header
  //
  useEffect(() => {
    if (globalState.lockedLiftId) {
      if (
        liftDetailsProvider?.response === "loading") {
        return;
      }

      liftDetailsProvider.fetchLiftDetails({ liftId: globalState.lockedLiftId });

      const liftDetails = liftDetailsProvider?.response as LiftStatus.Schemas.ILift;

      setLiftData(liftDetails); 

      let matches = false;
      for (const property in liftDetails) {
        if (
          property.includes("organisation_id") &&
          liftDetails[property] === globalState.organisationId
        ) {
          matches = true;
        }
      }

      setLiftMatchesOu(matches);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liftDetailsProvider.response, globalState.lockedLiftId, globalState.organisationId, filtersState]);

  const removeOu = () => {
    globalDispatch({
      type: EGlobalStateCases.setOrganisationId,
      payload: null,
    });
  }

  return (
    <div className={classes.root} >
      <SideMenu />
      <div className={classes.content}>
        <>
          {globalState.lockedLiftId && liftData && liftMatchesOu ? (<StickyHeader showPeriods={false} liftData={liftData} showDeleteIcon={true} showHeader={true} />) : (<></>)}

          {!liftMatchesOu && globalState.lockedLiftId ? (
            (
              <div>
                <h2>
                  De geselecteerd lift heeft geen relatie met de geselecteerde organisatie. Klik <Button onClick={() => removeOu()}>hier</Button> om de organisatie te deselecteren
                </h2>
              </div>
            )
          ) : <>
            <Card className={classes.card} style={{ marginTop: globalState.lockedLiftId ? 70 : 0 }}>
              <EventsFilters onChange={setFiltersState} />
            </Card>

            {filtersState && (<Events
              organisationId={globalState.organisationId}
              liftId={globalState.lockedLiftId ?? undefined}
              eventKey={filtersState?.eventKey}
              openOnly={filtersState?.openOnly}
              dateRange={filtersState?.dateRange}
            />)}
          </>}

        </>
      </div>
    </div>
  );
};

const EventsPage = () => (
  <EventSearchDataProvider>
    <LiftDetailsProvider>
      <EventsInternalPage />
    </LiftDetailsProvider>
  </EventSearchDataProvider>
);

export default EventsPage;
