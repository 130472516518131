import React, { createContext, useState } from "react";
import { useParams } from "react-router-dom";
import { socketClient } from "Api/Socket";
import Axios from "axios";


export interface IStatusCodeRequest {
  levels: [string],
  onlyOpen: boolean
}

export const StatusCodes = createContext(null);

const StatusCodeProvider = ({ children }: any) => {
  const { liftId } = useParams();
  const [cancelToken, setCancelToken] = useState(null);

  const fetchStatusCodes = async (request: any) => {
    /**
     * There must be a request to do this
     */
     if (!request) {
      return;
    }

    // Cancel the previous call (if there is a token) before triggering a new call
    if (cancelToken) {
      cancelToken.cancel("Cancel");
      setCancelToken(null);
    }

    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelToken(source);
  

    try {
      const response = await socketClient.post({
        liftId,
        uri: `/liftstatus/external/${socketClient.getExternalIdForLift(
          liftId
        )}/status-code`,
        urlParameters: {
          from: request.from.format(),
          to: request.to.format(),
          limit: request.limit ?? null,
          skip: request.skip ?? null
        },
        payload: {
          levels: request.levels,
          onlyOpen: request.onlyOpen
        },
        cancelToken: source?.token,
      });

      return response?.data;
    } catch (e) {
      console.error(e, "Error fetching status codes")
      throw e;
    }

  };
  return (
    <StatusCodes.Provider
      value={{
        fetchStatusCodes,
      }}
    >
      {children}
    </StatusCodes.Provider>
  );
};

export function useStatusCodeContext() {
  const context = React.useContext(StatusCodes);
  if (context === undefined) {
    throw new Error(
      "The StatusCodes hook must be used within a StatusCodes.Provider"
    );
  }
  return context;
}

export default StatusCodeProvider;
