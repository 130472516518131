import {
  Button, Card, CardActions, CardContent, CircularProgress
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SideMenu from "Components/SideMenu/SideMenu";
import StickyHeader from "Components/StickyHeader/StickyHeader";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import AlarmCallsProvider, {
  AlarmCallsContext,
  IAlarmCallsRequest
} from "Providers/AlarmCallsProvider";
// eslint-disable-next-line no-unused-vars
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState
} from "Providers/GlobalStateProvider";
import LiftDetailsProvider, { LiftDetailsContext } from "Providers/LiftDetailsProvider";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classes from "./AlarmCalls.module.scss";



const AlarmCallsInternal = () => {
  const [alarmCalls, setAlarmCalls] = useState(
    {} as LiftStatus.Schemas.IPagedResult
  );
  const [alarmCallsToRender, setAlarmCallsToRender] = useState<
    LiftStatus.Schemas.ICallDetailRecordAlarm[]
  >([]);
  const [page, setPage] = React.useState(1);
  const [pageLimit] = useState(20);
  const alarmCallsProvider = useContext(AlarmCallsContext);
  const [globalState, globalDispatch] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const history = useHistory();
  const [alarmAudioCallId, setAlarmAudioCallId] = useState<number>();
  const [alarmAudioUrl, setAlarmAudioUrl] = useState<string>();
  const liftDetailsProvider = useContext(LiftDetailsContext);
  const [liftData, setLiftData] = useState(null);
  const [liftMatchesOu, setLiftMatchesOu] = useState(false);
  const [paginationTotal, setPaginationTotal] = useState(0);
  // Handle matching the OU to the lockedLiftId if it exists and populate the sticky header
  //
  useEffect(() => {
    if (globalState.lockedLiftId) {
      if (
        liftDetailsProvider?.response === "loading") {
        return;
      }
      liftDetailsProvider.fetchLiftDetails({ liftId: globalState.lockedLiftId });

      const liftDetails = liftDetailsProvider?.response as LiftStatus.Schemas.ILift;

      setLiftData(liftDetails);

      let matches = false;
      for (const property in liftDetails) {
        if (
          property.includes("organisation_id") &&
          liftDetails[property] === globalState.organisationId
        ) {
          matches = true;
        }
      }

      setLiftMatchesOu(matches);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liftDetailsProvider.response, globalState.lockedLiftId, globalState.organisationId]);
  /**
   * Handle AlarmCallsProvider fetch response
   */
  useEffect(() => {
    if (alarmCallsProvider.fetchResponse === "loading") {
      return;
    }

    const alarmCallsData = alarmCallsProvider.fetchResponse;

    if (!isEmpty(alarmCallsData)) {
      setAlarmCalls(alarmCallsData);

      setAlarmCallsToRender(alarmCallsData.items);
      setPaginationTotal(alarmCallsData.total || 99);
    }
  }, [page, pageLimit, alarmCalls, alarmCallsProvider]);

  /**
   * Handle AlarmCallsProvider get audio response
   */
  useEffect(() => {
    if (alarmCallsProvider.getAlarmAudioResponse === "loading") {
      return;
    }

    const alarmAudioData = alarmCallsProvider.getAlarmAudioResponse;

    if (!isNil(alarmAudioData) && !isEmpty(alarmAudioData)) {
      setAlarmAudioUrl(alarmAudioData);
    }
  }, [alarmCallsProvider]);

  const handleLiftClick = (liftID: number) => {
    history.push(`/lifts/${liftID}`);
  };

  const handlePageChange = (event, pageNum: number) => {
    event.stopPropagation();
    alarmCallsProvider.fetch({
      limit: pageLimit,
      offset: (pageNum - 1) * pageLimit,
      organisationId: globalState.organisationId,
      liftId: globalState.lockedLiftId && liftMatchesOu ? Number(globalState.lockedLiftId) : null,
    } as IAlarmCallsRequest);
    setPage(pageNum);
  };

  const playAlarmCall = (
    alarmCall: LiftStatus.Schemas.ICallDetailRecordAlarm
  ) => {
    setAlarmAudioUrl(undefined);

    setAlarmAudioCallId(alarmCall.id);

    alarmCallsProvider.getAlaramAudio(alarmCall.id);
  };

  const removeOu = () => {
    globalDispatch({
      type: EGlobalStateCases.setOrganisationId,
      payload: null,
    });
  }

  /**
   * On page load fetch the initial list of alarm calls
   */
  useEffect(() => {
    alarmCallsProvider.fetch({
      limit: pageLimit,
      offset: 0,
      organisationId: globalState.organisationId,
      liftId: globalState.lockedLiftId && liftMatchesOu ? Number(globalState.lockedLiftId) : null,
    } as IAlarmCallsRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.lockedLiftId, globalState.organisationId, liftMatchesOu]);

  return (
    <div className={classes.root}>
      <SideMenu
        onChangeOrganisation={(organisation) => {
          alarmCallsProvider.fetch({
            limit: pageLimit,
            offset: 0,
            organisationId: organisation.id,
          });
        }}
      />

      <div className={classes.content}>
        {globalState.lockedLiftId && liftData && liftMatchesOu ? (<StickyHeader showDeleteIcon={true} liftData={liftData} showHeader={true} showPeriods={false} />) : (<></>)}

        {!liftMatchesOu && globalState.lockedLiftId ? (
          <div>
            <h2>
              De geselecteerd lift heeft geen relatie met de geselecteerde organisatie. Klik <Button onClick={() => removeOu()}>hier</Button> om de organisatie te deselecteren
            </h2>
          </div>
        ) : (
          <div style={{marginTop: globalState.lockedLiftId ? 70 : 0}}>
            {/** Title */}
            <span className={classes.title}>

              <h4>
                <FormattedMessage id="calls" />
              </h4>
            </span>

            {alarmCallsProvider.fetchResponse === "loading" ? (
              <div className={classes.progressIndicator} >
                <CircularProgress color="secondary" />
              </div>
            ) : (<>
            {alarmCallsToRender.length > 0 ? (
              (
                <>
                  {alarmCallsToRender?.map(
                    (alarmCall: LiftStatus.Schemas.ICallDetailRecordAlarm) => {
                      const title = [];
                      alarmCall.city && title.push(alarmCall.city);
                      alarmCall.address && title.push(alarmCall.address);

                      if (alarmCall.lift_phonenr_count === 1) {
                        alarmCall.lift_name && title.push(alarmCall.lift_name);
                      }

                      const now = moment();
                      const mCalldate = moment(alarmCall.queued_at);

                      let dateFormat: string;

                      // - Show all when it's not the current year
                      // - today, show only the time
                      //
                      if (now.format("YYYY") !== mCalldate.format("YYYY")) {
                        dateFormat = "D MMM YYYY HH:mm";
                      } else if (now.format("D") === mCalldate.format("D")) {
                        dateFormat = "HH:mm";
                      } else {
                        dateFormat = "D MMM HH:mm";
                      }

                      return (
                        <Card
                          style={{ gridColumn: "1/5" }}
                          key={alarmCall.id}
                          className={classes.card}
                        >
                          <CardContent className={classes.cardContainer}>
                            <h4>
                              {mCalldate.format(dateFormat)} - {title.join(", ")}
                            </h4>
                            {!alarmCall.lift_id &&
                              alarmCall.lift_phonenr_count > 1 ? (
                              <p className={classes.multipleLifts}>
                                De oproep was niet te koppelen aan één lift. Er zijn{" "}
                                {alarmCall.lift_phonenr_count} liften met hetzelfde
                                telefoonnummer: {alarmCall.src_full}
                              </p>
                            ) : (
                              ""
                            )}
                            <p className={classes.callDetails}>
                              Aangenomen door: {alarmCall.answered_by_description} -
                              Oproepduur: {alarmCall.queued_duration}s - Gespreksduur:{" "}
                              {alarmCall.answered_duration}s
                            </p>
                          </CardContent>

                          <CardActions>
                            {alarmCall.lift_id ? (
                              <Button
                                size="small"
                                onClick={() => handleLiftClick(alarmCall.lift_id)}
                              >
                                <FormattedMessage id="navigate.to.lift" />
                              </Button>
                            ) : (
                              <Button size="small">
                                Zoek lift(en) met telefoonnummer
                              </Button>
                            )}
                            { globalState.userRoles.some( ( item: {name:string} ) => ["Hoofdgebruiker", "Admin"].includes(item.name)) ? (
                              <Button
                                size="small"
                                onClick={() => playAlarmCall(alarmCall)}
                              >
                                Beluister
                              </Button>
                            ) : (
                              <></>
                            ) }
                            {alarmAudioCallId === alarmCall.id &&
                              alarmCallsProvider.getAlarmAudioResponse === "loading" ? (
                              <div className={classes.progressIndicator}>
                                <CircularProgress color="secondary" />
                              </div>
                            ) : (
                              <></>
                            )}

                            {alarmAudioCallId === alarmCall.id && alarmAudioUrl ? (
                              <audio
                                src={alarmAudioUrl}
                                controls={true}
                                autoPlay={true}
                              ></audio>
                            ) : (
                              <></>
                            )}
                          </CardActions>
                        </Card>
                      );
                    }
                  )}

               {alarmCallsToRender.length > 0 ? (
                  <div className={classes.pagination}>
                    {/* TODO: we don't get a total back from the API, how to deal with this without a total page count? */}
                    <Pagination count={paginationTotal} page={page} onChange={handlePageChange} />
                  </div>
                ) : <></>}
                </>
             )
            ) : (
              <h2>Geen oproepen gevonden</h2>
            )}
            </>)}

          </div>
        )}
      </div>
    </div>
  );
};

const AlarmCalls = () => (
  <AlarmCallsProvider>
    <LiftDetailsProvider>
      <AlarmCallsInternal />
    </LiftDetailsProvider>
  </AlarmCallsProvider>
);

export default AlarmCalls;
