import React, { createContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { LiftStatusBaseApi } from 'Api/Generic';

interface ISenseConfigurationProviderProps {
  children: any;
}

export interface ISenseConfigurationResponse {
  items: any[];
  pagination: {
    limit: string;
  };
  total: number;
}

export interface ISenseConfigurationRequest {
  limit?: number;
  offset?: number;
  type?: string;
}

export const SenseConfigurationContext = createContext(null);

const SenseConfigurationProvider = ({ children }: ISenseConfigurationProviderProps) => {
  const alert = useAlert();
  const [senseConfigurations, setSenseConfigurations] = useState<any | undefined>();

  // Fetch Sense configurations
  //
  const fetch = async (_key, senseConfigurationQuery?: ISenseConfigurationRequest) => {
    const urlParameters: ISenseConfigurationRequest = {
      type: 'label'
    };

    if ( senseConfigurationQuery?.limit ) {
      urlParameters.limit = senseConfigurationQuery.limit;
    } else {
      urlParameters.limit = 9999;
    }

    if ( senseConfigurationQuery?.offset ) {
      urlParameters.offset = senseConfigurationQuery.offset;
    }

    const response = await LiftStatusBaseApi.get({
      uri: '/liftstatus/sense/configuration',
      urlParameters,
    });

    const data: ISenseConfigurationResponse = response?.data;
    return data;
  };

  // Setup query for sense configurations
  //
  const {
    status,
    data,
    error,
  } = useQuery(
    ["sense-configuration", senseConfigurations],
    fetch,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // Map sense configurations
  //
  const SenseConfigurations = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error('[SENSE:CONFIGURATION]:', error);
      alert.show("Error fetching Sense configurations", { type: "error" });
      return status;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  return (
    <SenseConfigurationContext.Provider
      value={{
        results: SenseConfigurations(),
        fetch: setSenseConfigurations
      }}
    >
      {children}
    </SenseConfigurationContext.Provider>
  );
};

export default SenseConfigurationProvider;
