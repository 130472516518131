import { StyledComponentProps, withStyles } from "@material-ui/core";
import * as d3 from "d3";
import { FormatLocaleDefinition } from "d3";
import d3_nl_NL from "d3-format/locale/nl-NL.json";
import {
  each,
  first,
  isArray,
  isEmpty,
  keys,
  last,
  pickBy,
  size,
} from "lodash";
import moment from "moment";
import * as React from "react"; // we need this to make JSX compile
import { ReactFauxDomProps, withFauxDOM } from "react-faux-dom";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { LiftStatusStorage } from "Utils/Storage";
import "./ElevatorMaintenance.scss";

type ICounterData = {
  value_at: string;
  value: number;
  reset?: boolean;
};

type IVisit = {
  created_at: string;
  updated_at: string;
  closed_at: string;
  event_key: string;
  visit_role: string;
  visit_type: string;
  visit_result: string;
  visit_state: string;
  remarks: string;
};

type IUnavailability = {
  created_at: string;
  closed_at: string;
  event_key: string;
  remarks: string;
};

type IUnhealthy = {
  created_at: string;
  closed_at: string;
  event_key: string;
  remarks: string;
};

interface ElevatorMaintenanceProps
  extends ReactFauxDomProps,
    StyledComponentProps {
  chart?: Element;
  data: ICounterData[];
  height: number;
  intl: IntlShape;
  label?: string;
  servicecounterInitvalue?: number;
  unavailability: IUnavailability[];
  visits: IVisit[];
  unhealthy: IUnhealthy[];
  width: number;
  onClickNavFunc: Function;
}

interface IInfoTooltip {
  x: number;
  y: number;
  title: string;
  description: string;
  show: boolean;
}

const MARGIN_LEFT = 52;
const MARGIN_TOP = 30;
const MARGIN_BOTTOM = 20;
const WIDGET_PADDING = 24;
const Y_AXIS_HEIGHT = 20;
const LEGENDA_HEIGHT = 28;
const HEADER_HEIGHT = 19;
const TIP_HEIGHT = 70;
const TIP_WIDTH = 200;

export interface IMaintenanceDataForHistoryDetails {
  tip: IInfoTooltip;
  navData: IUnhealthy | IUnavailability;
}

class ElevatorMaintenance extends React.Component<
  ElevatorMaintenanceProps,
  { tip: IInfoTooltip; navData: any; onClickNavFunc: any }
> {
  // Method to get closest data point for event
  //
  getClosestDataPoint = (event: IVisit) => {
    const { data } = this.props;

    if (event && event.created_at && !isEmpty(data)) {
      const eventDay = event.created_at.substr(0, 10);
      const hit = data?.find((d) => {
        if (d.value_at) {
          const day = d.value_at.substr(0, 10);
          return day === eventDay;
        }
        return null;
      });
      return hit?.value;
    }
    return 0;
  };

  handleNavigateOnTooltip = (data: IMaintenanceDataForHistoryDetails) => {
    this.props.onClickNavFunc(data);
  };

  componentDidMount() {
    const {
      height,
      data,
      visits,
      servicecounterInitvalue,
      unavailability,
      drawFauxDOM,
      unhealthy,
    } = this.props;

    let { width } = this.props;

    width = width <= 0 ? 600 : width;
    // TODO: Use nl_NL style number formatting for now, need to look into i18n for this
    //
    d3.formatDefaultLocale(d3_nl_NL as FormatLocaleDefinition);

    const reactElement = this;
    // Let's do some layout calculation to keep everything sane
    //
    const actualWidth = width - MARGIN_LEFT - 2 * WIDGET_PADDING;
    const actualHeight =
      height -
      (MARGIN_TOP + Y_AXIS_HEIGHT + MARGIN_BOTTOM + LEGENDA_HEIGHT) -
      2 * WIDGET_PADDING;

    const fauxDom = this.props.connectFauxDOM("svg", "chart");

    const element = d3
      .select(fauxDom)
      .attr("width", width - 2 * WIDGET_PADDING)
      .attr(
        "height",
        height - 2 * WIDGET_PADDING - (HEADER_HEIGHT + LEGENDA_HEIGHT)
      ); // Minus 19 pixels for the header

    /**
     * If there is no data there is nothing to draw
     */
    const safeData = isArray(data) ? data : [];

    const points = safeData.map((d) => d.value);
    const dates = safeData.map((d) => new Date(d.value_at));

    // Pushes the end date so the chart renders the dates until the end period
    // even if the data is empty
    // https://trello.com/c/OtT26LxP
    //
    dates.unshift(new Date(LiftStatusStorage.startDate));
    dates.push(new Date(LiftStatusStorage.endDate));

    const x = d3
      .scaleTime()
      .domain(d3.extent(dates, (d) => d))
      .range([0, actualWidth])
      .nice();

    // Let's figure out what the service counter is for this data set
    //
    const maxCounter = d3.max(points);
    const minCounter = d3.min(points);
    const resetCounter = servicecounterInitvalue ?? maxCounter;
    const y = d3
      .scaleLinear()
      .domain([
        minCounter < 0 ? minCounter : 0,
        maxCounter > 40000 ? maxCounter : 40000,
      ])
      .range([actualHeight, 0]);

    // Let's draw some grid lines
    //
    const xGridLines = d3.axisLeft(y).ticks(10);

    element
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
      .call(
        xGridLines
          .tickSize(-(width - MARGIN_LEFT - 2 * WIDGET_PADDING))
          //@ts-ignore:next-line
          .tickFormat("")
      );

    // Let's draw unavailable periods, if there are any
    //
    const periodOffset = 5;
    element
      .selectAll("g.unvailable-group")
      .data(unavailability)
      .enter()
      .append("g")
      .attr("class", "unvailable-group")
      .attr(
        "transform",
        (d) =>
          `translate(${
            MARGIN_LEFT + x(new Date(d.created_at)) - periodOffset
          }, ${0})`
      )
      .on("click", function (d) {
        const rect = d3.select(this);
        const isSelected = rect.classed("selected");

        // Unselect other elements
        //
        element.selectAll(".selected").classed("selected", false);

        // Select this element
        //
        rect.classed("selected", !isSelected);

        if (!isSelected) {
          reactElement.setState({
            tip: {
              x: WIDGET_PADDING + x(new Date(d.created_at)) - periodOffset,
              y: WIDGET_PADDING + y(maxCounter) - periodOffset,
              title: `${reactElement.props.intl.formatMessage({
                id: `unavailability`,
              })}: ${moment(d.created_at).format("D MMM HH:mm")} - ${
                d.closed_at ? moment(d.closed_at).format("D MMM HH:mm") : ""
              }`,
              description: d.remarks,
              show: true,
            },
            navData: d,
          });
        } else {
          reactElement.setState((prevState) => {
            prevState.tip.show = false;
            return prevState;
          });
        }

        // Update the DOM
        //
        drawFauxDOM();
      })
      .append("rect")
      .attr("class", "unvailable-hitarea")
      .attr("rx", 2)
      .attr("width", (d) => {
        return Math.max(
          2,
          x(new Date(d.closed_at ? d.closed_at : new Date())) -
            x(new Date(d.created_at)) +
            2 * periodOffset
        );
      })
      .attr("height", actualHeight + 500 * periodOffset)
      .select(function () {
        return this.parentNode;
      })
      .append("rect")
      .attr("class", (d) => "unvailable-time time-" + +new Date(d.created_at))
      .attr("width", (d) =>
        Math.max(
          2,
          x(new Date(d?.closed_at ? d?.closed_at : new Date())) -
            x(new Date(d?.created_at))
        )
      )
      .attr("transform", `translate(${periodOffset}, ${periodOffset})`)
      .attr("height", "100%");

    // Ok now let's divide the path's into running counters and resets
    //
    const setOfCounters: Array<ICounterData[]> = [];
    const indexOfResets = keys(pickBy(data, (d) => d.reset === true));

    let startIndex = 0;
    each(indexOfResets, (index: string) => {
      const endIndex = parseInt(index, 10);
      setOfCounters.push(data.slice(startIndex, endIndex));
      startIndex = endIndex;
    });

    // Ok, this looks complicated but is really simple
    // We've found the places in the data set where the resets occur
    // But if the last set didn't haven't reached the reset counter
    // we need to add that manually. But only in the last counter was not a reset
    //
    const lastCounter = last(points);
    if (lastCounter < resetCounter) {
      setOfCounters.push(data.slice(startIndex));
    }

    // Where to place the x-axis.
    //
    // When the min and max value contain 0 we try to lock the a-xis on 0
    // In all other scenario's we lock it on the min value
    //
    let xAxisPosition = 0;
    if (minCounter < 0) {
      xAxisPosition = minCounter;
    }

    // Add the X Axis
    //
    element
      .append("g")
      .attr("class", "x-axis")
      .attr(
        "transform",
        `translate(${MARGIN_LEFT},${y(xAxisPosition) + MARGIN_TOP})`
      )
      .call(
        d3
          .axisBottom(x)
          .tickSize(7)
          //@ts-ignore:next-line
          .tickFormat(d3.timeFormat("%d %b"))
      );

    if (xAxisPosition < 0) {
      const xExtent = x.domain();
      element
        .append("line")
        .attr("class", "zero-line")
        .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
        .attr("stroke-dasharray", "10,10")
        .attr("x1", x(xExtent[0]))
        .attr("y1", y(0))
        .attr("x2", x(xExtent[1]))
        .attr("y2", y(0));
    }

    // Add the Y Axis
    //
    element
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
      .call(d3.axisLeft(y).tickSize(0));

    // Add the line object
    //
    const line = d3
      .line()
      .curve(d3.curveLinear)
      //@ts-ignore:next-line
      .x((d: ICounterData) => x(new Date(d.value_at)))
      //@ts-ignore:next-line
      .y((d: ICounterData) => y(d.value));

    // Now let's draw the set one-by-one
    //
    let lastKnownCounterValue: ICounterData;
    each(setOfCounters, (counters: ICounterData[], index) => {
      if (lastKnownCounterValue) {
        element
          .append("path")
          .datum([lastKnownCounterValue, first(counters)])
          .attr("key", "dottedline-" + index)
          .attr("className", "dottedline")
          .attr("stroke-dasharray", "10,10")
          .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
          //@ts-ignore:next-line
          .attr("d", line);
      }

      const blueLines = counters.filter((counter) => counter.value > 0);
      const redLines = counters.filter((counter) => counter.value <= 0);

      // If the line goes into red, that means we have to stitch the lines
      //
      if (size(redLines) > 0 && !isEmpty(blueLines)) {
        const lastBlueEntry = last(blueLines);
        lastBlueEntry.value = 0;
        blueLines.push(lastBlueEntry);
        redLines.unshift(lastBlueEntry);
      }

      element
        .append("path")
        .datum(blueLines)
        .attr("key", "blueline-" + index)
        .attr("className", "blueline")
        .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
        //@ts-ignore:next-line
        .attr("d", line);

      element
        .append("path")
        .datum(redLines)
        .attr("key", "redline-" + index)
        .attr("className", "redline")
        .attr("transform", `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
        //@ts-ignore:next-line
        .attr("d", line);

      lastKnownCounterValue = last(counters);
    });

    const cirlceRadialOffset = 6;

    element
      .selectAll("g.event-group")
      .data(visits)
      .enter()
      .append("g")
      .attr("class", "event-group")
      .attr(
        "transform",
        (d) =>
          `translate(${x(new Date(d.created_at)) + MARGIN_LEFT},${
            y(this.getClosestDataPoint(d)) + MARGIN_TOP
          })`
      )
      .on("click", function (event) {
        const circle = d3.select(this);
        const isSelected = circle.classed("selected");

        // Unselect other elements
        //
        element.selectAll(".selected").classed("selected", false);

        // Select this element
        //
        circle.classed("selected", !isSelected);

        if (!isSelected) {
          reactElement.setState({
            tip: {
              x:
                x(new Date(event.created_at)) +
                MARGIN_LEFT -
                10 -
                2 * cirlceRadialOffset,
              y:
                y(reactElement.getClosestDataPoint(event)) +
                MARGIN_TOP -
                cirlceRadialOffset,
              title: `${moment(event.created_at).format("D MMM HH:mm")} - ${
                event.closed_at
                  ? moment(event.closed_at).format("D MMM HH:mm")
                  : ""
              } ${reactElement.props.intl.formatMessage({
                id: `visit`,
              })}: ${reactElement.props.intl.formatMessage({
                id: `visit_type.${event.visit_type}`,
              })} (${event.visit_state})
              ${event.visit_result ? `Result: ${event.visit_result}` : ""}
              `,
              description: event.remarks,
              show: true,
            },
            navData: event,
          });
        } else {
          reactElement.setState((prevState) => {
            prevState.tip.show = false;
            return prevState;
          });
        }

        // Update the DOM
        //
        drawFauxDOM();
      })
      .append("circle")
      .attr("class", "event-hitarea")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 5 + cirlceRadialOffset)
      .select(function () {
        return this.parentNode;
      })
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 5)
      .attr("class", (d) => `event ${d.visit_type}`);

    ///////////////////////////////////////////////////////////////////////////////////////////
    // Now we draw the unhealthy periods if there are any
    //

    element
      .selectAll("g.unhealthy-group")
      .data(unhealthy)
      .enter()
      .append("g")
      .attr("class", "unhealthy-group")
      .attr(
        "transform",
        (d) =>
          `translate(${
            MARGIN_LEFT + x(new Date(d.created_at)) - periodOffset
          },${0})`
      )
      .on("click", function (d) {
        const rect = d3.select(this);
        const isSelected = rect.classed("selected");

        // Unselect other elements
        //
        element.selectAll(".selected").classed("selected", false);

        // Select this element
        //
        rect.classed("selected", !isSelected);

        if (!isSelected) {
          reactElement.setState({
            tip: {
              x: WIDGET_PADDING + x(new Date(d.created_at)) - periodOffset,
              y: WIDGET_PADDING + y(maxCounter) - periodOffset,
              title: `${reactElement.props.intl.formatMessage({
                id: `unhealthy`,
              })}: ${moment(d.created_at).format("D MMM HH:mm")} - ${
                d.closed_at ? moment(d.closed_at).format("D MMM HH:mm") : ""
              }`,
              description: d.remarks,
              show: true,
            },
            navData: d,
          });
        } else {
          reactElement.setState((prevState) => {
            prevState.tip.show = false;
            return prevState;
          });
        }

        // Update the DOM
        //
        drawFauxDOM();
      })
      .append("rect")
      .attr("class", "unhealthy-hitarea")
      .attr("rx", 2)
      .attr("width", (d) =>
        Math.max(
          2,
          x(new Date(d.closed_at ? d.closed_at : new Date())) -
            x(new Date(d.created_at)) +
            2 * periodOffset
        )
      )
      .attr("height", actualHeight + 500 * periodOffset)
      .select(function () {
        return this.parentNode;
      })
      .append("rect")
      .attr("class", (d) => "unhealthy-time time-" + +new Date(d.created_at))
      .attr("width", (d) =>
        Math.max(
          3,
          x(new Date(d.closed_at ? d.closed_at : new Date())) -
            x(new Date(d.created_at))
        )
      )
      .attr("transform", `translate(${periodOffset}, ${periodOffset})`)
      .attr("height", "100%");
  }

  render() {
    const { classes, label, chart } = this.props;

    return (
      <div
        className={classes.root}
        style={{
          width: `${this.props.width}px`,
          height: `${this.props.height}px`,
        }}
      >
        {this.state?.tip?.show && (
          <div
            className={classes.tip}
            style={{
              left: this.state?.tip?.x + MARGIN_LEFT - TIP_WIDTH / 2,
              top: this.state?.tip?.y + WIDGET_PADDING - TIP_HEIGHT,
              cursor: 'pointer'
            }}
            onClick={() => this.handleNavigateOnTooltip(this.state)}
          >
            <h4>{this.state?.tip?.title}</h4>
            <p>{this.state?.tip?.description}</p>
          </div>
        )}
        {this.props?.label && <h4 className={classes.label}>{label}</h4>}
        {chart}
        <ul className="legenda">
          <li className="failure">
            <FormattedMessage id="visit_type.failure" />
          </li>
          <li className="maintenance">
            <FormattedMessage id="visit_type.maintenance" />
          </li>
          <li className="repair">
            <FormattedMessage id="visit_type.repair" />
          </li>
          <li className="modernization">
            <FormattedMessage id="visit_type.modernization" />
          </li>
          <li className="other">
            <FormattedMessage id="visit_type.other" />
          </li>
          <li className="inspection">
            <FormattedMessage id="visit_type.inspection" />
          </li>
          <li className="approval">
            <FormattedMessage id="visit_type.approval" />
          </li>
        </ul>

        <div className="square">
          <ul className="legenda">
            <li className="unavailable">
              <FormattedMessage id="visit_type.unavailable" />
            </li>
            <li className="disconnected">
              <FormattedMessage id="visit_type.disconnected" />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withStyles({
  root: {
    margin: `${WIDGET_PADDING}px`,
    padding: WIDGET_PADDING,
    boxSizing: "border-box",
    borderRadius: 4,
    position: "relative",
  },
  tip: {
    position: "absolute",
    backgroundColor: "rgba(66,66,66, 0.85)",
    padding: "5px",
    borderRadius: "8px",
    color: "white",
    width: `${TIP_WIDTH}px`,
    height: `${TIP_HEIGHT}px`,
    "& h4": {
      margin: "5px",
      fontSize: "12px",
    },
    "& p": {
      margin: "5px",
      fontSize: "12px",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: 0,
      height: 0,
      border: "8px solid transparent",
      borderTopColor: "rgba(66,66,66, 0.85)",
      borderBottom: 0,
      marginLeft: "-8px",
      marginBottom: "-8px",
    },
  },
  label: {
    margin: 0,
    textAlign: "center",
    color: "#929EAA",
    backgroundColor: "white",
    fontWeight: 400,
    fontSize: "16px",
  },
  //@ts-ignore:nextline
})(withFauxDOM(injectIntl(ElevatorMaintenance)));
