import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AlertTemplate from "Components/CustomAlert/CustomAlert";

import GlobalStateProvider, {
  IGlobalStateBootstrapOptions,
} from "Providers/GlobalStateProvider";
import TranslationProvider from "Providers/TranslationProvider";
import React from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import Routes from "Router/Routes";
import classes from "./App.module.scss";
import "./theme/variables.scss";
import { LiftStatusStorage } from "./Utils/Storage";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#86B29C" },
    secondary: { main: "#b28c86" },
  },
});

// Alert options
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  containerStyle: {
    color: "#fff",
  },
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

// You can fetch the acess_token (userToken) from the URL as query string
// Otherwise we fallback to whatever is stored in the local storage
//
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let userToken = LiftStatusStorage.userToken;
if (urlParams.has("access_token")) {
  // First set access token
  //
  userToken = urlParams.get("access_token");
  LiftStatusStorage.userToken = userToken;

  // Check for start and end date
  //
  // Flag the period selection as custom so it won't be parsed by the period selector
  //
  if (urlParams.has("start_date") || urlParams.has("end_date")) {
    LiftStatusStorage.period = { unit: "custom", period: null };

    if (urlParams.has("start_date")) {
      LiftStatusStorage.startDate = urlParams.get("start_date");
    }
    if (urlParams.has("end_date")) {
      LiftStatusStorage.endDate = urlParams.get("end_date");
    }
  }

  // Check for organisation id
  //
  if (urlParams.has("organisation_id")) {
    LiftStatusStorage.organisationId = parseInt(
      urlParams.get("organisation_id"),
      10
    );
  }

  document.location.href =
    document.location.origin + document.location.pathname;
}

const globalStateOptions: IGlobalStateBootstrapOptions = {
  organisationId: LiftStatusStorage.organisationId,
  userToken,
  lockedLiftId: LiftStatusStorage.lockedLiftId,
};

function App() {
  return (
    <div className={classes.App}>
      <TranslationProvider>
        <GlobalStateProvider bootstrap={globalStateOptions}>
          <AlertProvider template={AlertTemplate} {...options}>
            <MuiThemeProvider theme={theme}>
              <Routes />
            </MuiThemeProvider>
          </AlertProvider>
        </GlobalStateProvider>
      </TranslationProvider>
    </div>
  );
}

export default App;
