import { socketClient } from "Api/Socket";
import React, { createContext } from "react";
import { IGetModuleSensorData } from "./SenseModuleProvider";

export interface IGetModuleSensorSeriesRequest {
  sensors: number[];
  liftId: number;
  from: number,
  to: number,
}

export const SenseModuleSeries = createContext(null);

const SenseModuleSeriesProvider = ({ children }: any) => {
  const fetchSensorSeriesData = async (
    request: IGetModuleSensorSeriesRequest
  ) => {
    /**
     * There must be a request to do this
     */
    if (!request) {
      return;
    }

    try {
      const response: any = await socketClient.post({
        liftId: request.liftId,
        uri: `/liftstatus/external/${socketClient.getExternalIdForLift(
          request.liftId
        )}/sensor/series`,
        urlParameters: {
          from: request.from,
          to: request.to,
          sensors: request.sensors,
          resolution: "week",
        },
      });

      return response?.data as IGetModuleSensorData[];
    } catch (error) {
      return error;
    }
  };

  return (
    <SenseModuleSeries.Provider
      value={{
        fetchSensorSeriesData,
      }}
    >
      {children}
    </SenseModuleSeries.Provider>
  );
};

export function useSenseModuleSeries() {
  const context = React.useContext(SenseModuleSeries);
  if (context === undefined) {
    throw new Error(
      "The useSenseModule hook must be used within a SenseModuleContext.Provider"
    );
  }
  return context;
}

export default SenseModuleSeriesProvider;
