import React from "react";
import classes from "./LiftDetailsDetails.module.scss";

interface ILiftDetailsDetailsProps {
  liftData: LiftStatus.Schemas.ILift;
  esNumber: number;
}
const LiftDetailsDetails = ({ liftData, esNumber }: ILiftDetailsDetailsProps) =>{
  return (
    <div className={classes.detailsExpansion}>
      <div className={classes.leftColumn}>
        <p>
          Gebouwnaam: <span>{liftData.details?.buildingname}</span>
        </p>
        <p>
          Liftnaam: <span>{liftData.details?.liftname}</span>
        </p>
        <p>
          Adres: <span>{liftData.details?.address}</span>
        </p>
        <p>
          Postcode: <span>{liftData.details?.postcode}</span>
        </p>
        <p>
          Plaats: <span>{liftData.details?.city}</span>
        </p>
        <p>
          Land: <span>{liftData.details?.country}</span>
        </p>
      </div>

      <div className={classes.rightColumn}>
        <p>
          Installateursnr: <span>{liftData.details?.liftnr}</span>
        </p>
        <p>
          Fabrieksnr: <span>{liftData.details?.manufacturernr}</span>
        </p>
        <p>
          Eigenaarsnr: <span>{liftData.details?.ownernr}</span>
        </p>
        <p>
          Adviseursnr: <span>{liftData.details?.advicernr}</span>
        </p>
        <p>
          Keuringsnr: <span>{liftData.details?.auditnr}</span>
        </p>
        <p>
          EDNLnr: <span>{liftData.details?.ednlnr}</span>
        </p>
        <p>
          SBCL ID: <span>{liftData.details?.sbcl_id}</span>
        </p>
        <hr />
        <p>
          Lift ID: <span>{liftData.id}</span>
        </p>
        {esNumber ? (
          <p>
            ES nr: <span>{esNumber}</span>
          </p>
        ) : <></>}


      </div>
    </div>
  );
};

export default LiftDetailsDetails;
