import React from 'react';
import { useFrame, useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { Group, LineSegments } from 'three';
import { each } from 'lodash';
import { IElevatorMaterials, IElevatorPositions } from './types';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import * as TWEEN from '@tweenjs/tween.js';
import { Tween } from '@tweenjs/tween.js';
import { ISenseSensorUpdate, useSenseModule } from 'Providers/SenseModuleProvider';
import { get, keys, first, values } from 'lodash';

const Elevator3d = () => {
  const { scene } = useLoader(
    ColladaLoader as any,
    '/assets/scenes/elevator-001.dae',
  );
  const pulseInterval = React.useRef<any>();
  const elevator = React.useRef();
  const [floorAnimationTween, setFloorAnimationTween] = React.useState<Tween<{topPos: number}> | null>(null);
  const animateTheFloor = React.useRef<{animate: boolean}>();
  const [speed] = React.useState(2000);
  const [floorHeight] = React.useState(520);
  const [doorWidth] = React.useState(12.5);
  const senseModuleProvider = useSenseModule();
  const [movements, setMovements] = React.useState<ISenseSensorUpdate | undefined>();
  const [offline, setOffline] = React.useState<boolean>(false);

  // Initial floor animation setting
  //
  animateTheFloor.current = { animate: true };

  const arrowDown = scene.getObjectByName('arrow_down');
  const arrowUp = scene.getObjectByName('arrow_up');
  const elevatorBackWall = scene.getObjectByName('elevator_back_wall');
  const elevatorBackWallBeam = scene.getObjectByName('achter_wand_beam');
  const frontDoor = scene.getObjectByName('front_door');
  const frontDoorLeft = scene.getObjectByName('front_door_left');
  const frontDoorRight = scene.getObjectByName('front_door_right');
  const backDoor = scene.getObjectByName('back_door');
  const backDoorLeft = scene.getObjectByName('back_door_left');
  const backDoorRight = scene.getObjectByName('back_door_right');
  const floorSeperatorIndicator = scene.getObjectByName('floor_seperator_indicator') as Group;

  const [positions] = React.useState<IElevatorPositions>({
    frontDoor: {
      left: {
        open: frontDoorLeft.position.y + doorWidth,
        closed: frontDoorLeft.position.y,
      },
      right: {
        open: frontDoorRight.position.y - doorWidth,
        closed: frontDoorRight.position.y,
      },
    },
    backDoor: {
      left: {
        open: backDoorLeft.position.y + doorWidth,
        closed: backDoorLeft.position.y,
      },
      right: {
        open: backDoorRight.position.y - doorWidth,
        closed: backDoorRight.position.y,
      },
    },
    floor: {
      down: floorSeperatorIndicator.position.z,
      up: floorSeperatorIndicator.position.z + floorHeight
    },
  });

  // when ever the socket emits data handle the sensor data update
  //
  React.useEffect(() => {
    if (senseModuleProvider && senseModuleProvider.movementPoints) {
      setMovements(senseModuleProvider.movementPoints);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.movementPoints]);

  // Will check socket state & data of sensor 524 to make sure its healthy
  //
  React.useEffect(() => {
    if (senseModuleProvider?.senseModuleSensorData?.values[524] === 0 || !senseModuleProvider?.socketConnected) {
      // If it's 0 its unhealthy
      setOffline(true);
    } else {
      setOffline(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    senseModuleProvider.senseModuleSensorData,
    senseModuleProvider.socketConnected,
  ]);

  // Create some base materials
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const materials: IElevatorMaterials = {
    active: new THREE.MeshPhongMaterial({ color: 0xff0000 }),
    normal: new THREE.MeshPhongMaterial({
      color: 0xa3a3a3,
      transparent: true,
    }),
    pulseOn: new THREE.MeshPhongMaterial({
      color: 0x6255ff,
      transparent: true,
    }),
    pulseOff: new THREE.MeshPhongMaterial({
      color: 0x6255ff,
      transparent: false,
    }),
    transparent: new THREE.MeshPhongMaterial({
      color: 0xffffff,
      transparent: true,
    }),
    floor: new THREE.MeshPhongMaterial({
      color: 0x85b19b,
      transparent: true,
    }),
  };

  // Method to change a material
  //
  const changeMaterial = React.useCallback((node: Group, material: THREE.MeshPhongMaterial) => {
    if (node && typeof node.traverse === 'function') {
      node.traverse((childNode: LineSegments) => {
        childNode.material = material;
      });
    }
  }, []);

  // Open front door animation
  //
  const openFrontDoor = React.useCallback((skipAnimation = false) => {
    // Only open the doors if they are in the closed position
    //
    if (frontDoorLeft.position.y === positions.frontDoor.left.closed) {
        if (skipAnimation === true) {
          frontDoorLeft.position.y  = positions.frontDoor.left.open;
          frontDoorRight.position.y = positions.frontDoor.right.open;
        } else {
          // Perform the transition
          //
          new TWEEN.Tween({
            leftPos:  positions.frontDoor.left.closed,
            rightPos: positions.frontDoor.right.closed
          }).to({
            leftPos:  positions.frontDoor.left.open,
            rightPos: positions.frontDoor.right.open
          },
            speed,
          ).onUpdate(function({ leftPos, rightPos }) {
            frontDoorLeft.position.y = leftPos;
            frontDoorRight.position.y = rightPos;
          }).onComplete( () => {
            frontDoorLeft.position.y  = positions.frontDoor.left.open;
            frontDoorRight.position.y = positions.frontDoor.right.open;
          }).start();
        }
    }
  }, [
    frontDoorLeft,
    frontDoorRight,
    positions,
    speed,
  ]);

  // Close front door animation
  //
  const closeFrontDoor = React.useCallback((skipAnimation = false) => {
    // Only close the doors if they are in the open position
    //
    if ( frontDoorLeft.position.y === positions.frontDoor.left.open ) {
      if (skipAnimation === true) {
        frontDoorLeft.position.y  = positions.frontDoor.left.closed;
        frontDoorRight.position.y = positions.frontDoor.right.closed;
      } else {
        // Perform the transition
        //
        new TWEEN.Tween({
          leftPos:  positions.frontDoor.left.open,
          rightPos: positions.frontDoor.right.open
        })
        .to({
          leftPos:  positions.frontDoor.left.closed,
          rightPos: positions.frontDoor.right.closed
        },
          speed
        ).onUpdate( function({ leftPos, rightPos }) {
          frontDoorLeft.position.y = leftPos;
          frontDoorRight.position.y  = rightPos;
        })
        .onComplete( () => {
          frontDoorLeft.position.y = positions.frontDoor.left.closed;
          frontDoorRight.position.y = positions.frontDoor.right.closed;
        }).start();
      }
    }
  }, [
    frontDoorLeft,
    frontDoorRight,
    positions,
    speed,
  ]);

  // Open back door animation
  //
  const openBackDoor = React.useCallback((skipAnimation = false) => {
    // Only open the doors if they are in the closed position
    //
    if (backDoorLeft.position.y === positions.backDoor.left.closed) {
      if (skipAnimation === true) {
        backDoorLeft.position.y = positions.backDoor.left.open;
        backDoorRight.position.y = positions.backDoor.right.open;
      } else {
        // Perform the transition
        //
        new TWEEN.Tween({
          leftPos: positions.backDoor.left.closed,
          rightPos: positions.backDoor.right.closed
        }).to({
          leftPos: positions.backDoor.left.open,
          rightPos: positions.backDoor.right.open
        },
          speed,
        ).onUpdate(function({ leftPos, rightPos }) {
          backDoorLeft.position.y = leftPos;
          backDoorRight.position.y = rightPos;
        }).onComplete( () =>
        {
          backDoorLeft.position.y = positions.backDoor.left.open;
          backDoorRight.position.y = positions.backDoor.right.open;
        }).start();
      }
    }
  }, [
    backDoorLeft,
    backDoorRight,
    positions,
    speed,
  ]);

  // Close back door animation
  //
  const closeBackDoor = React.useCallback((skipAnimation = false) => {
    // Only close the doors if they are in the open position
    //
    if (backDoorLeft.position.y === positions.backDoor.left.open) {
      if (skipAnimation === true) {
        backDoorLeft.position.y  = positions.backDoor.left.closed;
        backDoorRight.position.y = positions.backDoor.right.closed;
      } else {
        // Perform the transition
        //
        new TWEEN.Tween({
          leftPos: positions.backDoor.left.open,
          rightPos: positions.backDoor.right.open
        })
        .to({
          leftPos: positions.backDoor.left.closed,
          rightPos: positions.backDoor.right.closed
        },
          speed,
        )
        .onUpdate(function({ leftPos, rightPos }) {
          backDoorLeft.position.y = leftPos;
          backDoorRight.position.y = rightPos;
        })
        .onComplete(() => {
          backDoorLeft.position.y = positions.backDoor.left.closed;
          backDoorRight.position.y = positions.backDoor.right.closed;
        }).start();
      }
    }
  }, [
    backDoorLeft,
    backDoorRight,
    positions,
    speed,
  ]);

  // Stop floor animation
  //
  const stopAnimateFloorsIndicator = React.useCallback((elavatorDirection: 'down' | 'up') => {
    if (floorAnimationTween) {
      animateTheFloor.current.animate = false;
      floorAnimationTween.stop();
      let easingAmount = 50;

      if (elavatorDirection === 'down') {
        easingAmount = -easingAmount;
      }

      // Easy out floor
      //
      new TWEEN.Tween({
        topPos: floorSeperatorIndicator.position.z
      })
      .to({
        topPos: floorSeperatorIndicator.position.z + easingAmount
      })
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(function({ topPos }) {
        floorSeperatorIndicator.position.z = topPos;
      })
      .onComplete(() => {
        // Fade floor indicator away
        //
        new TWEEN.Tween({
          opacity: 0.5
        })
        .to({
          opacity: 0
        }, 1000)
        .onUpdate(function () {
          const material = new THREE.MeshPhongMaterial({
            color: 0x85B19B,
            transparent: true
          });
          changeMaterial(floorSeperatorIndicator, material);
        })
        .start();
      })
      .start();
    }
  }, [
    changeMaterial,
    floorAnimationTween,
    floorSeperatorIndicator,
    animateTheFloor,
  ]);

  const animateFloorsIndicator = React.useCallback((elavatorDirection: 'up' | 'down') => {
    const duration = speed;
    const continious = animateTheFloor.current.animate;
    let startPos = positions.floor.down;
    let endPos = positions.floor.up;

    if (elavatorDirection === 'down') {
      startPos = positions.floor.up;
      endPos = positions.floor.down;
    }

    changeMaterial(floorSeperatorIndicator, materials.floor);

    // Remove any previous tweens
    //
    if (floorAnimationTween) {
      floorAnimationTween.stop();
    }

    // Perform the transition
    //
    // console.log(`start tween startPos: ${startPos}, endPos: ${endPos}, duration: ${duration}`);
    const tween = new TWEEN.Tween({
      topPos: startPos
    })
    .to({
      topPos: endPos
    },
      duration
    )
    .onUpdate( function({ topPos }) {
      floorSeperatorIndicator.position.z = topPos;
    })
    .onComplete( () => {
      floorSeperatorIndicator.position.z = endPos;
      setFloorAnimationTween(null);

      // Another turn?
      //
      if (continious === true) {
        animateFloorsIndicator(elavatorDirection);
      }
    })
    .start();
    setFloorAnimationTween(tween);
  }, [
    changeMaterial,
    floorAnimationTween,
    floorSeperatorIndicator,
    animateTheFloor,
    materials.floor,
    positions.floor,
    speed,
  ]);

  const startAnimateFloorsIndicator = React.useCallback((direction: 'up' | 'down') => {
    animateTheFloor.current.animate = true;
    animateFloorsIndicator(direction);
  }, [
    animateFloorsIndicator,
    animateTheFloor,
  ]);

  const hideNode = React.useCallback((node: Group) => {
    if (node) {
      node.traverse((childNode) => {
        childNode.visible = false;
      });
    }
  }, []);

  const showNode = React.useCallback((node: Group) => {
    if (node) {
      node.traverse((childNode) => {
        childNode.visible = true;
      });
    }
  }, []);

  showNode(frontDoor);
  hideNode(backDoor);
  showNode(elevatorBackWall);
  showNode(elevatorBackWallBeam);

  // Start pulse arrow animation
  //
  const startPulseArrowAnimaton = React.useCallback(
    (arrow) => {
      if (arrow) {
        let counter = 1;
        pulseInterval.current = setInterval(() => {
          const selectedIndex = counter % 8;
          each(arrow.children, (node, index) => {
            if (index === selectedIndex) {
              changeMaterial(node, materials.pulseOn);
            } else {
              changeMaterial(node, materials.pulseOff);
            }
          });
          counter++;
        }, 180);
      }
    },
    [pulseInterval, changeMaterial, materials]
  );

  // End pulse arrow animation
  //
  const stopPulseArrowAnimaton = React.useCallback(() => {
    if (pulseInterval.current) {
      clearInterval(pulseInterval.current);
    }
  }, [pulseInterval]);

  // Hide arrow(s)
  //
  const hideArrows = React.useCallback(() => {
    hideNode(arrowUp);
    hideNode(arrowDown);

    // Animate the floors
    //
    stopPulseArrowAnimaton();
    stopAnimateFloorsIndicator('down');
  }, [
    arrowDown,
    arrowUp,
    hideNode,
    stopAnimateFloorsIndicator,
    stopPulseArrowAnimaton,
  ]);

  // Show arrow up indicator
  //
  const showArrowUp = React.useCallback(() => {
      stopPulseArrowAnimaton();
      hideNode(arrowDown);
      showNode(arrowUp);
      startPulseArrowAnimaton(arrowUp);

      // Animate the floors
      //
      startAnimateFloorsIndicator('down');
  }, [
    arrowDown,
    arrowUp,
    hideNode,
    showNode,
    startAnimateFloorsIndicator,
    startPulseArrowAnimaton,
    stopPulseArrowAnimaton,
  ]);

  // Show arrow down indicator
  //
  const showArrowDown = React.useCallback(() => {
    stopPulseArrowAnimaton();
    hideNode(arrowUp);
    showNode(arrowDown);
    startPulseArrowAnimaton(arrowDown);

    // Animate the floors
    //
    startAnimateFloorsIndicator( 'up' );
  }, [
    arrowDown,
    arrowUp,
    hideNode,
    showNode,
    startAnimateFloorsIndicator,
    startPulseArrowAnimaton,
    stopPulseArrowAnimaton,
  ]);

  (window as any).debug = {
    startDown: React.useCallback(() => {
      showArrowDown();
    }, [showArrowDown]),
    startUp: React.useCallback(() => {
      showArrowUp();
    }, [showArrowUp]),
    openFrontDoor: () => {
      openFrontDoor();
    },
    closeFrontDoor: () => {
      closeFrontDoor();
    },
    openBackDoor: () => {
      openBackDoor();
    },
    closeBackDoor: () => {
      closeBackDoor();
    },
    stop: React.useCallback(() => {
      hideArrows();
      stopAnimateFloorsIndicator('down');
    }, [stopAnimateFloorsIndicator, hideArrows]),
  };

  const processSensorUpdate = React.useCallback((sensorId: string, value: string | number | null, skipAnimation = false) => {
    switch (sensorId) {
      // Beweging
      //
      case "500":
        if ( value === "U" ) {
          showArrowUp();
        } else if ( value === "D") {
          showArrowDown();
        } else {
          hideArrows();
        }
        break;

      // Current stop place
      //
      case "515":
        // setCurrentFloor( value );
      break;

      // Last passed stop place
      //
      case "514":
        // setPreviousFloor( value );
      break;

      // Position frontdoor
      //
      case "502":
        if (value === "C" || value === "-") {
          closeFrontDoor(skipAnimation);
        } else if ( value === "O" || value === "+" ) {
          openFrontDoor( skipAnimation );
        } else {
          closeFrontDoor( skipAnimation );
        }
      break;

      // Position backdoor
      //
      case "503":
        if ( value === "C" || value === "-" ) {
          closeBackDoor(skipAnimation);
        } else if ( value === "O" || value === "+" ) {
          openBackDoor( skipAnimation );
        } else {
          closeBackDoor( skipAnimation );
        }
      break;

      // 504: Safety strip (floor)
      // 505: Safety strip (door)
      // 506: Tempature safety
      // 513: Generic error
      //
      case "504":
      case "505":
      case "506":
      // case "513":
        // frontDoorComponent.updateStatus( { value: value } );
      break;
    }
  }, [
    hideArrows,
    showArrowDown,
    showArrowUp,
    openFrontDoor,
    openBackDoor,
    closeFrontDoor,
    closeBackDoor
  ]);

  // Process movement update sensor events
  //
  React.useEffect(() => {
    const update = get(movements, 'message.data');
    const key = first(keys(update));
    const value = first(values(update));
    processSensorUpdate(key, value, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ movements ]);

  // Process flipping between on and offline
  //
  React.useEffect(() => {
    if ( offline ) {
      hideArrows();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ offline ]);

  // Initial sensor values
  //
  React.useEffect(() => {
    const values = get(senseModuleProvider, 'senseModuleSensorData.values');
    if (values) {
      for (const sensorId in values) {
        processSensorUpdate(`${sensorId}`, values[sensorId], true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.senseModuleSensorData]);

  scene.scale.x = scene.scale.y = scene.scale.z = 0.03;
  scene.updateMatrix();

  // Hoist animation frame from react three fiber to feed into TWEEN
  //
  useFrame(() => {
    TWEEN.update();
  });

  // Initially execute these functions
  //
  React.useEffect(() => {
    hideArrows();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <primitive ref={elevator} dispose={null} object={scene} />
    </>
  );
};

export default Elevator3d;
