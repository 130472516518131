import {
  Button,
  Card,
  CircularProgress, IconButton,
  InputBase,
  Paper,
  TextField
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import SideMenu from "Components/SideMenu/SideMenu";
import { isEmpty } from "lodash";
import LiftDetails from "Pages/LiftDetails/LiftDetails";
// eslint-disable-next-line no-unused-vars
import {
  GlobalStateContext,
  IGlobalState
} from "Providers/GlobalStateProvider";
// eslint-disable-next-line no-unused-vars
import LiftsProvider, { LiftsContext } from "Providers/LiftsProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { LiftStatusStorage } from "Utils/Storage";
import Lift from "../../Components/Lift/Lift";
import classes from "./Lifts.module.scss";


const LiftsInternal = () => {
  const [lifts, setLifts] = useState({} as LiftStatus.Schemas.IPagedResult);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState<
    LiftStatus.Schemas.ILiftSearchRequestDto | undefined
  >();
  const [liftsToRender, setLiftsToRender] = useState<
    LiftStatus.Schemas.ILift[]
  >([]);
  const [page, setPage] = React.useState(1);
  const [pageLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const liftsProvider = useContext(LiftsContext);
  const [globalState] = useContext<[IGlobalState]>(GlobalStateContext);
  const [search$] = useState(() => new Subject<string>());
  const { liftId } = useParams();


  useEffect(() => {
    const subscription = search$.pipe(debounceTime(200)).subscribe((query) => {
      performNormalSearch(query)
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performNormalSearch = (query, pageNum?: number) => {
    liftsProvider.textSearch({
      limit: pageLimit,
      offset: pageNum ? (pageNum - 1) * pageLimit : 0,
      search: query,
      organisationId: LiftStatusStorage.organisationId,
    });
  }

  // Setup advances search
  //
  const performAdvancedSearch = useCallback((query, pageNum?: number) => {
    if (isEmpty(advancedSearchQuery)) {
      return;
    }

    query.organisationId = globalState.organisationId;
    setSearchQuery("");
    setPage(1);

    liftsProvider.advancedSearch({
      limit: pageLimit,
      offset: pageNum ? (pageNum - 1) * pageLimit : 0,
      advancedSearch: query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedSearchQuery, pageLimit, globalState.organisationId]);

  /**
   * Handle Lift provider response for text search
   */
  useEffect(() => {
    if (liftsProvider.textSearchResults === "loading") {
      return;
    }

    const liftsData = liftsProvider.textSearchResults;

    if (!isEmpty(liftsData)) {
      setLifts(liftsData);

      const nrOfPages = Math.floor(liftsData.total / pageLimit);

      setLiftsToRender(liftsData.items);
      setTotalPages(nrOfPages);
    }
  }, [page, pageLimit, lifts, liftsProvider.textSearchResults]);

  /**
   * Handle Lift provider response for advanced search
   */
  useEffect(() => {
    if (liftsProvider.advancedSearchResults === "loading") {
      return;
    }

    const liftsData = liftsProvider.advancedSearchResults;

    if (!isEmpty(liftsData)) {
      setLifts(liftsData);

      const nrOfPages = Math.floor(liftsData.total / pageLimit);

      setLiftsToRender(liftsData.items);
      setTotalPages(nrOfPages);
    }
  }, [page, pageLimit, lifts, liftsProvider.advancedSearchResults]);

  /**
   * Initial lifts search on page load
   */
  useEffect(() => {
    if (advancedSearchQuery) {
      performAdvancedSearch(advancedSearchQuery);
    } else {
      performNormalSearch(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.organisationId]);


  const handlePageChange = (event, pageNum: number) => {
    event.stopPropagation();
    if (advancedSearchQuery) {
      performAdvancedSearch(advancedSearchQuery, pageNum);
    } else {
      performNormalSearch(searchQuery, pageNum);
    }

    setPage(pageNum);
  };

  return (
    <div className={classes.root}>
      <SideMenu/>
      <div className={classes.content}>
        {/** Show lift details */}
        {liftId ? (
          <div className={classes.liftDetails}>
            <LiftDetails />
          </div>
        ) : (
          <div className={classes.liftsList}>
            {/** Title */}
            <span className={classes.title}>
              <h4>
                <FormattedMessage id="lifts" />
              </h4>
              <h4 className={classes.green}>
                <FormattedMessage id="total.lifts" />:{" "}
                <b>{lifts?.total ?? "0"}</b>
              </h4>
            </span>
            <Paper className={classes.search}>
              <InputBase
                value={searchQuery}
                onChange={(event) => {
                  search$.next(event?.target?.value);
                  setSearchQuery(event?.target?.value);

                  if (!isEmpty(advancedSearchQuery)) {
                    setAdvancedSearchQuery(undefined);
                  }
                }}
                className={classes.input}
                placeholder="Search"
              />
              <IconButton className={classes.iconButton}>
                <SearchIcon />
              </IconButton>
              <Button
                className={classes.advancedSearchBtn}
                onClick={() => setOpenAdvancedSearch(!openAdvancedSearch)}
              >
                <FormattedMessage id="advance" />
              </Button>
            </Paper>
            {openAdvancedSearch ? (
              <Card className={classes.advSearch}>
                <form onSubmit={() => performAdvancedSearch(advancedSearchQuery)}>
                  <TextField
                    className={classes.advSearchField}
                    label="Telefoonnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;

                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          phoneNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Installateursnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          liftNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Fabrieksnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          manufacturerNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Eigenaarsnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          ownerNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Adviseursnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          advisorNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Keuringsnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          auditNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="EDNLnummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          ednlNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Serienummer"
                    color="secondary"
                    type="number"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          serialNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Lift ID"
                    color="secondary"
                    type="number"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          liftIdentifier: value
                            ? parseInt(value, 10)
                            : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Adres"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          address: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="Plaats"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          city: value ? value : undefined,
                        };
                      });
                    }}
                  />
                  <TextField
                    className={classes.advSearchField}
                    label="ES Nummer"
                    color="secondary"
                    onChange={(event) => {
                      const { value } = event.target;
                      setAdvancedSearchQuery((prevValue) => {
                        return {
                          ...prevValue,
                          esNumber: value ? value : undefined,
                        };
                      });
                    }}
                  />
                </form>

                <div className={classes.advBottomRow}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      performAdvancedSearch(advancedSearchQuery);
                    }}
                  >
                    <FormattedMessage id="search" />
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setOpenAdvancedSearch(!openAdvancedSearch)}
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Card>
            ) : (
              <></>
            )}
            {liftsProvider.textSearchResults === "loading" ? (
              <div className={classes.progressIndicator}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Card style={{ gridColumn: "1/5" }}>
                {liftsToRender?.map((lift: LiftStatus.Schemas.ILift) => (
                  <Lift key={lift.id} lift={lift} />
                ))}
              </Card>
            )}
            <div className={classes.pagination}>
              {totalPages > 1 ? (<Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />) : (<></>)}

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const Lifts = () => (
  <LiftsProvider>
    <LiftsInternal />
  </LiftsProvider>
);

export default Lifts;
