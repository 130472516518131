import { Button, Card } from "@material-ui/core";
import Lift from "Components/Lift/Lift";
import Visit from "Components/Visit/Visit";
import moment from "moment";
import {
  EGlobalStateCases,
    GlobalStateContext, IGlobalState
} from "Providers/GlobalStateProvider";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import classes from "./EventDetailsCard.module.scss";






const EventDetailsCard = (props: { event: LiftStatus.Schemas.IEvent }) => {
  const intl = useIntl();
  const [, globalDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const history = useHistory();

  const navigateToDetails = (event: LiftStatus.Schemas.IEvent) => {
    // A set list of events has different functionality when navigating to lift details
    //
    if (
      event.event_key === "P-LIFT-UNAVAILABLE" ||
      event.event_key === "P-LIFT-UNAVAILABLE_OUTSIDE_SLA" ||
      event.event_key === "P-LIFT-SENSE-SENSORDATA-FAILURE_SENSOR" ||
      event.event_key === "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY"
    ) {
      // if we push the state because of the specific event type we'd also want to make sure
      // we are not in "RealTime" or some panels will not be shown, such as the History panel
      //
      globalDispatch({
        type: EGlobalStateCases.switchViewToCurrent,
        payload: false,
      });
      // Now that we aren't in realtime anymore, we can navigate and open the history panel
      //
      history.push({
        pathname: `/lifts/${event.lift_id}`,
        state: { eventCreatedAt: event.created_at },
      });
    } else {
      history.push(`/lifts/${event.lift_id}`);
    }
  };
  return (
    <Card key={props.event.id} className={classes.wrapper}>
      <div className={classes.card}>
        <div className={classes.cardContainer}>
          {props.event.event_key === "P-LIFT-SENSE-VISIT" ? (
            <Visit event={props.event}></Visit>
          ) : (
            <div>
              <h4>
                {intl.formatMessage({
                  id: `event.${props.event.event_key}`,
                })}{" "}
              </h4>

              <p className={classes.dates}>
                {props.event.event_key.startsWith("P") ? "" : ""}
                {moment(props.event.created_at).format("DD-MM-YYYY HH:mm")}
                {props.event.event_key.startsWith("P") ? (
                  <>
                    {" "}
                    -{" "}
                    {props.event.closed_at
                      ? `${moment(props.event.closed_at).format(
                          "DD-MM-YYYY HH:mm"
                        )} (${moment
                          .duration(
                            moment(props.event.closed_at).diff(
                              props.event.created_at
                            )
                          )
                          .humanize()})`
                      : `open (${moment
                          .duration(moment().diff(props.event.created_at))
                          .humanize()})`}
                  </>
                ) : (
                  ""
                )}
              </p>

              {props.event.remarks ? (
                <div className={classes.remarks}>
                  <p className={classes.remarksTitle}>Notities</p>
                  {props.event?.remarks.split("\n").map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        <div className={classes.btnWrapper}>
          {props.event.lift ? (
            <Lift lift={props.event.lift} padding={0} />
          ) : (
            <></>
          )}

          <Button
            className={classes.btn}
            onClick={() => navigateToDetails(props.event)}
          >
            {intl.formatMessage({ id: "navigate.to.lift" })}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default EventDetailsCard;
