import React, { useContext, useEffect, useState } from "react";
import EventSearchDataProvider, {
  EventSearchDataContext,
  IEventDataRequest,
} from "Providers/EventDataProvider";
import { isEmpty } from "lodash";
import { Card, CircularProgress } from "@material-ui/core";
import classes from "./OpenIssues.module.scss";
import { useIntl } from "react-intl";
import moment from "moment";

import VisitPayload from "../../Utils/VisitPayload";

interface EventsProps {
  liftId?: number | string;
  eventKey?: string[];

  pagination?: {
    limit: number;
    offset: number;
  };
}

const OpenIssuesInternal = (props: EventsProps) => {
  const eventDataProvider = useContext(EventSearchDataContext);
  const [events, setEvents] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    if (!eventDataProvider || eventDataProvider.response === "loading") {
      return;
    }

    if (!isEmpty(eventDataProvider.response)) {
      setEvents(eventDataProvider.response.items);
    }
  }, [eventDataProvider]);

  useEffect(() => {
    if (!eventDataProvider) {
      return;
    }

    const eventDataRequest: IEventDataRequest = {
      eventKey: props.eventKey,
    };

    if (props.liftId) {
      eventDataRequest.liftId =
        typeof props.liftId === "string"
          ? parseInt(props.liftId, 10)
          : props.liftId;
    }

    if (props.pagination) {
      eventDataRequest.pagination = {
        limit: props.pagination.limit,
        offset: props.pagination.offset,
      };
    } else {
      eventDataRequest.pagination = {
        limit: 999999,
        offset: 0,
      };
    }

    eventDataRequest.openOnly = true;

    eventDataProvider.fetchEventSearchData(eventDataRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.liftId, props.eventKey, props.pagination]);

  return (
    <div className={classes.root}>
      {eventDataProvider.response === "loading" ? (
        <div className={classes.progressIndicator}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Card className={classes.cardContent}>
          <h3>Open issues</h3>

          {!events?.length && <p>Geen open issues</p>}

          {events?.map((event: LiftStatus.Schemas.IEvent) => {
            const title = [];

            let visitPayload: VisitPayload | undefined;

            switch (event.event_key) {
              case "P-LIFT-SENSE-VISIT":
                visitPayload = new VisitPayload(event.payload);

                title.push(`Bezoek: ${visitPayload.visitReason}`);
                break;

              default:
                title.push(
                  `${intl.formatMessage({ id: `event.${event.event_key}` })}`
                );
            }

            const subtitle = [];
            subtitle.push(
              `Sinds: ${moment(event.created_at).format("DD-MM-YYYY HH:mm")}`
            );

            return (
              <div key={event.id} className={classes.card}>
                <div className={classes.cardContainer}>
                  <h4>{title.join(", ")}</h4>
                  <p className={classes.subTitle}>{subtitle.join(", ")}</p>

                  { event.remarks ? (
                                  <div className={classes.remarks}>
                                    <p className={classes.remarksTitle}>Notities</p>
                                    { event?.remarks.split('\n').map((line, i) => (
                                      <p key={i}>
                                          {line}
                                      </p>
                                      ))
                                    }
                                  </div>
                                ) : '' }

                </div>
              </div>
            );
          })}
        </Card>
      )}
    </div>
  );
};

const OpenIssues = (props: EventsProps) => (
  <EventSearchDataProvider>
    <OpenIssuesInternal {...props} />
  </EventSearchDataProvider>
);

export default OpenIssues;
