import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ElevatorHistoryDetails from "Components/ElevatorHistoryDetails/ElevatorHistoryDetails";
import ElevatorHistorySwimlanes from "Components/ElevatorHistorySwimlanes/ElevatorHistorySwimlanes";
import { IMaintenanceDataForHistoryDetails } from "Components/ElevatorMaintenance/ElevatorMaintenance";
import moment from "moment";
import SenseModuleHistoryProvider from "Providers/SenseModuleHistoryProvider";
import StatusCodeProvider from "Providers/StatusCodeProvider";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LocalizedMomentUtils } from "Utils/Helpers";
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./LiftDetailsHistory.module.scss";

interface ILiftDetailsHistoryProps {
  width: number;
  esNumber: string;
  loading?: boolean;
  alarmorganisationId: number;
  fromElevatorMaintenanceChart?: IMaintenanceDataForHistoryDetails;
  overwriteDatePickerWithEventDate?: string;
}

const LiftDetailsHistory = React.memo(
  ({
    width,
    esNumber,
    loading,
    fromElevatorMaintenanceChart,
    overwriteDatePickerWithEventDate,
  }: ILiftDetailsHistoryProps) => {
    const intl = useIntl();
    const [defaultValueForPeriodTime, setDefaultValueForPeriodTime] =
      useState("0");

    const defaultMiliSeconds = +moment(LiftStatusStorage.startDate).startOf(
      "hour"
    );
    const overwriteDate = useRef(overwriteDatePickerWithEventDate);
    const [navigatedDate, setNavigatedDate] = useState(overwriteDatePickerWithEventDate);
    const [dateRangePicker, setDateRangePicker] = useState<{
      from: number | null;
      to: number | null;
    }>({
      from: defaultMiliSeconds,
      to: defaultMiliSeconds + 3600000,
    });

    const [dateRangeForSwimlanes, setDateRangeForSwimlanes] = useState({
      from: defaultMiliSeconds,
      to: defaultMiliSeconds + 3600000,
    });
    // This use effect makes this a subset of the period selector
    // It'll trigger every time the period selector changes dates
    //
    useEffect(() => {
      let resultsInMilliseconds;

      if (LiftStatusStorage.period.period === 0) {
        resultsInMilliseconds = +moment(new Date()).startOf("hour");
      } else {
        resultsInMilliseconds = +moment(LiftStatusStorage.endDate).startOf(
          "hour"
        );
      }


      if (overwriteDate.current) {
        const startOfEventHour = +moment(overwriteDate.current).startOf("hour");
        resultsInMilliseconds = startOfEventHour - 120000; // we remove 2 minutes here per request of Arne to give the event more breathing room

          setDateRangeForSwimlanes({
            from: startOfEventHour,
            to: startOfEventHour + 3600000,
          })
        } else {
          setDateRangeForSwimlanes({
            from: resultsInMilliseconds,
            to: resultsInMilliseconds + 3600000,
          })
        }



      setDateRangePicker({
        from: resultsInMilliseconds,
        to: resultsInMilliseconds + 3600000,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      LiftStatusStorage.startDate,
      LiftStatusStorage.endDate,

    ]);

    const createIndexLocationForSelector = React.useCallback(() => {
      let date = moment(dateRangePicker.from).format("HH:hh").substr(0, 2);

      if (overwriteDate.current) {
        date = moment(overwriteDate.current).format("HH:hh").substr(0, 2);
      }

      // If hour starts with 0 we need to return the second string to
      // set a default value for the picker because we use it as an index to an array
      // string manipulation I do not enjoy but this is
      // the current solution for the current implementation
      //
      if (date.slice(0, 1) === "0") {
        setDefaultValueForPeriodTime(date.slice(1, 2));
      } else {
        setDefaultValueForPeriodTime(date);
      }
      overwriteDate.current = null;
    }, [dateRangePicker.from]);

    useEffect(() => {
      createIndexLocationForSelector();
    }, [dateRangePicker, createIndexLocationForSelector]);

    useEffect(() => {
      if ( overwriteDatePickerWithEventDate ) {
        overwriteDate.current = overwriteDatePickerWithEventDate;
        setNavigatedDate(overwriteDatePickerWithEventDate)
      }

    }, [overwriteDatePickerWithEventDate])

    useEffect(() => {
      if (fromElevatorMaintenanceChart) {
        createZoomedInStateFromMaintenanceChart(fromElevatorMaintenanceChart);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromElevatorMaintenanceChart ]);

    const createZoomedInStateFromMaintenanceChart = (
      data: IMaintenanceDataForHistoryDetails
    ) => {
      // handle all the functions needed when clicking on the oversight char tooltip.
      //  https://trello.com/c/jUBCMJkO
      //
      let resultsInMilliseconds = +moment(data.navData.created_at).startOf(
        "hour"
      );

      if (overwriteDatePickerWithEventDate) {
        const startOfEventHour = +moment(overwriteDate.current).startOf("hour");
        resultsInMilliseconds = startOfEventHour - 120000;

          setDateRangeForSwimlanes({
            from: startOfEventHour,
            to: startOfEventHour + 3600000,
          })
        } else {
          setDateRangeForSwimlanes({
            from: resultsInMilliseconds,
            to: resultsInMilliseconds + 3600000,
          })
        }

      setDateRangePicker({
        from: resultsInMilliseconds,
        to: resultsInMilliseconds + 3600000,
      });
    };

    const changeTime = (timeInMili: number) => {
      // over complicated check to restore normal time when using
      // the time picker after navigating to an event
      //
      const m = moment(timeInMili);
      const roundHour = m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
      const fromTime = roundHour.isAfter(m) ? timeInMili + 120000 : moment(timeInMili).startOf('hour').valueOf();

      const time = {
        from: fromTime,
        to: fromTime + 3600000, // add one hour in milliseconds
      }

      setDateRangeForSwimlanes(time)
      setDateRangePicker(time);
      setNavigatedDate(null);
    }

    return (
      <React.Fragment>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className={classes.liftDetailsHistory}>
            <React.Fragment>
              {/* This makes sure we can use our date utility of choice in combination with keyboardDataPicker */}
              <div className={classes.datePicker}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={LocalizedMomentUtils}
                >
                  {/* Responsible for selecing a day (only through the button, not the text input) */}
                  <DatePicker
                    disableToolbar
                    margin="normal"
                    id="date-picker-dialog"
                    required={true}
                    inputProps={{ readOnly: true }}
                    label={intl.formatMessage({ id: "date" })}
                    format="DD-MM-yyyy"
                    disableFuture={true}
                    value={dateRangePicker.from}
                    onChange={(selectedDate) => {
                      const resultsInMilliseconds =
                        +selectedDate.startOf("hour");

                        changeTime(resultsInMilliseconds);
                    }}
                  />

                  {/* Responsible for selecting the hour of day */}
                  <FormControl className={classes.timeSelector}>
                    <InputLabel id="pick-history-date-period">
                      {dateRangePicker.to === defaultMiliSeconds ? (
                        moment(dateRangePicker.from)
                          .format("HH:hh")
                          .substr(0, 2) +
                        ":00" +
                        " - " +
                        moment(dateRangePicker.to).format("HH:hh").substr(0, 2)
                      ) : (
                        <FormattedMessage id="periods" />
                      )}
                    </InputLabel>

                    <Select
                      value={defaultValueForPeriodTime}
                      labelId="pick-history-date-period"
                      onChange={(event) => {
                        const result = +new Date(dateRangePicker.from).setHours(
                          event.target.value as number
                        );

                        changeTime(result);
                      }}
                    >
                      {[
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        16, 17, 18, 19, 20, 21, 22, 23,
                      ].map((_value, idx) => {
                        return (
                          <MenuItem key={idx} value={idx}>
                            {("00" + idx).slice(-2)}:00 -{" "}
                            {("00" + (idx += 1)).slice(-2)}:00
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </MuiPickersUtilsProvider>
              </div>

              <SenseModuleHistoryProvider esNumber={esNumber}>
                <ElevatorHistorySwimlanes
                  width={width}
                  dateRangeProps={dateRangeForSwimlanes}
                  periodEndDate={dateRangeForSwimlanes.to}
                  eventStartDate={navigatedDate}
                />
              </SenseModuleHistoryProvider>

              <StatusCodeProvider>
                <ElevatorHistoryDetails
                  from={dateRangePicker.from}
                  to={dateRangePicker.to}
                />
              </StatusCodeProvider>
            </React.Fragment>
          </div>
        )}
      </React.Fragment>
    );
  }
);

export default LiftDetailsHistory;
