import React, { createContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { LiftStatusBaseApi } from 'Api/Generic';

interface IAttachmentsProviderProps {
  children: any;
}

export const AttachmentsContext = createContext(null);

const AttachmentsProvider = ({ children }: IAttachmentsProviderProps) => {
  const alert = useAlert();
  const { liftId } = useParams();
  const [liftIdQuery, setLiftIdQuery] = useState({ liftId });

  const fetchAttachments = async (_key, liftIdQuery) => {

    const response = await LiftStatusBaseApi.get({
      uri: `/liftstatus/attachment`,
      urlParameters: {
        limit: 1000,
        liftId
      }
    });

    const data = await response?.data;

    return data;
  };

  const { status, data, error } = useQuery(
    ["lift-attachments", liftIdQuery],
    fetchAttachments,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const QueryAttachments = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error(error);
      alert.show(`Error: ${error.message}`, { type: "error" });
      return status;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  // Download
  //
  const downloadAttachment = async (attachmentId: number) => {
    /**
     * There must be a id to do this
     */
    if (!attachmentId) {
      return;
    }

    const response = await LiftStatusBaseApi.get({
      uri: `/liftstatus/attachment/${attachmentId}`,
      responseType: 'arraybuffer',
    });

    const data = response?.data;

    // to be able to play the audio in the html5 audio tag we need to make it a blob and create an url
    // this url can then be set as src on the audio tag
    //
    const blob = new Blob([data], { type: response.headers['content-type'] });
    const url = window.URL.createObjectURL(blob);

    return url;
  }

  return (
    <AttachmentsContext.Provider
      value={{
        response: QueryAttachments(),
        fetchAttachments: setLiftIdQuery,
        downloadAttachment
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

export default AttachmentsProvider;
