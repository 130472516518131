import { LiftStatusBaseApi } from "Api/Generic";
import { socketClient } from "Api/Socket";
import ActiveAlertCard from "Components/ActiveAlertCard/ActiveAlertCard";
import SideMenu from "Components/SideMenu/SideMenu";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from "./CurrentCalls.module.scss";

export interface AlarmNotifyDto {
  uniqueid: string;
  instance_key: string;
  src: string;
  lift_id: number;
  identified: number;
  status: string;
  queued_at: string;
  queued_duration: number;
  answered_by: string;
  answered_by_description: string;
  answered_at: string;
  answered_duration: number;
  accesspoint_id: string;
}

export interface IExtendedAlarmData {
  uniqueid: string;
  instance_key: string;
  src: string;
  lift_id: number;
  identified: number;
  status: string;
  queued_at: string;
  queued_duration: number;
  answered_by: string;
  answered_by_description: string;
  answered_at: string;
  answered_duration: number;
  accesspoint_id: string;
  lift_data: LiftStatus.Schemas.ILift;
}

export interface IEmitAlarmPayload {
  type: "changed" | "deleted";
  data: AlarmNotifyDto;
}
interface IExtendedActiveAlarm {
  type: "changed" | "deleted";
  data: IExtendedAlarmData;
}

const InnerCurrentCalls = () => {
  const [currentActiveCalls, setCurrentActiveCalls] = useState([]);
  const [extendedCurrentActiveCalls, setExtendedCurrentActiveCalls] = useState<
    IExtendedActiveAlarm[]
  >([]);

  const [currentAlarms, setCurrentAlarms] = useState([]);
  const [extendedCurrentAlarms, setExtendedCurrentAlarms] = useState([]);

  // Get the connected socket back to use here.
  //
  const connectSocket = React.useCallback(async () => {
    try {
      const connectedSocket = await socketClient.connectToNextSocket();
      return connectedSocket as SocketIOClient.Socket;
    } catch (e) {
      console.error("socket connection error", e);
      return e;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to subscribe to active alarms
  const subscribeToAlarms = async () => {
    const socket = await connectSocket();

    socket.on("alarms", (data: AlarmNotifyDto[]) => {

      setCurrentAlarms(data);
    });

    socket.on("alarm", (alarm) => {
      // Since we got an emition from an active call we can remove it from the current calls
      //
      const removeAlertFromCurrentAlarms = extendedCurrentAlarms.filter(
        (call: IExtendedAlarmData) => call.lift_id !== alarm.lift_id
      );

      setExtendedCurrentAlarms(removeAlertFromCurrentAlarms);

      setCurrentActiveCalls([...currentActiveCalls, alarm]);
    });
  };

  // Takes the current alarms and extends them with the attatched lift data
  //
  async function fetchExtendedCurrentAlarms() {
    const data: IExtendedAlarmData[] = [];

    currentAlarms?.forEach(async (alarm: AlarmNotifyDto) => {
      if (alarm.status === "ended") {
        setTimeout(() => {
          const filterOutDeletedCall = extendedCurrentAlarms?.filter(
            (activeCall) => activeCall.lift_id !== alarm.lift_id
          );

          setExtendedCurrentAlarms(filterOutDeletedCall);
        }, 30000);
      }

      LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${alarm.lift_id}`,
      })
        .then((response) => {
          const liftData: LiftStatus.Schemas.ILift = response?.data;

          data.push({ ...alarm, lift_data: liftData } as IExtendedAlarmData);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setExtendedCurrentAlarms(data);
        });
    });
  }

  // This will fetch the liftData for the current alarm
  // it will take the lift data and extend the
  // incoming alarm object with the liftData so we can
  // extract more information to show
  //
  const fetchExtendedActiveAlarms = () => {
    const dataArray: IExtendedActiveAlarm[] = [];

    currentActiveCalls?.forEach(async (alarm: IEmitAlarmPayload) => {
      if (alarm.type === "deleted") {
        setTimeout(() => {
          const filterOutDeletedCall = extendedCurrentActiveCalls?.filter(
            (activeCall) => activeCall.data.lift_id !== alarm.data.lift_id
          );

          setExtendedCurrentActiveCalls(filterOutDeletedCall);
        }, 3000);
      }

      // Should be made into a provider
      //
      LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${alarm?.data?.lift_id}`,
      })
        .then((response) => {
          const liftData: LiftStatus.Schemas.ILift = response?.data;

          const incDataObj: IExtendedActiveAlarm = {
            type: alarm.type,
            data: {
              ...alarm.data,
              lift_data: liftData,
            } as IExtendedAlarmData,
          };

          dataArray.push(incDataObj);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          // We want to remove the alarm if it's already in the active alarms state
          // so we dont get multiple instances of a call with different statuses
          // rather, we want to just replace the alert because it's status has changed.
          // but at the same time we want to show multiple alerts with multiple
          // statuses
          //
          const currentSet = new Set(dataArray.map(({ data }) => data.lift_id));
          const combined = [
            ...dataArray,
            ...extendedCurrentActiveCalls.filter(
              ({ data }) => !currentSet.has(data.lift_id)
            ),
          ];

          setExtendedCurrentActiveCalls(combined);
        });
    });
  };

  // This is our subscriber effect
  //
  useEffect(() => {
    subscribeToAlarms();
    // Unlisten to sockets
    // this return in a useEffect hook is the same
    // as onDestroy.
    //
    return () => {
      killSockets();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Kill the connected sockets
  //
  const killSockets = async () => {
    const socket = await connectSocket();

    socket.disconnect();
  };

  // This will re-trigger if currentAlarms changes
  // currentAlarms should never re-trigger unless requested to by emitting from us
  //
  useEffect(() => {
    fetchExtendedCurrentAlarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAlarms]);

  // This will re-trigger if currentActiveCalls changes
  // this change happens every emition of a new alarm
  //
  useEffect(() => {
    fetchExtendedActiveAlarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveCalls]);

  return (
    <div className={classes.root}>
      <SideMenu />

      <div className={classes.content}>
        <h2 className={classes.title}>
          <FormattedMessage id="realtime.alerts" />
        </h2>

        {extendedCurrentAlarms?.length > 0 ? (
          <React.Fragment>
            {extendedCurrentAlarms?.map((currentAlarm: IExtendedAlarmData) => (
              <ActiveAlertCard
                alarm={currentAlarm}
                key={currentAlarm.lift_id}
              />
            ))}
          </React.Fragment>
        ) : (
          <></>
        )}

        {extendedCurrentActiveCalls?.length > 0 ? (
          <React.Fragment>
            {extendedCurrentActiveCalls?.map(
              (activeAlarm: IExtendedActiveAlarm) => (
                <ActiveAlertCard
                  alarm={activeAlarm?.data}
                  key={activeAlarm?.data?.lift_id}
                />
              )
            )}
          </React.Fragment>
        ) : (
          <></>
        )}

        {extendedCurrentActiveCalls?.length === 0 &&
        extendedCurrentAlarms?.length === 0 ? (
          <React.Fragment>
            <h2 className={classes.noAlerts}>
              <FormattedMessage id="no.alerts" /> <br />
              <FormattedMessage id="alerts.shown.here" />
            </h2>
          </React.Fragment>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const CurrentCalls = () => {
  return <InnerCurrentCalls />;
};
export default CurrentCalls;
