/* eslint-disable react/prop-types */
import { CircularProgress } from "@material-ui/core";
import SideMenu from "Components/SideMenu/SideMenu";
// eslint-disable-next-line no-unused-vars
import StatsDetailsProvider, {
  StatsDetailsContext,
  IStatsDetailsRequest,
} from "Providers/StatsDetailsProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import LiftIcon from "../../Assets/LiftIcon";
import classes from "./StatsDetails.module.scss";
import { LiftStatusStorage } from "Utils/Storage";

export type IStatsParams = {
  type?: 'liftAvailability' | 'intercomAvailability' | 'servicecounterMaintenanceAlert' | 'liftUnavailable' | 'intercomUnavailable' | 'maintenanceOnInterval' | 'maintenanceBeforeInterval' | 'maintenanceAfterInterval' | 'approvalRejected' | 'approvalExpired' | 'visits' | 'maintenanceExpired' | 'senseFailureSensor' | 'senseNotConnected';
  upper?: string;
  lower?: string;
  hideSideMenu?: boolean;
}

const StatsDetailsInternal: React.FC<IStatsParams> = ({ type, upper, lower, hideSideMenu }) => {
  const statsDetailsProvider = useContext(StatsDetailsContext);
  const history = useHistory();
  const intl = useIntl();
  const queryParams = useParams<IStatsParams>({});
  const [statsParams, setStatsParams] = useState<IStatsParams>();
  const [bracketDescription, setBracketDescription] = useState(null);
  const [statsType, setStatsType] = useState(null);
  const [response, setResponse] = useState<LiftStatus.Schemas.IStatsEventDto[]>();

  useEffect(() => {
    let description = "";
    let unit = "";

    if (statsParams) {
      const { type, upper, lower } = statsParams;
      const lowerInt = parseFloat(lower);
      const upperInt = parseFloat(upper);

      switch (type) {
        case "liftAvailability":
        case "intercomAvailability":
        case "maintenanceOnInterval":
        case "maintenanceBeforeInterval":
        case "maintenanceAfterInterval":
          unit = "%";
          setStatsType("percentage");
          break;

        case "servicecounterMaintenanceAlert":
        case "liftUnavailable":
        case "intercomUnavailable":
        case "approvalRejected":
        case "approvalExpired":
        case "maintenanceExpired":
        case "visits":
        case "senseFailureSensor":
        case "senseNotConnected":
          unit = "dagen";
          setStatsType("days");
          break;

        default:
          setStatsType(null);
      }

      if (lower === "null") {
        description += "<";
      }

      description += lower && lower !== "null" ? `${unit === '%' ? lowerInt * 100 : lower} ${unit}` : "";

      if (description && upper) {
        description += " - ";
      }

      if (upper === "null") {
        description += ">";
      }

      description += upper && upper !== "null" ? `${unit === '%' ? upperInt * 100 : upper} ${unit}` : "";

      setBracketDescription(description);
    }

  }, [statsParams]);

  // Either receive the stats from the URL of as component props
  //
  useEffect(() => {
    if (type) {
      setStatsParams({ type, upper, lower});
    } else if (queryParams?.type) {
      setStatsParams({
        type: queryParams.type,
        upper: queryParams.upper,
        lower: queryParams.lower,
      });
    }
  }, [type, upper, lower, queryParams, queryParams.type, queryParams.upper, queryParams.lower]);


  const handleLiftClick = (liftID: number) => {
    if (!liftID) {
      console.error("[STATSDETAILS] No liftID!");
      return;
    }

    history.push(`/lifts/${liftID}`);
  };

  const handleChange = useCallback(async () => {
    if (statsParams) {
      const request = {
        organisationId: LiftStatusStorage.organisationId,
        type: statsParams?.type,
      } as IStatsDetailsRequest;

      if (statsParams?.lower && statsParams.lower !== "null") {
        request.lower = statsParams.lower;
      }
      if (statsParams?.upper && statsParams.upper !== "null") {
        request.upper = statsParams.upper;
      }

      const payload = await statsDetailsProvider.fetchStatsDetails(request);
      setResponse(payload);
    }
  }, [statsDetailsProvider, statsParams]);

  /**
   * On page load fetch the initial list of alarm calls
   */
  useMemo(() => {
    if (statsParams) {
      handleChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsParams]);

  // eslint-disable-next-line react/prop-types
  const Item: React.FC<{ data: LiftStatus.Schemas.IStatsEventDto}> = ({ data }) => {

    const title = [];
    let statsTypeValueText = "";

    data?.lift?.details?.city && title.push(data?.lift.details.city);
    data?.lift?.details?.country &&
      data?.lift.details.country !== "Nederland" &&
      title.push(data?.lift.details.country);
    data?.lift?.details?.address && title.push(data?.lift.details.address);
    data?.lift?.details?.buildingname &&
      title.push(data?.lift.details.buildingname);
    data?.lift?.details?.liftname && title.push(data?.lift.details.liftname);

    switch (statsType) {
      case "percentage":
        // 2 decimals max
        statsTypeValueText = `${(Math.round(data?.value * 10000) / 100).toLocaleString()}%`;
        break;

      case "days":
        statsTypeValueText = `${Math.round(data?.value * 10) / 10} dagen`;
        break;

      default:
        statsTypeValueText = `${data?.value}`;
    }

    const subtitle = [];
    data?.lift?.details?.liftnr &&
      subtitle.push(
        `${intl.formatMessage({ id: "lift.nr" })}: ${data?.lift.details.liftnr}`
      );
    data?.lift?.details?.manufacturernr &&
      subtitle.push(
        `${intl.formatMessage({ id: "manufacturer.nr" })}: ${
          data?.lift.details.manufacturernr
        }`
      );
    data?.lift?.details?.ownernr &&
      subtitle.push(
        `${intl.formatMessage({ id: "owner.nr" })}: ${
          data?.lift.details.ownernr
        }`
      );
    data?.lift?.details?.advicernr &&
      subtitle.push(
        `${intl.formatMessage({ id: "advicer.nr" })}: ${
          data?.lift.details.advicernr
        }`
      );
    data?.lift?.details?.auditnr &&
      subtitle.push(
        `${intl.formatMessage({ id: "audit.nr" })}: ${
          data?.lift.details.auditnr
        }`
      );

    return (
      <div
        key={data?.lift_id}
        className={classes.card}
        onClick={() => handleLiftClick(data?.lift_id)}
      >
        <div className={classes.cardContainer}>
          <div className={classes.lifticon}>
            <LiftIcon />
          </div>

          <span className={classes.cardTitle}>
            <h4>{title.join(", ")}</h4>
            <h4 className={classes.statsTypeValueText}>{statsTypeValueText}</h4>
          </span>
          <p>{subtitle.join(", ")}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {!hideSideMenu && (
        <SideMenu
          onChangeOrganisation={() => {
            handleChange();
          }}
        />
      )}
      <div className={classes.content}>
        <div className={classes.liftsList}>
          {/** Title */}
          <span className={classes.title}>
            <h4>
              {intl.formatMessage({ id: `stats.${statsParams?.type}` })}{" "}
              {bracketDescription ? ` - ${bracketDescription}` : ""}
            </h4>
            <h4 className={classes.green}>
              {statsDetailsProvider.results !== "loading" &&
                statsDetailsProvider.results !== "error" && (
                  <>
                    <p><FormattedMessage id="total.lifts" />:</p> <span><b>{response?.length ?? "?"}</b></span>
                  </>
                )}
            </h4>
          </span>

          {statsDetailsProvider.results === "loading" ? (
            <div className={classes.progressIndicator}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className={classes.liftResults} style={{ gridColumn: "1/5" }}>
              {response?.map((item, idx) => (<Item data={item} key={idx} />))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StatsDetails: React.FC<IStatsParams> = (props) => (
  <StatsDetailsProvider>
    <StatsDetailsInternal {...props} />
  </StatsDetailsProvider>
);

export default StatsDetails;
