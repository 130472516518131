import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import cx from "classnames";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./Periods.module.scss";
import { FormattedMessage } from "react-intl";
import { GlobalStateContext } from "Providers/GlobalStateProvider";
// eslint-disable-next-line no-unused-vars
import PeriodSelector, {
  IDateRange,
} from "Components/PeriodSelector/PeriodSelector";

interface IPeriodsProps {
  hidePeriods?: boolean;
  hideNavigation?: boolean;
  onlyUnits?: boolean;
  onDateChange: (dateRange: IDateRange) => void;
}

const Periods = (props: IPeriodsProps) => {
  const [pageOpen, setPageOpen] = useState(false);
  const [, globalStateDispatch] = useContext(GlobalStateContext);

  const history = useHistory();
  const location = useLocation();

  const handlePeriodsCompareClick = () => {
    history.push("/compare-periods");
    setPageOpen(false);
  };

  const navigateBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setPageOpen(false);
    if (location?.pathname === "/compare-periods") {
      setPageOpen(true);
    } else {
      setPageOpen(false);
    }
  }, [pageOpen, location, globalStateDispatch]);

  return (
    <div className={classes.root}>
      <div
        className={cx(
          classes.periods,
          props.hidePeriods ? classes.reverse : null
        )}
      >
        {props.hidePeriods ? null : (
          <React.Fragment>
            {props.hideNavigation ? (
              <span></span>
            ) : (
              <>
                {pageOpen ? (
                  <Button variant="outlined" onClick={() => navigateBack()}>
                    <React.Fragment>
                      <ChevronLeftIcon className={classes.icon} />
                      <span>
                        <FormattedMessage id="back.to.dashboard" />
                      </span>
                    </React.Fragment>
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => handlePeriodsCompareClick()}
                    className={classes.periodsBtn}
                  >
                    <React.Fragment>
                      <ShowChartIcon className={classes.icon} />
                      <span>
                        <FormattedMessage id="compare.periods" />
                      </span>
                    </React.Fragment>
                  </Button>
                )}
              </>
            )}

            <div className={classes.rangeColumn}>
              <PeriodSelector
                onlyUnits={props.onlyUnits}
                onDateChange={(dateRange) => {
                  if (props.onDateChange) {
                    props.onDateChange(dateRange);
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Periods;
