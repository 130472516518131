import { CircularProgress } from "@material-ui/core";
import * as d3 from "d3";
import {
  cloneDeep,
  filter,
  first,
  get,
  isObject,
  keys,
  last,
  sortBy,
  values,
} from "lodash";
import moment from "moment";
import { useSenseModule } from "Providers/SenseModuleProvider";
import React, { useEffect, useState } from "react";
import classes from "./StopsGraph.module.scss";

interface ITimePoint {
  name: string;
  x: Date;
  y: number;
  id: string;
  sensor: number;
  fake: boolean;
}

// ids used
const chartId = "chartRecentMovement";
const focusLineXId = "focusLineX";
const focusLineYId = "focusLineY";
const focusCircleId = "focusCircle";
const focusTextContainerId = "focusTextContainer";
const focusTextId = "focusText";

const minHeight = 200;
const margins = { top: 20, right: 20, bottom: 20, left: 20 };
const sensorIds = [501, 514, 515];
const momentFormat = "DD-MM-YYYY HH:mm:ss";
let currentXScale: d3.ScaleTime<number, number> = undefined;
let currentYScale: d3.ScaleLinear<number, number> = undefined;
let xAxis: d3.Axis<number | Date | { valueOf(): number }> = undefined;
let yAxis: d3.Axis<number | Date | { valueOf(): number }> = undefined;
let graph: d3.Selection<SVGGElement, unknown, HTMLElement, any> = undefined;
let timepoints: ITimePoint[] = [];
let height: number = 200;
let maxFloors = 0;

const StopsGraph: React.FC<{ width: number }> = ({
  width,
}: {
  width: number;
}) => {
  const chartRef = React.useRef<HTMLDivElement>();
  const maxWidth = width <= 0 ? 600 : width;
  const useSenseModuleProvider = useSenseModule();
  const [updateTimer, setUpdateTimer] = useState<NodeJS.Timeout>();
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  const handleSensorUpdate = (data: any, timestamp: any) => {
    if (data) {
      const key = parseInt(first(keys(data)), 10);
      const value = parseInt(first(values(data)), 10);

      if ( key === 591 ) {
        maxFloors = (value || 0 ) + 1;
      }

      // key 501 is current (open doors)
      // key 515 is current (regardless of door state)
      // key 514 is last passed
      // These are the only statuses we want to show
      //
      if (!Number.isNaN(value) && sensorIds.includes(key)) {
        const momentTimestamp = moment(timestamp);
        const newTimePoint: ITimePoint = {
          name: momentTimestamp.format(momentFormat),
          x: momentTimestamp.toDate(),
          y: value + 1,
          id: `${key}-${timestamp}`,
          sensor: key,
          fake: false,
        };

        const updatedTimePoints = [...timepoints, newTimePoint];
        timepoints = updatedTimePoints;
      }
    }
  };

  const clearChart = () => {
    // clear the timer so that if we are gonna redraw we don't set intermediate updates that can ruin everything
    //
    clearInterval(updateTimer);
    // clear out the html of the chart so that we have a clear chart
    //
    d3.select(`#${chartId}`).html("");
  };

  const drawChart = async (startTime: moment.Moment) => {
    // console.log('[RECENT-MOVEMENTS] draw chart', startTime);

    // lets first remove the current chart before we draw a new one to prevent overlapping
    //
    clearChart();

    setChartLoading(true);

    currentXScale = d3.scaleTime().range([0, width]);
    currentYScale = d3.scaleLinear().range([height, 0]);

    xAxis = d3
      .axisBottom(currentXScale)
      .ticks(d3.timeMinute.every(5))
      .tickFormat(d3.timeFormat("%H:%M"));
    yAxis = d3.axisLeft(currentYScale).tickFormat(d3.format("d"));

    const svg = d3
      .select(`#${chartId}`)
      .append("svg")
      .attr("width", maxWidth)
      .attr("height", minHeight)
      .attr("class", classes.loading)
      .attr("viewbox", `0 0 ${maxWidth} ${minHeight}`)
      .append("g")
      .attr("transform", `translate(${margins.left},${margins.top})`);

    // add the x axis
    //
    svg
      .append("g")
      .attr("class", `${classes.axis} x_axis`)
      .attr("transform", `translate(0,${height})`)
      .call(xAxis);

    // add the y axis
    //
    svg.append("g").attr("class", `${classes.axis} y_axis`).call(yAxis);

    const historicalPointsResponse =
      await useSenseModuleProvider.fetchModuleSensorData({
        from: startTime,
        to: moment(),
        sensors: sensorIds,
      });
    if (historicalPointsResponse) {
      const dataSets: any[] = historicalPointsResponse
        ? historicalPointsResponse
        : [];

      // create timePoints
      const historicalTimePoints = [];

      for (const dataSet of dataSets) {
        const sensorId = get(dataSet, "sensor.id");

        for (const row of dataSet.rows) {
          const value = parseInt(get(row, "val"), 10);

          if (!Number.isNaN(value)) {
            const newTimePoint: ITimePoint = {
              name: moment(row.ts).format(momentFormat),
              x: moment(row.ts).toDate(),
              y: value + 1,
              id: `${sensorId}-${row.ts}`,
              sensor: sensorId,
              fake: false,
            };

            historicalTimePoints.push(newTimePoint);
          }
        }
      }

      // merge these time points with the ones we already have
      //
      const mergedTimePoints: ITimePoint[] = [
        ...historicalTimePoints,
        ...timepoints,
      ];

      // sort the time points on date
      //
      mergedTimePoints.sort((timePointA, timePointB) =>
        moment(timePointA.x).isBefore(moment(timePointB)) ? -1 : 1
      );

      // set the merged time points
      timepoints = mergedTimePoints;
    }

    // create a graph element
    graph = svg.append("g");

    // create a element for onFocus
    const focus = svg.append("g").style("display", "none");

    // append the x axis line to the onFocus element
    //
    focus
      .append("line")
      .attr("id", focusLineXId)
      .attr("class", classes.focusLine);

    // append the y axis line to the onFocus element
    //
    focus
      .append("line")
      .attr("id", focusLineYId)
      .attr("class", classes.focusLine);

    // append a circle to the onFocus element with a radius of 5px
    focus
      .append("circle")
      .attr("id", focusCircleId)
      .attr("r", 5)
      .attr("class", `${classes.circle} ${classes.focusCircle}`);

    // add a text container to the onFocus element
    //
    const textContainer = focus
      .append("g")
      .attr("id", focusTextContainerId)
      .attr("class", classes.focus_text_container);

    // create the outer box where the text will appear in
    //
    textContainer
      .append("rect")
      .attr("width", 240)
      .attr("height", 20)
      .attr("x", -120)
      .attr("y", -20)
      .attr("rx", 5)
      .attr("ry", 5);

    // create the inner box where the text will appear in
    //
    textContainer
      .append("text")
      .attr("y", -10)
      .attr("id", focusTextId)
      .attr("text-anchor", "middle")
      .attr("dy", ".35em");

    // use the return value here as we need it later for the overlay
    //
    const [floorExtend, datesExtend] = updateDataRange();

    // create a bisector object to find the current data item on move move on the overlay
    const bisectDate = d3.bisector((timepoint: ITimePoint) => timepoint.x).left;

    // create an overlay which will show the focus items on the correct point
    //
    svg
      .append("rect")
      .attr("class", classes.overlay)
      .attr("width", maxWidth)
      .attr("height", minHeight)
      .on("mouseover", () => focus.style("display", null))
      .on("mouseout", () => focus.style("display", "none"))
      .on("mousemove", function () {
        // do not make this an arrow function as it relies on this being set to the move scope
        const mouse = d3.mouse(this);
        const mouseDate = currentXScale.invert(mouse[0]);
        // we need to sort the timepoints as they might not be yet. and after that find the index in the sorted timepoints
        const clonedTimePoints: ITimePoint[] = cloneDeep(timepoints);
        clonedTimePoints.sort((a, b) => a.x.valueOf() - b.x.valueOf());
        const i = bisectDate(clonedTimePoints, mouseDate); // returns the index to the current data item

        // get the current data item and the one before that so we can see which one is closes to the current mouse position
        //
        const timepoint0 = clonedTimePoints[i - 1];
        const timepoint1 = clonedTimePoints[i];

        if (isObject(timepoint0) && isObject(timepoint1)) {
          // work out which date value is closest to the mouse
          //
          const closestTimePoint =
            mouseDate.getTime() - timepoint0.x.getTime() >
            timepoint1.x.getTime() - mouseDate.getTime()
              ? timepoint1
              : timepoint0;

          // get the x and y values of that closest point
          //
          const x = currentXScale(closestTimePoint.x);
          const y = currentYScale(closestTimePoint.y);

          focus.select(`#${focusCircleId}`).attr("cx", x).attr("cy", y);
          focus
            .select(`#${focusLineXId}`)
            .attr("x1", x)
            .attr("y1", currentYScale(floorExtend[0]))
            .attr("x2", x)
            .attr("y2", currentYScale(floorExtend[1]));
          focus
            .select(`#${focusLineYId}`)
            .attr("x1", currentXScale(datesExtend[0]))
            .attr("y1", y)
            .attr("x2", currentXScale(datesExtend[1]))
            .attr("y2", y);
          focus
            .select(`#${focusTextContainerId}`)
            .attr("transform", `translate(${x},${y})`)
            .select(`#${focusTextId}`)
            .text(closestTimePoint.name);
        }
      });

    setChartLoading(false);
    d3?.selectAll(`.${classes.loading}`).classed(`${classes.loading}`, false);
  };

  const updateDataRange = () => {
    const now = moment();
    const start = getSelectedStartPeriod();
    // set last updated to the current moment
    // filter out time points older then the start selected, filter out fake values, and any points too close to 501 points
    //
    const filteredTimePoints = filter(timepoints, (timePoint: ITimePoint) => {
      const isAfterStart = moment(timePoint.x).isAfter(start);
      const isNotFake = !timePoint.fake;

      return isAfterStart && isNotFake;
    });

    // clone the last point
    //
    const clonedTimePoints: ITimePoint[] = cloneDeep(filteredTimePoints);
    clonedTimePoints.sort((a, b) => a.x.valueOf() - b.x.valueOf());
    const fakeValue = cloneDeep(last(clonedTimePoints));

    // add a fake point if we were successfull on cloning the last point
    //
    if (fakeValue) {
      fakeValue.x = now.toDate();
      fakeValue.name = now.format(momentFormat);
      fakeValue.id = `${fakeValue.y}-${now.toDate()}`;
      fakeValue.fake = true;

      const timePointsWithFakevalue = [...filteredTimePoints, fakeValue];

      timepoints = timePointsWithFakevalue;
    }

    const floorExtent = [ 1, maxFloors];

    const datesExtend = d3.extent(timepoints, (timepoint) => timepoint.x);
    datesExtend[1] = now.toDate();

    currentXScale.domain([start.valueOf(), now.valueOf()]);
    currentYScale.domain(floorExtent);

    // Remove previous lines
    //
    if (graph) {
      graph?.selectAll(`.${classes.line}`).remove();

      for (let i = 1; i <= maxFloors; i++) {
        graph
          ?.append("line")
          .attr("y1", currentYScale(i))
          .attr("y2", currentYScale(i))
          .attr("x1", 0)
          .attr("x2", width)
          .attr("class", classes.line);
      }

      const yTicks = d3.max(timepoints, (timepoint) => timepoint.y) - 1;
      yAxis.ticks(yTicks);

      // Remove all previous paths
      //
      graph?.selectAll(`.${classes.path}`).remove();

      graph
        .append("path")
        .datum(
          sortBy(timepoints, (t) => t.x.getTime()).map((timepoint) => [
            timepoint.x.getTime(),
            timepoint.y,
          ])
        )
        .attr("fill", "none")
        .attr("class", classes.path)
        .attr(
          "d",
          d3
            .line()
            .x((d) => currentXScale(d[0]))
            .y((d) => currentYScale(d[1]))
        );

      // Remove previous circles
      //
      graph.selectAll(`.point`).remove();

      // append new circles for the new timepoints
      //
      graph
        .selectAll("circle")
        .data(sortBy(timepoints, "sensor").reverse() as ITimePoint[])
        .enter()
        .append("circle")
        .attr("cx", (timepoint) => currentXScale(timepoint.x))
        .attr("cy", (timepoint) => currentYScale(timepoint.y))
        .attr("r", 5)
        .attr("class", (timepoint) => {
          let result = classes.stop;

          const sensorType = parseInt(`${timepoint.sensor}`, 10);

          if (sensorType === 514 || sensorType === 515) {
            result = classes.passed;
          }

          if (timepoint.fake) {
            result = classes.fake;
          }

          return `${result} point`;
        });

      // update the axis
      //
      d3.select(".x_axis").call(xAxis);
      d3.select(".y_axis").call(yAxis);

      return [floorExtent, datesExtend];
    }
  };

  const getSelectedStartPeriod = () => {
    // Default time
    //
    const startTime = moment().subtract(1, "hour");

    return startTime;
  };


  useEffect(() => {
    const max = get(useSenseModuleProvider.senseModuleSensorData, "values.591") || 0;

    maxFloors = max + 1;
  }, [
    useSenseModuleProvider.senseModuleSensorData,
    useSenseModuleProvider.socketConnected
  ]);

  // when ever the socket emits data handle the sensor data update
  useEffect(() => {
    handleSensorUpdate(
      get(useSenseModuleProvider.movementPoints, "message.data"),
      get(useSenseModuleProvider.movementPoints, "message.ts")
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSenseModuleProvider.movementPoints]);

  // on init
  //
  useEffect(() => {
    if (useSenseModuleProvider.socketConnected) {
      // need to check if this is causing width to not work correctly
      const tempWidth = maxWidth - margins.left;
      if (tempWidth <= 0) {
        width = 600;
      } else {
        width = tempWidth;
      }
      height = minHeight - margins.top - margins.bottom;
      drawChart(getSelectedStartPeriod());

      // create an interval to update the chart and see it moving forward in time
      //
      const updateTimer = setInterval(() => {
        updateDataRange();
      }, 3000);

      // set the interval to the state item so that we can cancel it on destroy
      //
      setUpdateTimer(updateTimer);
    }

    // on destroy clear chart;
    return () => {
      clearChart();
      timepoints = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSenseModuleProvider.socketConnected]);

  return (
    <div ref={chartRef}>
      <div
        id={chartId}
        className={`${classes.chart}`}
        style={{ width: width }}
      ></div>
      {chartLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default StopsGraph;
