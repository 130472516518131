import { CircularProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { IDateRange } from "Components/PeriodSelector/PeriodSelector";
import { isEmpty, isNil } from "lodash";
import {
  EventSearchDataContext,
  IEventDataRequest
} from "Providers/EventDataProvider";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { paginationPageSize } from "Utils/constants";
import EventDetailsCard from "../EventDetailsCard/EventDetailsCard";
import classes from "./Events.module.scss";

interface EventsProps {
  liftId?: number | string;
  eventKey?: string[];
  openOnly?: boolean;

  pagination?: {
    limit: number;
    offset: number;
  };

  organisationId?: number;

  dateRange?: IDateRange;
}

const EventsInternal = (props: EventsProps) => {
  const eventSearchDataProvider = useContext(EventSearchDataContext);
  const [events, setEvents] = useState<LiftStatus.Schemas.IEvent[]>(null);
  const intl = useIntl();

  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    liftId,
    eventKey,
    openOnly,
    organisationId,
    dateRange,
    pagination,
  } = props;

  useEffect(() => {
    if (!eventSearchDataProvider || eventSearchDataProvider.response === "loading") {
      return;
    }

    if (!isEmpty(eventSearchDataProvider.response) && eventKey.length) {
      setEvents(eventSearchDataProvider.response.items);
      setPaginationTotal(eventSearchDataProvider.response.total || 0);
    } else {
      setPaginationTotal(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSearchDataProvider, eventKey, organisationId, liftId]);

  useEffect(() => {
    if (!eventSearchDataProvider || loading) {
      return;
    }

    const eventDataRequest: IEventDataRequest = {
      eventKey: eventKey,
    };

    if (liftId) {
      eventDataRequest.liftId =
        typeof liftId === "string" ? parseInt(liftId, 10) : liftId;
    }

    if (pagination) {
      eventDataRequest.pagination = {
        limit: pagination.limit,
        offset: pagination.offset,
      };
    } else {
      eventDataRequest.pagination = {
        limit: paginationPageSize,
        offset: paginationOffset,
      };
    }

    if (typeof openOnly === "boolean") {
      eventDataRequest.openOnly = !!openOnly;
    }

    if (!isNil(organisationId)) {
      eventDataRequest.organisationId = organisationId;
    }

    if (eventKey.length) {
      setLoading(true);
      eventSearchDataProvider.fetchEventSearchData(eventDataRequest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liftId,
    eventKey,
    openOnly,
    organisationId,
    dateRange,
    pagination,
    paginationOffset,
    eventSearchDataProvider.response,

  ]);

  useEffect(() => {
    setLoading(eventSearchDataProvider.response === "loading");
  }, [eventSearchDataProvider.response]);



  return (
    <>
      {loading ? (
        <div className={classes.progressIndicator}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.content}>

          {!eventKey.length ? (<h2>Selecteer een event type</h2>) : (
            (
              <div>
                <div>
                  <h3 style={{marginBottom: 16}}>{intl.formatMessage({ id: "events.title" })}</h3>

                  {!events?.length && (
                    <p>{intl.formatMessage({ id: "events.empty.title" })}</p>
                  )}

                  {eventKey.length && events?.map((event) => {
                    return (
                     <EventDetailsCard key={event.id} event={event} />
                    );
                  })}
                </div>
                < div
                  className={classes.pagination}
                  hidden={!isEmpty(props.pagination)}
                >
                  <Pagination
                    count={paginationTotal}
                    page={paginationOffset + 1}
                    onChange={(_event, newPageNumber) => {
                      setPaginationOffset(newPageNumber - 1);
                    }}
                  />
                </div>
              </div>
            )
          )}
        </div>
      )
      }
    </>
  );
};

const Events = (props: EventsProps) => (
  <EventsInternal {...props} />
);

export default Events;
