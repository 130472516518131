import React, { createContext } from 'react';
import { LiftStatusBaseApi } from 'Api/Generic';

interface IPasswordResetProviderProps {
  children: any;
}

export const PasswordResetContext = createContext(null);

const PasswordResetProvider = ({ children }: IPasswordResetProviderProps) => {
  // Request password reset
  //
  const requestPasswordReset = async (email: string) => {
    /**
     * There must be a email to do this
     */
    if (!email) {
      return;
    }

    const response = await LiftStatusBaseApi.post({
      uri: `/liftstatus/password-reset/request`,
      payload: {
        email,
      }
    });

    const data = response?.data;

    return data;
  }

  // Request password reset
  //
  const setPassword = async (token: string, password: string) => {
    /**
     * There must be a email to do this
     */
    if (!token || !password) {
      return;
    }

    const response = await LiftStatusBaseApi.post({
      uri: `/liftstatus/password-reset`,
      payload: {
        token,
        password,
      }
    });

    if (response.status !== 200 && response.status !== 201) {
      if ( response.response.data?.error ) {
        throw response.response.data.error;
      }

      throw new Error('Error setting password');
    }

    const data = response?.data;

    return data;
  }

  return (
    <PasswordResetContext.Provider
      value={{
        requestPasswordReset,
        setPassword,
      }}
    >
      {children}
    </PasswordResetContext.Provider>
  );
};

export default PasswordResetProvider;
