import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext } from "react";

interface ILiftCounterRunDataProviderProps {
  children: any;
}

interface ILiftRunCounterRequest {
  liftId?: number;
  from?: string;
  to?: string;
}

export const LiftCounterRunDataContext = createContext(null);

const LiftRunCounterProvider = ({
  children,
}: ILiftCounterRunDataProviderProps) => {
  const dispatchLiftCounterDataRequest = async (
    request: ILiftRunCounterRequest
  ) => {
    if (!request.liftId) {
      return;
    }

    const urlParameters: ILiftRunCounterRequest = {};

    if (request.from) {
      urlParameters.from = request.from;
    }

    if (request.to) {
      urlParameters.to = request.to;
    }

    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${request.liftId}/run-counter`,
        urlParameters,
      });

      return response?.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return (
    <LiftCounterRunDataContext.Provider
      value={{
        dispatchLiftCounterRunDataRequest: dispatchLiftCounterDataRequest,
      }}
    >
      {children}
    </LiftCounterRunDataContext.Provider>
  );
};

export function useLiftCounterRunData() {
  const context = React.useContext(LiftCounterRunDataContext);
  if (context === undefined) {
    throw new Error(
      "The useLiftCounterRunData hook must be used within a LiftCounterRunDataContext.Provider"
    );
  }
  return context;
}

export default LiftRunCounterProvider;
