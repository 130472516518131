import React from "react";
import { Line } from "react-chartjs-2";
import { sortBy } from 'lodash';

export interface ComparePeriodChartProps {
  data: {
    count: number;
    from: string;
    interval: string;
    lift_count: number;
    to: string;
  }[];
  counterName?: string;
}

const dateFormat = new Intl.DateTimeFormat('nl-NL', {
  year: 'numeric',
});

const ComparePeriodChart = (props: ComparePeriodChartProps) => {

  const dataset = props?.data ? sortBy(props?.data, 'from') : [];

  const options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 26,
        bottom: 16,
      },
    },
  };

  const data = {
    labels: dataset?.map(item => dateFormat.format(new Date(item.from))),
    datasets: [
      {
        label: false,
        data: dataset?.map(item => item[props.counterName ?? 'count']),
        fill: true,
        backgroundColor: "rgba(0, 0, 0, 0.0)",
        borderColor: "#E76959",
        pointRadius: 6,
        pointBackgroundColor: "#ffffff",
        pointBorderWidth: 2,
        borderJoinStyle: "miter",
      },
    ],

  };

  return <Line data={data} options={options}/>;
};

export default ComparePeriodChart;
