import BigCard from 'Components/BigCard/BigCard';
import SideMenu from 'Components/SideMenu/SideMenu';
import React, { useContext, useState, useEffect } from 'react';
import classes from './ComparePeriods.module.scss';
import Periods from 'Components/Periods/Periods';
import { GlobalStateContext } from 'Providers/GlobalStateProvider';
import { CircularProgress } from '@material-ui/core';
import ComparePeriodChart from './ComparePeriodChart';
import DashboardStatsPeriodProvider, { DashboardStatsPeriodContext } from 'Providers/DashboardStatsPeriodProvider';
import { LiftStatusStorage } from 'Utils/Storage';

const ComparePeriodsInner = () => {
  const dashboardStatsPeriodProvider = useContext(DashboardStatsPeriodContext);
  const [dashboardTileData, setDashboardTileData] = useState(
    null as LiftStatus.Schemas.IStatsOverviewDto
  );
  const [globalState] = useContext(GlobalStateContext);
  const organisationId = globalState.organisationId;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (
      dashboardStatsPeriodProvider?.response === "loading" ||
      dashboardStatsPeriodProvider?.response === "error"
    ) {
      return;
    }

    setDashboardTileData(dashboardStatsPeriodProvider?.response);

    setLoading(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, dashboardStatsPeriodProvider?.response]);
  return (
    <div className={classes.root}>
      <SideMenu
        onChangeOrganisation={(organisation) => {


          dashboardStatsPeriodProvider.fetch( {
            organisationId: organisation.id,
            period: LiftStatusStorage?.period?.unit ?? 'year',
          });
        }}
      />

      <div className={classes.content}>
        <div className={classes.periods}>
          <Periods onlyUnits={true} onDateChange={() => {
              dashboardStatsPeriodProvider.fetch({
                organisationId: organisationId,
                period: LiftStatusStorage?.period?.unit ?? 'year',
              });
          }} />
        </div>
        {/** Charts */}
        {loading ? (
            <div className={classes.progressIndicator}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart counterName="percentage" data={dashboardTileData?.liftAvailability as any} />
                  }
                  title={"Beschikbaarheid liften"}
                />
              </div>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart counterName="lift_count" data={dashboardTileData?.approvalRejected as any} />
                  }
                  title={"Aantal afgekeurde liften"}
                  subtitle={"Het aantal unieke liften met een negatieve keuringsuitslag"}
                />
              </div>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart data={dashboardTileData?.approvalRejected as any} />
                  }
                  title={"Aantal afkeuringen"}
                  subtitle={"Het aantal uitgevoerde keuringen van liften met een negatieve keuringsuitslag"}
                />
              </div>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart counterName="lift_count" data={dashboardTileData?.approvalExpired as any} />
                  }
                  title={"Verlopen keuringen"}
                  subtitle={"Het aantal unieke liften met een verlopen keuring"}
                />
              </div>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart data={dashboardTileData?.maintenanceAfterExpiration as any} />
                  }
                  title={"Onderhoudsbezoek later"}
                  subtitle={"Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is na het overschrijden van het rittenteller gebaseerde onderhoudsinterval"}
                />
              </div>
              <div className={classes.chart}>
                <BigCard
                  chart={
                    <ComparePeriodChart counterName="percentage" data={dashboardTileData?.intercomAvailability as any} />
                  }
                  title={"Beschikbaarheid spreekluister"}
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

const ComparePeriods = () => {
  return (
    <DashboardStatsPeriodProvider>
      <ComparePeriodsInner />
    </DashboardStatsPeriodProvider>
  );
};

export default ComparePeriods;
