import React, { createContext, useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import { LiftStatusStorage } from "Utils/Storage";
import { LiftStatusBaseApi } from "Api/Generic";

interface ILiftVistsProviderProps {
  children: any;
}

interface ILiftStatsRequest {
  liftId?: number;
  from: string;
  to: string;
}

export const LiftVisitsContext = createContext(null);

const LiftVistsProvider = ({ children }: ILiftVistsProviderProps) => {
  const alert = useAlert();
  
  const [request, setRequest] = useState<ILiftStatsRequest>({
    liftId: undefined,
    from: LiftStatusStorage.startDate,
    to: LiftStatusStorage.endDate,
  });

  const fetchLiftVists = async (key, request: ILiftStatsRequest) => {
    if (!request.liftId) {
      return;
    }

    const response = await LiftStatusBaseApi.get({
      uri: `/liftstatus/lift/${request.liftId}/events/visit`,
      urlParameters: {
        from: request.from,
        to: request.to,
        limit: 1000,
      }
    });

    return response?.data;
  };

  const { status, data, error } = useQuery(
    ["lift-visits", request],
    fetchLiftVists, {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const QueryLiftVisits = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error(error);
      alert.show(`Error: ${error.message}`, { type: "error" });
      return status;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  return (
    <LiftVisitsContext.Provider
      value={{
        response: QueryLiftVisits(),
        fetch: setRequest,
      }}
    >
      {children}
    </LiftVisitsContext.Provider>
  );
};

export default LiftVistsProvider;
