import React, { createContext, useState } from "react";
import { useParams } from "react-router-dom";
import { socketClient } from "Api/Socket";
import { IGetModuleSensorDataResponse } from "./SenseModuleProvider";
import Axios from "axios";

interface ISenseModuleProviderHistoryProps {
  esNumber: string;
  children: any;
}

export interface IGetModuleSensorDataRequest {
  from: moment.Moment;
  to: moment.Moment;
  sensors: number[];
}

export const SenseModuleHistoryContext = createContext(null);

const SenseModuleHistoryProvider = ({
  children,
  esNumber,
}: ISenseModuleProviderHistoryProps) => {
  const { liftId } = useParams();
  const [cancelToken, setCancelToken] = useState(null);

  const fetchModuleSensorData = async (
    request: IGetModuleSensorDataRequest
  ) => {
    /**
     * There must be a request to do this
     */
    if (!request) {
      return;
    }
    // Cancel the previous call (if there is a token) before triggering a new call
    if (cancelToken) {
      cancelToken.cancel("Cancel");
      setCancelToken(null);
    }

    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelToken(source);

    try {
      const response = (await socketClient.post({
        liftId,
        uri: `/liftstatus/external/${socketClient.getExternalIdForLift(
          liftId
        )}/sensor`,
        urlParameters: {
          from: request.from,
          to: request.to,
          sensors: request.sensors,
        },
        cancelToken: source?.token,
      })) as IGetModuleSensorDataResponse;

      return response?.data;
    } catch (error) {
      console.error("[ModuleSenseorDataRequest]", error);
      throw error;
    }
  };

  return (
    <SenseModuleHistoryContext.Provider
      value={{
        fetchModuleSensorData,
        esNumber,
      }}
    >
      {children}
    </SenseModuleHistoryContext.Provider>
  );
};

export function useSenseModuleHistory() {
  const context = React.useContext(SenseModuleHistoryContext);
  if (context === undefined) {
    throw new Error(
      "The useSenseModule hook must be used within a SenseModuleContext.Provider"
    );
  }
  return context;
}

export default SenseModuleHistoryProvider;
