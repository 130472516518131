 import LiftIcon from "Assets/LiftIcon";
import { isInteger } from "lodash";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import classes from "./Lift.module.scss";

interface ILiftProps {
  lift: LiftStatus.Schemas.ILift;
  disableHover?: boolean;
  padding?: number;
  disableIcon?: boolean;
}

/** This component will render the information that is shown for a lift */
const Lift = (props: ILiftProps) => {
  const intl = useIntl();
  const { lift } = props;
  const history = useHistory();
  const [hover, setHover] = useState(false);

  const handleClickOnLift = () => {
    history.push(`/lifts/${lift.id}`);
  }

  const title = [];
  lift.details?.city && title.push(lift.details.city);
  lift.details?.country &&
    lift.details.country !== "Nederland" &&
    title.push(lift.details?.country);
  lift.details?.address && title.push(lift.details.address);
  lift.details?.buildingname && title.push(lift.details.buildingname);
  lift.details?.liftname && title.push(lift.details.liftname);

  const subtitle = [];
  lift?.details?.liftnr &&
    subtitle.push(
      `${intl.formatMessage({ id: "lift.nr" })}: ${lift.details.liftnr}`
    );
  lift?.details?.manufacturernr &&
    subtitle.push(
      `${intl.formatMessage({ id: "manufacturer.nr" })}: ${
        lift.details.manufacturernr
      }`
    );
  lift?.details?.ownernr &&
    subtitle.push(
      `${intl.formatMessage({ id: "owner.nr" })}: ${lift.details.ownernr}`
    );
  lift?.details?.advicernr &&
    subtitle.push(
      `${intl.formatMessage({ id: "advicer.nr" })}: ${lift.details.advicernr}`
    );
  lift?.details?.auditnr &&
    subtitle.push(
      `${intl.formatMessage({ id: "audit.nr" })}: ${lift.details.auditnr}`
    );
  lift?.details?.sbcl_id &&
   subtitle.push(
    `${intl.formatMessage({ id: 'sbcl.id'})}: ${lift.details.sbcl_id}`
    );

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
      }}
      key={lift.id}
      className={
        classes.card +
        " " +
        (hover && !props.disableHover ? classes.hover : null)
      }
      style={{ padding: isInteger(props.padding) ? props.padding : 18 }}
      onClick={handleClickOnLift}
    >
      <div className={classes.cardContainer}>
        {!props.disableIcon &&
          <div className={classes.lifticon}>
            <LiftIcon />
          </div>
        }

        <h3>{title.join(", ")}</h3>
        <p>{subtitle.join(", ")}</p>
      </div>
    </div>
  );
};

export default Lift;
