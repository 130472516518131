import React, { useContext, useEffect, useState } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import StatsDetails from 'Pages/StatsDetails/StatsDetails';
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./OrganisationReport.module.scss";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import { GlobalStateContext, IGlobalState } from 'Providers/GlobalStateProvider';
import { find } from 'lodash';

const OrganisationReport = () => {
  const [globalState] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [reportOrganisation, setReporOrganisation] = useState<LiftStatus.Schemas.IOrganisation | undefined>();

  useEffect(() => {
    if (globalState.organisationId && globalState.organisationList?.length) {
      const organisation = find(globalState.organisationList, (org) => org.id === globalState.organisationId);
      setReporOrganisation(organisation);
    }
  }, [ globalState.organisationList, globalState.organisationId ]);

  const dateFormat = new Intl.DateTimeFormat('nl-NL', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const formatDate = (dateString: string) => {
    if ( !dateString ) {
      return 'not set';
    }
    const mDate = new Date(dateString);

    return dateFormat.format(mDate);
  }

  return (
    <table className={classes.page}>
    <thead>
      <tr>
        <th>
          <div className={classes.printHeader}>
            <img className={classes.ednlLogo} src="/assets/images/logo.svg" alt="ednl logo"></img>
            <div>
              <div><MailOutlineIcon /> <a href="mailto:info@ednl.nl">info@ednl.nl</a></div>
              <div><PhoneEnabledOutlinedIcon /> <a href="tel:0031880405300">+31 (0)88 040 5300</a></div>
              <div><LanguageIcon /> <a href="https://www.ednl.nl" target="_blacnk">https://www.ednl.nl</a></div>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th>
          <div className={classes.printSelectedOrganisation}>
            <div>
              <span className={classes.printLabel}>RAPORT ORGANISATIE:</span><br />
              <span>{reportOrganisation?.name}</span>
            </div>
            <div>
              <span className={classes.printLabel}>GEMAAKT DOOR:</span><br />
              <span>{globalState.userOrganisationName}</span>
            </div>
            <div>
              <span className={classes.printLabel}>PERIODE:</span><br />
              <span>{formatDate(LiftStatusStorage.startDate)} t/m {formatDate(LiftStatusStorage.endDate)}</span>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <Dashboard hideSideMenu={true} />
      <StatsDetails type='liftAvailability' lower='0' upper='0.98' hideSideMenu={true} />
      <StatsDetails type='liftAvailability' lower='0.98' upper='0.99' hideSideMenu={true} />
      <StatsDetails type='intercomAvailability' lower='0' upper='0.98' hideSideMenu={true} />
      <StatsDetails type='intercomAvailability' lower='0.98' upper='0.99' hideSideMenu={true} />
      <StatsDetails type='approvalRejected' hideSideMenu={true} />
      <StatsDetails type='approvalExpired' hideSideMenu={true} />
      <StatsDetails type='maintenanceAfterInterval' hideSideMenu={true} />
      <StatsDetails type='maintenanceExpired' hideSideMenu={true} />
    </tbody>
  </table>

  );
};

export default OrganisationReport;