import { Typography } from "@material-ui/core";
import React from "react";
import classes from "./ElevatorinfoField.module.scss";

const ElevatorInfoField = ({
  value,
  label,
  badge,
  level,
  invertBadgeColor,
  dateTime,
  hr,
}: {
  value: string | number;
  label?: string;
  badge: string;
  invertBadgeColor?: boolean;
  dateTime?: string;
  hr?: boolean;
  level?: "impact" | "non-impact";
}) => {
  return (
    <div
      className={`
      ${classes.container}
      ${level && level === "impact" && classes.impact}
      ${level && level === "non-impact" && classes.nonImpact}
      `}
      title={label ?? ""}
    >
      <div className={classes.textDiv}>
        <Typography className={`${classes.text} ${classes.value}`} variant="subtitle1">
          {value ?? "-"}
        </Typography>

        <Typography className={`${classes.text} ${classes.date}`} variant="subtitle2">
          {dateTime ?? null}
        </Typography>

        <div className={classes.textDiv}>
          <span className={classes.level + " " + classes.text}>{level ? level : "unknown"}</span>

          {badge && (
            <Typography className={classes.badge + " " + classes.text} variant="caption">
              {badge}
            </Typography>
          )}
        </div>
      </div>

      {hr ? <hr className={classes.bottomLine} /> : <></>}
    </div>
  );
};
export default ElevatorInfoField;
