import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext, useContext } from "react";
import { useAlert } from "react-alert";
import { LiftStatusStorage } from "Utils/Storage";
import { GlobalStateContext, IGlobalState } from "./GlobalStateProvider";
export interface IStatsDetailsRequest {
  limit?: number;
  offset?: number;
  organisationId?: number;
  type: string;
  from: string;
  to: string;
  lower?: string;
  upper?: string;
}

interface IStatsDetailsProviderProps {
  children: any;
}

export const StatsDetailsContext = createContext(null);

const StatsDetailsProvider = ({ children }: IStatsDetailsProviderProps) => {
  const alert = useAlert();
  const [globalState] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

  // Fetch lifts by text query
  //
  const fetchStatsDetails = async (
    statsDetailsQuery: IStatsDetailsRequest
  ) => {
    /**
     * There must be a query to do this
     */
    if (!statsDetailsQuery) {
      return;
    }

    const urlParameters: IStatsDetailsRequest = {
      type: statsDetailsQuery.type,
      from: LiftStatusStorage.startDate,
      to: LiftStatusStorage.endDate,
    };

    if (statsDetailsQuery.limit) {
      urlParameters.limit = statsDetailsQuery.limit;
    }

    if (statsDetailsQuery.offset) {
      urlParameters.offset = statsDetailsQuery.offset;
    }

    if (statsDetailsQuery.organisationId) {
      urlParameters.organisationId = statsDetailsQuery.organisationId;
    }
    if (statsDetailsQuery.lower) {
      urlParameters.lower = statsDetailsQuery.lower;
    }
    if (statsDetailsQuery.upper) {
      urlParameters.upper = statsDetailsQuery.upper;
    }

    let response;

    try {
      if (globalState.viewIsCurrent) {
        response = await LiftStatusBaseApi.get({
          uri: "/liftstatus/current-state/details",
          urlParameters: {
            organisationId: statsDetailsQuery.organisationId,
            type: statsDetailsQuery.type,
          },
        });
      } else {
        response = await LiftStatusBaseApi.get({
          uri: "/liftstatus/stats/details",
          urlParameters,
        });
      }
    } catch (error) {
      console.error("[LIFTS:STATS-DETAILS]:", error);
      alert.show("Error fetching lifts", { type: "error" });
    }

    const data: LiftStatus.Schemas.IStatsEventDto[] = response?.data;
    return data;
  };

  return (
    <StatsDetailsContext.Provider
      value={{
        fetchStatsDetails,
      }}
    >
      {children}
    </StatsDetailsContext.Provider>
  );
};

export default StatsDetailsProvider;
