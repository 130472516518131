import { CircularProgress, Typography } from "@material-ui/core";
import { get } from "lodash";
import {
  useSenseModule
} from "Providers/SenseModuleProvider";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { timeAgoFromMilliseconds } from "Utils/Helpers";
import classes from "./ElevatorRealtimeInfo.module.scss";

const ElevatorRealtimeInfo: React.FunctionComponent = () => {
  const senseModuleProvider = useSenseModule();

  const [lastMovementTimeStamp, setLastMovementTimeStamp] = useState<string>();

  React.useEffect(() => {
    const lastUpdate = get(
      senseModuleProvider,
      "senseModuleSensorData.lastUpdate"
    );

    setLastMovementTimeStamp(timeAgoFromMilliseconds(lastUpdate, false));

    // This is here to update every minute
    // so the label isn't 'still' when nothing is happening
    //
    const interval = setInterval(() => {
      const lastUpdate = get(
        senseModuleProvider,
        "senseModuleSensorData.lastUpdate"
      );

      setLastMovementTimeStamp(timeAgoFromMilliseconds(lastUpdate, false));
    }, 610000);
    return () => {
      clearInterval(interval);
    };
  }, [
    senseModuleProvider.senseModuleSensorData,
    senseModuleProvider.movementPoints,
    senseModuleProvider,
  ]);

  return (
    <React.Fragment>
      {lastMovementTimeStamp && (
        <div className={classes.wrapper}>
          <span className={classes.lastTimeStamp}>
            <span className={classes.lastChange}>
              <FormattedMessage id="last.change" />:{" "}
            </span>
            {senseModuleProvider?.loading ? (
              <div className={classes.loading}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Typography className={classes.badge} variant="caption">
                {lastMovementTimeStamp}
              </Typography>
            )}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default ElevatorRealtimeInfo;
