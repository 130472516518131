import { CircularProgress } from "@material-ui/core";
import EventDetailsCard from "Components/EventDetailsCard/EventDetailsCard";
import SideMenu from "Components/SideMenu/SideMenu";
import SingleEventDataProvider, {
  SingleEventDataContext,
} from "Providers/SingleEventDataProvider";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./EventPage.module.scss";

const EventInternalPage = () => {
  const { eventId } = useParams();
  const singleEventDataProvider = useContext(SingleEventDataContext);
  const [event, setEvent] = useState<LiftStatus.Schemas.IEvent>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      singleEventDataProvider
        .dispatchSingleEventDataRequest({ eventId })
        .then((response: LiftStatus.Schemas.IEvent) => {
          setEvent({ ...response });
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
    const deepLinkEventId = localStorage.getItem('deep-link-event-id');
    if (deepLinkEventId ) {
      // If we arrived here and there is a deep link event id in localstorage
      // we want to remove that so we do not enter a situation
      // where the user keeps logging into this old event id
      // we get the current id from the url params, we only 
      // use the local storage id to trigger a nav to this page
      // after the authguard kicks the user to login.
      //
      localStorage.removeItem('deep-link-event-id');
    }
  }, [eventId, singleEventDataProvider]);

  return (
    <div className={classes.root}>
      <SideMenu />
      <div className={classes.wrapper}>
        {loading ? <CircularProgress /> : <></>}
        {event ? <EventDetailsCard event={event} /> : <></>}
        {!loading && !event && <>Geen Event</>}
      </div>
    </div>
  );
};

const EventPage = () => (
  <SingleEventDataProvider>
    <EventInternalPage />
  </SingleEventDataProvider>
);

export default EventPage;
