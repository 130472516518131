import { LiftStatusBaseApi } from "Api/Generic";
import {
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import React, { createContext, useContext, useState } from "react";
import Axios from "axios";

interface IDashboardStatsProviderProps {
  children: any;
}

interface ILiftStatusRequest {
  organisationId: number;
  from: string;
  to: string;
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
}

export const DashboardStatsContext = createContext(null);

const DashboardStatsProvider = ({ children }: IDashboardStatsProviderProps) => {
  const [globalState] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [cancelToken, setCancelToken] = useState(null);

  const fetchDashboardStats = async (request: ILiftStatusRequest) => {
    // Cancel the previous call (if there is a token) before triggering a new call
    if (cancelToken) {
      cancelToken.cancel("Cancel");
      setCancelToken(null);
    }

    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelToken(source);

    try {
      let response;
      if (globalState.viewIsCurrent) {
        response = await LiftStatusBaseApi.get({
          uri: "/liftstatus/current-state",
          urlParameters: {
            organisationId: request.organisationId ?? 0,
          },
          cancelToken: source?.token,
        });
      } else {
        response = await LiftStatusBaseApi.get({
          uri: "/liftstatus/stats",
          urlParameters: {
            organisationId: request.organisationId ?? 0,
            from: request.from,
            to: request.to,
            startHour: request.startHour ?? 0,
            endHour: request.endHour ?? 0,
          },
          cancelToken: source?.token,
        });
      }

      const data: LiftStatus.Schemas.IStatsOverviewDto = response?.data;
      return data;
    } catch (error) {
      console.error("[DASHBOARDSTATSPROVIDER]", error);
      throw error;
    }
  };

  return (
    <DashboardStatsContext.Provider
      value={{
        fetch: fetchDashboardStats,
      }}
    >
      {children}
    </DashboardStatsContext.Provider>
  );
};

export default DashboardStatsProvider;
