import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext } from "react";

interface IEventSubscriptionProps {
  children: any;
}

interface IEventSubscriptionRequest {
  ouId?: number;
}

interface IEventPostSubscriptionRequest {
  type: 'realtime' | 'report';
  eventKey: string;
}

interface IEventDeleteSubscriptionRequest {
  id: number
}

export const EventSubscriptionContext = createContext(null);

const EventSubscriptionProvider = ({
  children,
}: IEventSubscriptionProps) => {

  const fetchEventSubscription = async (
    request: IEventSubscriptionRequest
  ) => {
    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/event-subscription/${request ? request.ouId : ''}`,
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const postEventSubscribtion = async (
    request: IEventPostSubscriptionRequest
  ) => {
    try {
      const response = await LiftStatusBaseApi.post({
        uri: `/liftstatus/event-subscription/`,
        payload: {
          ...request
        }
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const deleteEventSubscribtion = async (
    request: IEventDeleteSubscriptionRequest
  ) => {
    try {
      const response = await LiftStatusBaseApi.delete({
        uri: `/liftstatus/event-subscription/${request.id}`,
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };


  return (
    <EventSubscriptionContext.Provider
      value={{
        get: fetchEventSubscription,
        post: postEventSubscribtion,
        delete: deleteEventSubscribtion
      }}
    >
      {children}
    </EventSubscriptionContext.Provider>
  );
};

export default EventSubscriptionProvider;
