import { isArray } from "lodash";
import React from "react";
import { Pie } from "react-chartjs-2";
import classes from "./AvailabilityChart.module.scss";

export interface AvailabilityChartProps {
  lifts: {
    bracket: LiftStatus.Schemas.IStatsBracketDto[];
  };
  intercom: {
    bracket: LiftStatus.Schemas.IStatsBracketDto[];
  };
  showDetails?: Function;
}

const AvailabilityChart = (props: AvailabilityChartProps) => {
  // Let's create some labels
  //
  const { lifts, intercom } = props;
  const labels = ["0% tot 98%", "98% tot 99%", "99 tot 100%"];

  const availabilityData = {
    labels,
    datasets: [
      {
        label: "Liften",
        data: isArray(lifts?.bracket) ? lifts.bracket.map( bracket => bracket.count ) : [],
        fill: true,
        backgroundColor: ["#F3776D", "#F3C46D", "#86B29C"],
        borderColor: "white",
      },
    ],
  };

  const loudSpeakerData = {
    labels,
    datasets: [
      {
        label: "Spreekluister",
        data: isArray(intercom?.bracket) ? intercom.bracket.map( bracket => bracket.count ) : [],
        fill: true,
        backgroundColor: ["#F3776D", "#F3C46D", "#86B29C"],
        borderColor: "white",
      },
    ],
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftGraph}>
        <Pie
          data={availabilityData}
          options={{
            title: {
              display: true, text: "Liften"
            },
            // responsive: false
          }}
          getElementAtEvent={(elem) => {
            if (!elem || !elem.length) {
              return;
            }
            const dataSetIndex = elem[0]._index;
            const dataSet = [];

            if (dataSetIndex === 0) {
              dataSet.push(0, 0.98);
            } else if (dataSetIndex === 1) {
              dataSet.push(0.98, 0.99);
            } else {
              dataSet.push(0.99, null);
            }

            if (props.showDetails) {
              props.showDetails("lifts", dataSet);
            }
          }}
        />
      </div>
      <div className={classes.rightGraph}>
        <Pie
          data={loudSpeakerData}
          options={{
            title: {
              display: true, text: "Spreekluister"
            },
            // responsive: false
          }}
          getElementAtEvent={(elem) => {
            if (!elem || !elem.length) {
              return;
            }
            const dataSetIndex = elem[0]._index;
            const dataSet = [];

            if (dataSetIndex === 0) {
              dataSet.push(0, 0.98);
            } else if (dataSetIndex === 1) {
              dataSet.push(0.98, 0.99);
            } else {
              dataSet.push(0.99, null);
            }

            if (props.showDetails) {
              props.showDetails("loudspeaker", dataSet);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AvailabilityChart;
