import React, { createContext } from "react";
import { useQuery } from "react-query";
import { useAlert } from "react-alert";
import { LiftStatusBaseApi } from "Api/Generic";

export const VersionNumberContext = createContext(null);

const VersionNumberProvider = ({ children }: any) => {
  const alert = useAlert();

  const fetchVersionNumber = async () => {

    try {
      const response = await LiftStatusBaseApi.get({
        uri: '/liftstatus/version',
      });
      return response?.data;
    } catch (error) {
      return;
    }
  };

  const { status, data, error } = useQuery(
    ["version"],
    fetchVersionNumber,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const QueryVersionNumber = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error(error);
      alert.show("Error fetching version", { type: "error" });
      return;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  return (
    <VersionNumberContext.Provider
      value={{
        response: QueryVersionNumber(),
      }}
    >
      {children}
    </VersionNumberContext.Provider>
  );
};

export default VersionNumberProvider;
