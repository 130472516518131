import React, { createContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { LiftStatusBaseApi } from 'Api/Generic';

interface ILiftsDetailsProviderProps {
  children: any;
}

export interface ILiftDetailsServiceCountersRequest {
  liftId: number;
  from: string;
  to: string;
  type: 'daily' | 'weekly' | '15mins'
}

export const LiftDetailsContext = createContext(null);

const LiftDetailsProvider = ({ children }: ILiftsDetailsProviderProps) => {
  const alert = useAlert();
  const { liftId } = useParams();
  const [liftIdQuery, setLiftIdQuery] = useState({ liftId });

  const fetchLiftDetails = async (_key, liftIdQuery) => {

    if ( liftIdQuery.liftId) {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${liftIdQuery.liftId}`,
      });

      const data = await response?.data;

      return data;
    }

  };

  const { status, data, error } = useQuery(
    ["lift-details", liftIdQuery],
    fetchLiftDetails,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const QueryLiftDetails = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error(error);
      alert.show(`Error: ${error.message}`, { type: "error" });
      return status;
    }

    const dataToReturn = data;
    return dataToReturn;
  }, [data, error, status, alert]);

  return (
    <LiftDetailsContext.Provider
      value={{
        response: QueryLiftDetails(),
        fetchLiftDetails: setLiftIdQuery,
      }}
    >
      {children}
    </LiftDetailsContext.Provider>
  );
};

export default LiftDetailsProvider;
