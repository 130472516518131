/* eslint-disable react/prop-types */
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import BigCard from "Components/BigCard/BigCard";
import AvailabilityChart from "Components/DashboardCharts/AvailabilityChart/AvailabilityChart";
import Periods from "Components/Periods/Periods";
import SideMenu from "Components/SideMenu/SideMenu";
import { isNumber } from "lodash";
import DashboardStatsProvider, {
  DashboardStatsContext,
} from "Providers/DashboardStatsProvider";
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import LiftsProvider, {
  ILiftRequest,
  LiftsContext,
} from "Providers/LiftsProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { LiftStatusStorage } from "Utils/Storage";
import SmallCardsGrid from "../../Components/SmallCardsGrid/SmallCardsGrid";
import classes from "./Dashboard.module.scss";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useCallReportService from "Hooks/useCallReportService.hook";

type IDashboardProps = {
  hideSideMenu?: boolean;
};

const InnerDashboard: React.FC<IDashboardProps> = ({ hideSideMenu }) => {
  const dashboardStatsProvider = useContext(DashboardStatsContext);
  const liftsProvider = useContext(LiftsContext);
  const [dashboardTileData, setDashboardTileData] = useState(
    null as LiftStatus.Schemas.IStatsOverviewDto
  );
  const [globalState, globalStateDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

  const [totalLifts, setTotalLifts] = useState(0);

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const availabilityLiftRef = useRef<HTMLDivElement>(null);
  const [organisationHasNoLifts, setorganisationHasNoLifts] = useState(false);

  const fetchDashboardStats = () => {
    setorganisationHasNoLifts(false);
    setLoading(true);
    if (globalState.organisationId) {
      dashboardStatsProvider
        .fetch({
          organisationId: globalState.organisationId,
          from: LiftStatusStorage.startDate,
          to: LiftStatusStorage.endDate,
          startHour: 7,
          endHour: 19,
        })
        .then((response) => {
          setDashboardTileData(response);
          setLoading(false);
        })
        .catch((e) => {
          if (e.statusCode === 404) {
            setorganisationHasNoLifts(true);
            setLoading(false);
          }
        });

      const liftRequest: ILiftRequest = {
        limit: 1,
        offset: 0,
        organisationId: globalState.organisationId,
      };

      liftsProvider.textSearch(liftRequest);
    }
  };

  const [reportLoading, fetchReport] = useCallReportService({
    subjectId: globalState.organisationId,
    report: "organisation",
  });

  useEffect(() => {
    fetchDashboardStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.organisationId,
    globalState.viewIsCurrent,
    LiftStatusStorage.startDate,
  ]);

  useEffect(() => {
    setTotalLifts(liftsProvider.textSearchResults?.total);
  }, [liftsProvider.textSearchResults]);

  useEffect(() => {
    let refetchDashboardCurrentStateTimer;

    if (globalState.viewIsCurrent) {
      refetchDashboardCurrentStateTimer = setTimeout(() => {
        fetchDashboardStats();
      }, 60000);
    }

    return () => {
      clearTimeout(refetchDashboardCurrentStateTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.viewIsCurrent, globalState.organisationId]);

  return (
    <div className={classes.root}>
      {!hideSideMenu && <SideMenu />}
      <div className={classes.content}>
        {/** Periods bar */}
        { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
          <div className={classes.periods}>
            <Periods
              onDateChange={(dateRange) => {
                globalStateDispatch({
                  type: EGlobalStateCases.setDatesLocal,
                  payload: {
                    startDate: dateRange.from,
                    endDate: dateRange.to,
                  },
                });
              }}
            />
          </div>
        ) : (
          <></>
        )}

        {/** Notifiy that there needs to be an organisation selected */}
        {globalState.organisationId === null && (
          <Box className={classes.noorganisation}>
            <Typography variant="subtitle1">
              <FormattedMessage id="first.select.an.organisation" />
            </Typography>
          </Box>
        )}

        <>
          {isNumber(globalState.organisationId) && (
            <>
              {organisationHasNoLifts || (!totalLifts && !loading) ? (
                <Typography variant="subtitle1">
                  <FormattedMessage id="organisation.has.no.lifts" />
                </Typography>
              ) : (
                <React.Fragment>
                  {totalLifts ? (
                    <div className={classes.titleBar}>
                      <h2 className={classes.title}>
                        <FormattedMessage id="total.lifts" />: {totalLifts}
                      </h2>
                      {!globalState.viewIsCurrent && !loading ? (
                        <>
                          {reportLoading ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              disabled={reportLoading}
                              onClick={async () => {
                                await fetchReport();
                              }}
                            >
                              <PictureAsPdfIcon></PictureAsPdfIcon>
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : loading ? (
                    <div className={classes.progressIndicator}>
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <></>
                  )}

                  {
                    <>
                      <SmallCardsGrid
                        availabilityLiftRef={availabilityLiftRef}
                        liftStats={dashboardTileData}
                        loading={loading}
                        liftDetailsPage={false}
                        viewIsCurrent={globalState.viewIsCurrent}
                      />

                      {/** Charts */}
                      {globalState.viewIsCurrent ? (
                        <></>
                      ) : (
                        <div
                          className={classes.chart}
                          ref={availabilityLiftRef}
                        >
                          <BigCard
                            loading={loading}
                            chart={
                              <AvailabilityChart
                                intercom={
                                  dashboardTileData?.intercomAvailability
                                }
                                lifts={dashboardTileData?.liftAvailability}
                                showDetails={(type, bracket) => {
                                  history.push(
                                    `/stats-details/${
                                      type === "lifts"
                                        ? "liftAvailability"
                                        : "intercomAvailability"
                                    }/${bracket[0]}/${bracket[1]}`
                                  );
                                }}
                              />
                            }
                            title={"Beschikbaarheid"}
                          />
                        </div>
                      )}
                    </>
                  }
                </React.Fragment>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

const Dashboard: React.FC<IDashboardProps> = (props) => {
  return (
    <DashboardStatsProvider>
      <LiftsProvider>
        <InnerDashboard {...props} />
      </LiftsProvider>
    </DashboardStatsProvider>
  );
};

export default Dashboard;
