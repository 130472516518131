import { LiftStatusBaseApi } from "Api/Generic";
import React, { createContext } from "react";

interface IEventNotificationOrganisationFilterProviderProps {
  children: any;
}

interface IEventNotificationOrganisationFilterRequest {
  ouId?: number;
}


interface IEventNotificationOrganisationFilterPost {
  ouId?: number;
}

interface IEventNotificationOrganisationFilterDelete {
  id: number;
}

export const EventNotificationOrganisationFilterContext = createContext(null);

const EventNotificationOrganisationFilterProvider = ({
  children,
}: IEventNotificationOrganisationFilterProviderProps) => {

  const getEventNotificationFilter = async (
    request: IEventNotificationOrganisationFilterRequest
  ) => {
    try {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/event-notification-organisation-filter/${request ? request.ouId : ''}`,
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const postEventNotificationFilter = async (
    request: IEventNotificationOrganisationFilterPost
  ) => {
    try {
      const response = await LiftStatusBaseApi.post({
        uri: `/liftstatus/event-notification-organisation-filter`,
        payload: {
          organisationId: request.ouId
        }
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const deleteEventNotificationFilter = async (
    request: IEventNotificationOrganisationFilterDelete
  ) => {
    try {
      const response = await LiftStatusBaseApi.delete({
        uri: `/liftstatus/event-notification-organisation-filter/${request.id}`,
      });

      const data = await response?.data;

      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return (
    <EventNotificationOrganisationFilterContext.Provider
      value={{
        get: getEventNotificationFilter,
        post: postEventNotificationFilter,
        delete: deleteEventNotificationFilter
      }}
    >
      {children}
    </EventNotificationOrganisationFilterContext.Provider>
  );
};

export default EventNotificationOrganisationFilterProvider;
