// eslint-disable-next-line no-unused-vars
import Axios, { AxiosRequestConfig, CancelToken, ResponseType } from "axios";
import { LiftStatusStorage } from "Utils/Storage";
import { each } from "lodash";
import env from "../Assets/env.json";

export interface ILiftStatusBaseApiOptions {
  uri: string;
  responseType?: ResponseType;
  payload?: any;
  urlParameters?: any;
  cancelToken?: CancelToken
}
export class LiftStatusBaseApi {


  static handleError(error) {
    console.error('[GENERIC-API]: Error', error);

    if (error.response?.status === 401) {
      console.error("[GENERIC-API]: Found 401 now redirecting to login", error);

      if (!["/", "/login"].includes(document.location.pathname)) {
        document.location.href = "/";
        LiftStatusStorage.userToken = "";
      }
    }
  }
  // Handle 401's the same everywhere
  //
  static init() {
    /*   Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          console.error(
            "[GENERIC-API]: Found 401 now redirecting to login",
            error
          );
           if (!['/', '/login'].includes(document.location.pathname)) {
        document.location.href = '/';
      }
        }

        return error;
      }
    ); */
  }

  /**
   * Figure out the host of the API
   */
  static getHost() {
    return env.endpoint;
  }

  /**
   * Headers are always the same for all calls
   */
  static getHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${LiftStatusStorage.userToken}`,
    };
  }

  /**
   * If URL parameters are provided as an object
   * Create nice URL parameters for them
   */
  static getUrlParameters(input: any) {
    let optionalParams = undefined;
    if (input) {
      const params = new URLSearchParams();
      each(input, (value, prop) => {
        params.append(prop, value);
      });
      optionalParams = params;
    }
    return optionalParams;
  }

  /**
   * Generic Axios get method wrapper
   * @param options: LiftStatusBaseApiOptions
   */
  static get(options: ILiftStatusBaseApiOptions) {

    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(),
      cancelToken: options.cancelToken
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }

    return Axios.get(`${this.getHost()}${options.uri}`, config).catch(
      (error) => {
        if (Axios.isCancel(error)) {
          console.error('Request canceled', error.message);
          throw error;
        } else {
          this.handleError(error);
          if (error.response) {
            // Request made and server responded
            throw error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            throw error.request
          } else {
            // Something happened in setting up the request that triggered an Error
            throw error.message
          }
        }
      }
    );
  }

  /**
   * Generic Axios post method wrapper
   * @param options: LiftStatusBaseApiOptions
   */
  static post(options: ILiftStatusBaseApiOptions) {
    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(),
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }

    return Axios.post(
      `${this.getHost()}${options.uri}`,
      options.payload,
      config
    ).catch((error) => {
      this.handleError(error);
      return error;
    });
  }

  /**
   * Generic Axios put method wrapper
   * @param options: LiftStatusBaseApiOptions
   */
  static put(options: ILiftStatusBaseApiOptions) {
    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(),
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }

    return Axios.put(
      `${this.getHost()}${options.uri}`,
      options.payload,
      config
    ).catch((error) => {
      this.handleError(error);
      return error;
    });
  }

  /**
   * Generic Axios delete method wrapper
   * @param options: LiftStatusBaseApiOptions
   */
  static delete(options: ILiftStatusBaseApiOptions) {
    const config: AxiosRequestConfig = {
      params: this.getUrlParameters(options.urlParameters),
      headers: this.getHeaders(),
    };

    if (options.responseType) {
      config.responseType = options.responseType;
    }

    return Axios.delete(`${this.getHost()}${options.uri}`, config);
  }
}

LiftStatusBaseApi.init();
