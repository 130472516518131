import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import ElevatorRealtimeInfo from "Components/ElevatorRealtimeInfo/ElevatorRealtimeInfo";
import { IExtendedAlarmData } from "Pages/CurrentCalls/CurrentCalls";
import SenseModuleProvider from "Providers/SenseModuleProvider";
import React from "react";
import { FormattedMessage } from "react-intl";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IAlertSenseDataModalProps {
  alarmData: IExtendedAlarmData;
}

export default function AlertSenseDataModal({
  alarmData,
}: IAlertSenseDataModalProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {alarmData ? (
        <SenseModuleProvider
          esNumber={alarmData?.lift_data?.sense_link[0]?.es_number}
        >
          <div>
            <Button size="small" onClick={handleClickOpen}>
              Sense Data
            </Button>

            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {alarmData?.lift_data?.details?.address}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <React.Fragment>
                    <ElevatorRealtimeInfo />
                  </React.Fragment>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  <FormattedMessage id={"lift.details"} />
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </SenseModuleProvider>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
