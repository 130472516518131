import { DeleteOutline } from '@material-ui/icons';
import Lift from 'Components/Lift/Lift';
import Periods from 'Components/Periods/Periods';
import { EGlobalStateCases, GlobalStateContext, IGlobalState } from 'Providers/GlobalStateProvider';
import React, { useContext } from 'react';
import { Spring } from 'react-spring/renderprops';
import classes from "./StickyHeader.module.scss";

interface IStickyHeaderProps {
  liftData: LiftStatus.Schemas.ILift;
  showHeader: boolean;
  showDeleteIcon?: boolean;
  showPeriods: boolean;
}

const StickyHeader = ({ liftData, showHeader, showDeleteIcon, showPeriods }: IStickyHeaderProps) => {
  const [globalState, globalStateDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

    const unLockLift = () => {
     globalStateDispatch({type: EGlobalStateCases.removedLockedLiftId});
    }

  return (
    <Spring
      to={{
        opacity: showHeader ? 1 : 0,
        transform: showHeader ? "translateY(0px)" : "translateY(-50px)",
      }}
    >
      {({ opacity, transform }) => (
        <div className={classes.stickyHeader} style={{ opacity, transform }}>
          <div className={classes.headerLiftData}>
            {liftData  && globalState.lockedLiftId ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {globalState.lockedLiftId && showDeleteIcon ? (<span onClick={() => unLockLift()} className={classes.deleteLiftIcon}><DeleteOutline ></DeleteOutline></span>) : (<></>)}
                <Lift key={liftData?.id} lift={liftData} disableHover={true} />
              </div>
            ) : (
              <></>
            )}
          </div>

          {showPeriods ? (
             <div className={classes.headerPeriods}>
             <Periods
               hideNavigation={true}
               onDateChange={(dateRange) => {
                 globalStateDispatch({
                   type: EGlobalStateCases.setDatesLocal,
                   payload: {
                     startDate: dateRange.from,
                     endDate: dateRange.to,
                   },
                 });
               }}
             />
           </div>
          ) : (<></>)}


        </div>
      )}
    </Spring>

  )
}

export default StickyHeader;