import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useContext } from "react";
import { isFunction } from "lodash";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  IGlobalState,
  GlobalStateContext,
  EGlobalStateCases,
} from "Providers/GlobalStateProvider";
import { useHistory } from "react-router-dom";
import { AnnouncementOutlined } from "@material-ui/icons";

export interface IProfileModalProps {
  onConfirm: () => void;
  onClose?: () => void;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
}

function ProfileModal(props: IProfileModalProps) {
  const { onClose, onConfirm, open, setOpen } = props;
  const history = useHistory();

  const [globalState, globalStateDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);

  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }

    setOpen(false);
  };

  const handleLogout = () => {
    handleClose();
    globalStateDispatch(EGlobalStateCases.logOut);
    if (isFunction(onConfirm)) {
      onConfirm();
    }
  };

  const handleSubscribtionClick = () => {
    history.push("/subscriptions");
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="Profile" open={open}>
      <DialogTitle>{globalState.username}</DialogTitle>
      <DialogContent>
        <List>
          {( !globalState.userClientLight && !globalState.userMechanic ) ? (
            <ListItem autoFocus button onClick={handleSubscribtionClick}>
              <ListItemAvatar>
                <Avatar>
                  <AnnouncementOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`Meldingen`} />
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem autoFocus button onClick={handleLogout}>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Log ${globalState.username} uit`} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileModal;
