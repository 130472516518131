import React, { createContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LiftStatusBaseApi } from 'Api/Generic';
import Axios from "axios";

interface ILiftSBCLInstallationProviderProps {
  children: any;
}

export interface ILiftSBCLInstallationRequest {
  liftId: number;
}

export const LiftSBCLInstallationContext = createContext(null);

const LiftSBCLInstallationProvider = ({ children }: ILiftSBCLInstallationProviderProps) => {

  const { liftId } = useParams();
  const [liftIdQuery] = useState({ liftId });
  const [cancelToken, setCancelToken] = useState(null);

  const fetchLiftSBCLInstallationDetails = async (request?: ILiftSBCLInstallationRequest) => {


    // Cancel the previous call (if there is a token) before triggering a new call
    if (cancelToken) {
      cancelToken.cancel("Cancel");
      setCancelToken(null);
    }

    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelToken(source);

    if ( liftIdQuery.liftId ) {
      const response = await LiftStatusBaseApi.get({
        uri: `/liftstatus/lift/${liftIdQuery.liftId ?? request.liftId}/sbcl-installation`, cancelToken: source.token
      });

      const data = await response?.data;

      return data;
    }

  };

  return (
    <LiftSBCLInstallationContext.Provider
      value={{
        fetch: fetchLiftSBCLInstallationDetails,
      }}
    >
      {children}
    </LiftSBCLInstallationContext.Provider>
  );
};

export default LiftSBCLInstallationProvider;
