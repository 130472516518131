import RealTimeLiftData from "Components/RealTimeLiftData/RealTimeLiftData";
import React from "react";
import classes from "./LiftDetailsRealTime.module.scss";
interface ILiftDetailsRealTimeProps {
  width: number;
  liftData: LiftStatus.Schemas.ILift;
  liftFlowAvailable: boolean;
}
const LiftDetailsRealTime = ({
  width,
  liftData,
  liftFlowAvailable,
}: ILiftDetailsRealTimeProps) => {

  return (
    <div className={classes.wrapper}>
      <React.Fragment>
        <>
          {width && (
            <RealTimeLiftData
              width={width}
              lift={liftData}
              liftFlowAvailable={liftFlowAvailable}
            ></RealTimeLiftData>
          )}
        </>
      </React.Fragment>
    </div>
  );
};

export default LiftDetailsRealTime;
