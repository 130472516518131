import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import classes from "./LiftSBCLInstallation.module.scss";
interface Column {
  id:
    | "id"
    | "description"
    | "referenceId"
    | "type"
    | "sbclId"
    | "approved"
    | "end";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "end",
    label: "Datum",
    minWidth: 70,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  { id: "description", label: "Omschrijving", minWidth: 120 },
  {
    id: "referenceId",
    label: "Reference ID",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "type",
    label: "Type",
    minWidth: 70,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "approved",
    label: "Goed gekeurd",
    minWidth: 70,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
];

interface ILogData {
  description: string;
  end: string;
  id: string;
  referenceId: string;
  sbclId: string;
  type: string;
  approved: boolean;
}

function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const LiftSBCLLogsTable = ({ logData }: any) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<ILogData[] | []>([]);
  const intl = useIntl();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    let rowsArray = [];

    if (logData) {
      rowsArray = logData?.results?.map((result: ILogData) => {
        return {
          approved: result.approved,
          description: result.description,
          end: moment(result.end).format("DD-MM-YYYY HH:mm"),
          id: result.id,
          referenceId: result.referenceId,
          sbclId: result.sbclId,
          type: intl.formatMessage({
            id: `sbcl.log.type.${result.type.replace(" ", ".")}`,
          }),
        };
      });

      setRows(rowsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logData]);

  return (
    <>
      {rows ? (
        <>
          <Paper style={{width: '100%'}}>
            <TableContainer>
              <Table
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{whiteSpace: "nowrap"}}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows ? (
                    <>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow hover tabIndex={-1} key={row?.id}>
                              {columns.map((column) => {
                                const value = row[column?.id];
                                return (
                                  <TableCell key={column?.id} className={classes[`col-${column?.id}`]}>
                                    {column?.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              style={{maxWidth: '100%', width:"inherit" }}
                rowsPerPageOptions={[5, 10, 15, 30]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
          </Paper>
        </>
      ) : (
        <>No rows data</>
      )}
    </>
  );
};

export default LiftSBCLLogsTable;
