import React from "react";
import { Card, CardContent, Button, CircularProgress } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import classes from "./BigCard.module.scss";

export interface IBigCardProps {
  title: string;
  subtitle?: string;
  buttonOne?: string;
  buttonOneAction?: Function;
  buttonTwo?: string;
  buttonTwoAction?: Function;
  chart?: any;
  loading?: boolean
}

const BigCard = (props: IBigCardProps) => {
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {props.loading ? (
          <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
            <React.Fragment>
              <span className={classes.title}>{props.title}</span>
              <span className={classes.subtitle}>{props.subtitle}</span>
              {props.chart ? props.chart : null}
              {props.buttonOne ? (
                <Button onClick={() => props.buttonOneAction && props.buttonOneAction()}>
                  {props.buttonOne}
                  <ArrowForwardIcon />
                </Button>
              ) : null}

              {props.buttonTwo ? (
                <Button onClick={() => props.buttonTwoAction && props.buttonTwoAction()}>
                  {props.buttonTwo}
                  <ArrowForwardIcon />
                </Button>
              ) : null}
            </React.Fragment>
          )}
      </CardContent>
    </Card>
  );
};

export default BigCard;
