import { LiftStatusBaseApi } from 'Api/Generic';
import React, { createContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';

export interface IAlarmCallsResponse {
  items: LiftStatus.Schemas.ICallDetailRecordAlarm[];
  pagination: {
    limit: string;
  };
  total: number;
}

export interface IAlarmCallsRequest {
  limit: number;
  offset: number;
  organisationId?: number;
  liftId?: number;
}

interface IAlarmCallsProviderProps {
  children: any;
}

export const AlarmCallsContext = createContext(null);

const AlarmCallsProvider = ({ children }: IAlarmCallsProviderProps) => {
  const alert = useAlert();
  const [alarmCallsQuery, setAlarmCallsQuery] = useState();

  const fetchAlarmCalls = async (key, alarmCallsQuery: IAlarmCallsRequest) => {

    /**
     * There must be a query to do this
     */
    if (!alarmCallsQuery) {
      return;
    }

    const urlParameters: IAlarmCallsRequest = {
      limit: alarmCallsQuery.limit,
      offset: alarmCallsQuery.offset,
    };

    if (alarmCallsQuery.organisationId) {
      urlParameters.organisationId = alarmCallsQuery.organisationId;
    }

    if (alarmCallsQuery.liftId) {
      urlParameters.liftId = alarmCallsQuery.liftId;
    }


    const response = await LiftStatusBaseApi.get({
      uri: '/liftstatus/alarm',
      urlParameters,
    });

    const data: IAlarmCallsResponse = response?.data;
    return data;
  };

  const { status: fetchStatus, data: fetchData, error: fetchError } = useQuery(
    ["alarmCalls", alarmCallsQuery],
    fetchAlarmCalls,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const QueryAlarmCalls = React.useCallback(() => {
    if (fetchStatus === "loading") {
      return fetchStatus;
    }

    if (fetchStatus === "error") {
      console.error(fetchError);
      alert.show("Error fetching alarm calls", { type: "error" });
      return fetchStatus;
    }

    const dataToReturn = fetchData;
    return dataToReturn;
  }, [fetchData, fetchError, fetchStatus, alert]);


  const [alarmAudioId, setAlarmAudioId] = useState<number | undefined>();

  const getAlarmAudio = async (key, alarmAudioId: number) => {
    /**
     * There must be a id to do this
     */
    if (!alarmAudioId) {
      return;
    }

    const response = await LiftStatusBaseApi.get({
      uri: `/liftstatus/alarm/${alarmAudioId}`,
      responseType: 'arraybuffer',
    });

    const data = response?.data;

    // to be able to play the audio in the html5 audio tag we need to make it a blob and create an url
    // this url can then be set as src on the audio tag
    //
    const blob = new Blob([data], { type: response.headers['content-type'] });
    const url = window.URL.createObjectURL(blob);

    return url;
  }

  const { status: getAlarmAudioStatus, data: getAlarmAudioData, error: getAlarmAudioError } = useQuery(
    ["getAlarmAudio", alarmAudioId],
    getAlarmAudio,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const GetAlarmAudio = React.useCallback(() => {
    if (getAlarmAudioStatus === "loading") {
      return getAlarmAudioStatus;
    }

    if (getAlarmAudioStatus === "error") {

      alert.show("Error fetching alarm audio", { type: "error" });
      return getAlarmAudioStatus;
    }

    const dataToReturn = getAlarmAudioData;
    return dataToReturn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAlarmAudioData, getAlarmAudioError, getAlarmAudioStatus, alert]);

  return (
    <AlarmCallsContext.Provider
      value={{
        fetchResponse: QueryAlarmCalls(),
        fetch: setAlarmCallsQuery,
        getAlarmAudioResponse: GetAlarmAudio(),
        getAlaramAudio: setAlarmAudioId,
      }}
    >
      {children}
    </AlarmCallsContext.Provider>
  );
};

export default AlarmCallsProvider;
