import React, { createContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { LiftStatusBaseApi } from 'Api/Generic';

export interface ILiftsResponse {
  items: LiftStatus.Schemas.ILift[];
  pagination: {
    limit: string;
  };
  total: number;
}

export interface ILiftRequest {
  limit: number;
  offset: number;
  organisationId?: number;
  search?: string | undefined;
}

export interface IAdvancedLiftRequest {
  limit: number;
  offset: number;
  organisationId?: number;
  advancedSearch?: LiftStatus.Schemas.ILiftSearchRequestDto | undefined;
}

interface ILiftsProviderProps {
  children: any;
}

export const LiftsContext = createContext(null);

const LiftsProvider = ({ children }: ILiftsProviderProps) => {
  const alert = useAlert();
  const [textSearch, setTextSearch] = useState<ILiftRequest | undefined>();
  const [advancedSearch, setAdvancedSearch] = useState<IAdvancedLiftRequest | undefined>();

  // Fetch lifts by text query
  //
  const fetchLiftsByQuery = async (key, liftQuery: ILiftRequest) => {
    /**
     * There must be a query to do this
     */
    if (!liftQuery) {
      return;
    }

    const urlParameters: ILiftRequest = {
      limit: liftQuery.limit,
      offset: liftQuery.offset,
    };

    if (liftQuery.organisationId) {
      urlParameters.organisationId = liftQuery.organisationId;
    }

    if (liftQuery.search) {
      urlParameters.search = liftQuery.search;
    }

    const response = await LiftStatusBaseApi.get({
      uri: '/liftstatus/lift',
      urlParameters,
    });

    const data: ILiftsResponse = response?.data;
    return data;
  };

  // Fetch lifts by advanced query
  //
  const fetchLiftsByAdvancedQuery = async (key, liftQuery: IAdvancedLiftRequest) => {
      /**
     * There must be a query to do this
     */
    if (isEmpty(liftQuery)) {
      return;
    }

    const urlParameters: IAdvancedLiftRequest = {
      limit: liftQuery.limit,
      offset: liftQuery.offset,
    };

    // Make sure there is an advanced search object to modify
    //
    if (isEmpty(liftQuery.advancedSearch)) {
      liftQuery.advancedSearch = {};
    }

    const response = await LiftStatusBaseApi.post({
      uri: '/liftstatus/lift',
      urlParameters,
      payload: liftQuery.advancedSearch,
    });

    const data: ILiftsResponse = response?.data;

    return data;
  }

  // Setup query for text search
  //
  const {
    status: textSearchStatus,
    data: textSearchData,
    error: textSearchError
  } = useQuery(
    ["lifts", textSearch],
    fetchLiftsByQuery,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // Setup query for advanced search
  //
  const {
    status: advancedSearchStatus,
    data: advancedSearchData,
    error: advancedSearchError
  } = useQuery(
    ["lifts", advancedSearch],
    fetchLiftsByAdvancedQuery,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // Map text search results to list of lifts
  //
  const TextQueryLifts = React.useCallback(() => {
    if (textSearchStatus === "loading") {
      return textSearchStatus;
    }

    if (textSearchStatus === "error") {
      console.error('[LIFTS:TEXT-SEARCH]:', textSearchError);
     // alert.show("Error fetching lifts", { type: "error" });
      return textSearchStatus;
    }

    const dataToReturn = textSearchData;
    return dataToReturn;
  }, [textSearchData, textSearchError, textSearchStatus]);

  // Map advanced search results to list of lifts
  //
  const AdvancedQueryLifts = React.useCallback(() => {
    if (advancedSearchStatus === "loading") {
      return advancedSearchStatus;
    }

    if (advancedSearchStatus === "error") {
      console.error('[LIFTS:ADVANCED-SEARCH]:', advancedSearchError);
      alert.show("Error fetching lifts", { type: "error" });
      return advancedSearchStatus;
    }

    const dataToReturn = advancedSearchData;
    return dataToReturn;
  }, [advancedSearchData, advancedSearchError, advancedSearchStatus, alert]);

  return (
    <LiftsContext.Provider
      value={{
        textSearchResults: TextQueryLifts(),
        advancedSearchResults: AdvancedQueryLifts(),
        textSearch: setTextSearch,
        advancedSearch: setAdvancedSearch,
      }}
    >
      {children}
    </LiftsContext.Provider>
  );
};

export default LiftsProvider;
