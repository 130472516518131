import { first, get, isEmpty, keys, values } from "lodash";
import {
  ISenseSensorUpdate,
  useSenseModule,
} from "Providers/SenseModuleProvider";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { translateSensorId } from "Utils/Helpers";
import classes from "./LiftDetailsSettings.module.scss";
interface ILiftDetailsSettingsProps {
  liftData: LiftStatus.Schemas.ILift;
}
interface IElevatorValue {
  value: string | number | null;
  timestamp: number;
}

const LiftDetailsSettings = ({ liftData }: ILiftDetailsSettingsProps) => {
  const senseModuleProvider = useSenseModule();

  const [movement, setMovement] = React.useState<IElevatorValue>();
  const [
    currentStopPlace,
    setCurrentStopPlace,
  ] = React.useState<IElevatorValue>();
  const [
    currentStopPlaceOpen,
    setCurrentStopPlaceOpen,
  ] = React.useState<IElevatorValue>();
  const [lastStopPlace, setLastStopPlace] = useState<IElevatorValue>();
  const [frontDoor, setFrontDoor] = useState<IElevatorValue>();
  const [backDoor, setBackDoor] = useState<IElevatorValue>();
  const [systemInfo, setSystemInfo] = useState<IElevatorValue>();
  const [healtySystem, setHealtySystem] = useState<IElevatorValue>();
  const [movements] = useState<ISenseSensorUpdate | undefined>();

  const processSensorUpdate = React.useCallback(
    (sensorId: string, value: string | number | null, timestamp?: number) => {
      const ts = timestamp ? timestamp : +new Date();

      switch (sensorId) {
        // Beweging
        //
        case "500":
          if (!movement || ts > movement.timestamp) {
            setMovement({ value, timestamp: ts });
          }
          break;

        // Current stop place (open)
        //
        case "501":
          if (!currentStopPlaceOpen || ts > currentStopPlaceOpen.timestamp) {
            setCurrentStopPlaceOpen({ value, timestamp: ts });
          }
          break;

        // Current stop place
        //
        case "515":
          if (!currentStopPlace || ts > currentStopPlace.timestamp) {
            setCurrentStopPlace({ value, timestamp: ts });
          }
          break;

        // Last passed stop place
        //
        case "514":
          if (!lastStopPlace || ts > lastStopPlace.timestamp) {
            setLastStopPlace({ value, timestamp: ts });
          }
          break;

        // Position frontdoor
        //
        case "502":
          if (!frontDoor || ts > frontDoor.timestamp) {
            setFrontDoor({ value, timestamp: ts });
          }
          break;

        // Position backdoor
        //
        case "503":
          if (!backDoor || ts > backDoor.timestamp) {
            setBackDoor({ value, timestamp: ts });
          }
          break;

        // System Info
        //
        case "590":
          if (!systemInfo || ts > systemInfo.timestamp) {
            setSystemInfo({ value, timestamp: ts });
          }
          break;

        // System
        //
        case "524":
          if (!healtySystem || ts > healtySystem.timestamp) {
            setHealtySystem({ value, timestamp: ts });
          }
          break;
      }
    },
    [
      backDoor,
      currentStopPlace,
      currentStopPlaceOpen,
      frontDoor,
      healtySystem,
      lastStopPlace,
      movement,
      systemInfo,
    ]
  );

  // Initial sensor values
  //
  React.useEffect(() => {
    const values = get(senseModuleProvider, "senseModuleSensorData.values");
    const updates = get(senseModuleProvider, "senseModuleSensorData.updated");

    if (values) {
      for (const sensorId in values) {
        processSensorUpdate(`${sensorId}`, values[sensorId], updates[sensorId]);
      }
    }
  }, [
    senseModuleProvider.senseModuleSensorData,
    processSensorUpdate,
    senseModuleProvider,
  ]);

  // Process movement update sensor events
  //
  React.useEffect(() => {
    const update = get(movements, "message.data");
    const ts = get(movements, "message.ts", +new Date());
    const key = first(keys(update));
    const value = first(values(update));
    processSensorUpdate(key, value, ts);
  }, [movements, processSensorUpdate]);

  return (
    <div className={classes.liftDetailsSettingsWrapper}>
      <div className={classes.leftColumn}>
        <h3>Rittenteller gebaseerd onderhoud</h3>
        <p>
          Actieve rittenteller:{" "}
          <span>{liftData.active_servicecounter || "-"}</span>
        </p>
        <p>
          Onderhoudinterval (ritten):{" "}
          <span>{liftData.servicecounter_initvalue}</span>
        </p>
        <p>
          Rittenteller vrijgegeven:{" "}
          <span>
            {liftData?.runcounter_data_released ? (
              <FormattedMessage id="yes" />
            ) : (
              <FormattedMessage id="no" />
            )}
          </span>
        </p>
        <h3>Afwijkende periodieke test</h3>
        <p>
          Interval: <span>{liftData.periodic_test_exception_threshold}</span>
        </p>
        <p>
          Actief:{" "}
          <span>
            {liftData.periodic_test_exception_active ? (
              <FormattedMessage id="yes" />
            ) : (
              <FormattedMessage id="no" />
            )}
          </span>
        </p>
        <h3>Afwijkende drempelwaarde events</h3>
        <p>
          Afwijkende drempel event rittenteller EDNL:{" "}
          <span>{liftData.event_thd_exception_ednl_runcounter || "-"}</span>
        </p>
        <p>
          Alarmoproepen per week:{" "}
          <span>{liftData.event_thd_exception_alarmcalls_weekly || "-"}</span>
        </p>
      </div>

      <div className={classes.rightColumn}>
        {!isEmpty(liftData.sense_link) ? (
          <>
            <h3>Sense koppeling</h3>
            {(liftData.sense_link as any)
              ?.filter((link) => link.linked)
              .map((link) => {
                return (
                  <span key={`${link.es_number}-${link.link_count}`}>
                    <p>
                      Gekoppelde Sense module: <span>{link.es_number}</span>
                    </p>
                    <p>
                      Lift Flow vrijgegeven:{" "}
                      <span>
                        {link.connected_controller_released ? (
                          <FormattedMessage id="yes" />
                        ) : (
                          <FormattedMessage id="no" />
                        )}
                      </span>
                    </p>
                  </span>
                );
              })}

            <span className={classes.sensor}>
                <p>
                  {translateSensorId(590) ? translateSensorId(590) : ""}:
                  <span>

                  {systemInfo?.value ?? "-"}
                  </span>
                </p>
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LiftDetailsSettings;
