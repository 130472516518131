import React, { useState, useEffect, useContext } from "react";
import classes from "./PasswordRecovery.module.scss";
import {
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import logo from "../../Assets/Images/ednl-logo.png";
import { useAlert } from "react-alert";
import { Button, FormControl, Input, InputLabel } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import PasswordResetProvider, { PasswordResetContext } from "Providers/PasswordResetProvider";

const PasswordRecovery = () => {
  const alert = useAlert();
  const [passwordState, setPasswordState] = useState<string>();
  const [passwordVerfState, setPasswordVerfState] = useState<string>("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const {token} = useParams();

  const passwordResetProvider = useContext(PasswordResetContext);

  const history = useHistory();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    name === "password" ? setPasswordState(value) : setPasswordVerfState(value);
  };

  const handleSubmit = async ( event ) => {

    event.preventDefault();

    try {
      await passwordResetProvider.setPassword(token, passwordState);

      alert.show(
        "Wachtwoord succesvol gewijzigd, er kan nu ingelogd worden met het nieuwe wachtwoord",
        {
          type: "success",
          position: "top left",
          timeout: 5000
      });

      history.replace('/login');

    } catch ( e ) {
      console.error('Error setting password using token', e);

      let msg = "Er is een fout opgetreden bij het wijzigen van het wachtwoord";

      switch ( e?.code ) {
        case 110:
          msg = 'Het gebruikte token is ongeldig'
          break;
        case 111:
          msg = 'Het token is ingetrokken'
          break;
        case 112:
          msg = 'Het token is al gebruikt'
          break;
        case 113:
          msg = 'Het token is verlopen en niet meer geldig'
          break;
      }

      alert.show(msg, {
        type: "error",
        position: "top center",
        timeout: 5000
      });
    }
  };

  // Updates on each change of inputs to verify and act
  // we do it here rather then on change to keep things sync since setState is async
  //
  useEffect(() => {
    if (passwordState === passwordVerfState) setPasswordsMatch(true);
    else setPasswordsMatch(false);
  }, [passwordState, passwordVerfState])

  // Can use CircularProgress for api loading indication
  //
  return (
    <div className={classes.recovery}>
      <Card className={classes.card}>
        <img src={logo} alt="ednl logo" />
        <CardHeader title={<FormattedMessage id="choose.new.password" />} />
        <CardContent>
          <form onSubmit={handleSubmit} className={classes.inputsWrapper}>
            <FormControl className={classes.input}>
              <InputLabel>
                <FormattedMessage id="password" />
              </InputLabel>
              <Input onChange={handleChange} name="password" type="password" />
              {
                passwordState && passwordState.length < 8 && (
                  <span className={classes.inputInvalid}>
                    <FormattedMessage id="password.not.long.enough" />
                  </span>
                )
              }
            </FormControl>

            <FormControl className={classes.input} style={{ marginTop: 26 }}>
              <InputLabel>
                <FormattedMessage id="repeat.password" />
              </InputLabel>

              <Input
                onChange={handleChange}
                name="passwordVerf"
                type="password"
                disabled={!passwordState}
              />
            </FormControl>

            <Button
              variant="outlined"
              color="primary"
              type="submit"
              disabled={!passwordsMatch || passwordState.length < 8}
              className={classes.button}
            >
              <FormattedMessage id="set.new.password" />
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const PasswordRecoveryWrapper = () => {
  return (
    <PasswordResetProvider>
      <PasswordRecovery />
    </PasswordResetProvider>
  );
}

export default PasswordRecoveryWrapper;
