import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorComponent = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
      <h3 style={{color: "#F3776D"}}>
          <FormattedMessage id="error.retrieving" />
      </h3>
    </div>
  );
};


export default ErrorComponent;