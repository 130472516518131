import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useStatusCodeContext } from "Providers/StatusCodeProvider";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import statusCodesJson from "../../Assets/status-codes.json";
import classes from "./ElevatorHistoryDetails.module.scss";

interface IElevatorHistoryDetailsProps {
  from: any;
  to: any;
}

function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.paginationButtonContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ElevatorHistoryDetails = React.memo(
  ({ from, to }: IElevatorHistoryDetailsProps) => {
    const statusCodeProvider = useStatusCodeContext();
    const [allFalse, setAllFalse] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [checkMarkState, setCheckMarkState] = useState({
      showImpact: true,
      showNonImpact: false,
      showUnknown: false,
      showInformational: false,
      showClosed: true,
    });
    const [levelsArray, setLevelsArray] = useState(['impact']);
    const [totalStatuses, setTotalStatuses] = useState(0);
    const [error, setError] = useState(false);

    // This handles every change of a check mark
    // We handle some logic here to populate the checkmark state
    // and the levels array for the request to the API
    //
    const handleCheckMarkChange = (event) => {
      setCheckMarkState({
        ...checkMarkState,
        [event.target.name]: event.target.checked,
      });

      switch (event.target.name) {
        case "showImpact":
          // code block
          if (event.target.checked) {
            setLevelsArray([...levelsArray, "impact"]);
          } else {
            setLevelsArray(levelsArray.filter((i) => i !== "impact"));
          }
          break;
        case "showNonImpact":
          if (event.target.checked) {
            setLevelsArray([...levelsArray, "non-impact"]); // code block
          } else {
            setLevelsArray(levelsArray.filter((i) => i !== "non-impact"));
          }
          break;
        case "showInformational":
          if (event.target.checked) {
            setLevelsArray([...levelsArray, "informational"]); // code block
          } else {
            setLevelsArray(levelsArray.filter((i) => i !== "informational"));
          }
          break;
        case "showUnknown":
          if (event.target.checked) {
            setLevelsArray([...levelsArray, ""]); // code block
          } else {
            setLevelsArray(levelsArray.filter((i) => i !== ""));
          }
          break;
        default:
          break;
      }
    };


    // Fetch status codes
    //
    const fetchStatusCode = React.useCallback(() => {
      setError(false);
      setLoading(true);
      if (!isEmpty(statusCodeProvider) && !isEmpty(levelsArray)) {
        statusCodeProvider
          .fetchStatusCodes({
            from: moment(from),
            to: moment(to),
            levels: levelsArray,
            onlyOpen: !checkMarkState.showClosed, // if show closed true only open is false
            limit: 1000,
            skip: 0,
          })
          .then(async (data: any) => {
            if (data) {
              // We are naming the status codes with a json we have locally for now
              // hence this loop
              //
              const newStatusObj = data?.items.map((status) => {
                const statusCodeDataFromJson =
                  statusCodesJson[status.status_code];
                const statusCode = {
                  ...status,
                  description: statusCodeDataFromJson?.description,
                  level: statusCodeDataFromJson?.level,
                };

                return statusCode;
              });

              setTotalStatuses(data.total);
              setStatuses(newStatusObj);
              setLoading(false);
            }
          })
          .catch((e) => {
            console.error(e);
            setError(true);
            setLoading(false);
          })
      } else {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [levelsArray, from, to, checkMarkState.showClosed]);

    useEffect(() => {
      const allFalse = Object.values(checkMarkState).filter(
        (state) => state === true
      );

      if ((allFalse.length === 1 && checkMarkState.showClosed === true) || allFalse.length <= 0) {
        setLevelsArray([]);
        setStatuses(null);
        setAllFalse(true);
      } else {
        setAllFalse(false);
      }

    }, [checkMarkState]);

    // Each status has a different color
    const handleDynamicColorStyle = (row: any) => {
      return {
        color:
          row.level === "impact"
            ? "#fff"
            : row.level === "non-impact"
              ? "#fff"
              : "",
        borderColor:
          row.level === "impact"
            ? "#fff"
            : row.level === "non-impact"
              ? "#fff"
              : "",
        backgroundColor:
          row.level === "impact"
            ? "#ec6c40"
            : row.level === "non-impact"
              ? "#F3C46D"
              : "",
      };
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    // This will fetch the status codes on specific dependencies
    useEffect(() => {
      fetchStatusCode();
    }, [from, to, fetchStatusCode, checkMarkState]);

    let key = 0;
    const rowsPerPage = 10;

    return (
      <div className={classes.root}>
        <React.Fragment>
          <FormControl>
            <p className={classes.checkMarkLabel}>Status levels</p>
            <FormGroup className={classes.checkMarks}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkMarkState.showImpact}
                    onChange={handleCheckMarkChange}
                    name="showImpact"
                    color="primary"
                  />
                }
                label="Met impact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checkMarkState.showNonImpact}
                    onChange={handleCheckMarkChange}
                    name="showNonImpact"
                  />
                }
                label="Zonder impact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checkMarkState.showUnknown}
                    onChange={handleCheckMarkChange}
                    name="showUnknown"
                  />
                }
                label="Onbekende impact"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checkMarkState.showInformational}
                    onChange={handleCheckMarkChange}
                    name="showInformational"
                  />
                }
                label="Informatie status"
              />
            </FormGroup>
          </FormControl>

          <p className={classes.checkMarkLabel}>Additionele filters</p>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checkMarkState.showClosed}
                onChange={handleCheckMarkChange}
                name="showClosed"
              />
            }
            label="Inclusief afgesloten statussen"
          />
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress color="secondary" />
            </div>
          ) : isEmpty(statuses) ? (
            <>
              <h2 style={{ color: "#F3776D" }}>
                {allFalse ? (
                  <>Selecteer tenminste één status level</>
                ) : (
                  <>
                    {error ? (<><FormattedMessage id="error.retrieving" /></>) : ((
                      <>Geen status events gevonden</>
                    ))}
                  </>
                )
                }
              </h2>
            </>
          ) : (
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>Einde</TableCell>
                    <TableCell>Duur</TableCell>
                    <TableCell>Level</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? statuses.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : statuses
                  ).map((row) => (
                    <TableRow className={classes.tableRow} key={key++}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={handleDynamicColorStyle(row)}
                        className={classes.firstCell}
                      >
                        <span
                          className={classes.description}
                          style={handleDynamicColorStyle(row)}
                        >
                          {row?.description || row?.status_code}
                        </span>
                      </TableCell>
                      <TableCell style={handleDynamicColorStyle(row)}>
                        {moment(row?.start_ts).format("DD MMM YY HH:mm")}
                      </TableCell>
                      <TableCell style={handleDynamicColorStyle(row)}>
                        {row?.end_ts
                          ? moment(row?.end_ts).format("DD MMM YY HH:mm")
                          : "Actief"}
                      </TableCell>
                      <TableCell style={handleDynamicColorStyle(row)}>
                        {
                          <>
                            {row?.end_ts
                              ? moment
                                .duration(
                                  moment(row.end_ts).diff(row.start_ts)
                                )
                                .humanize()
                              : moment
                                .duration(moment().diff(row?.start_ts))
                                .humanize()}
                          </>
                        }
                      </TableCell>
                      <TableCell style={handleDynamicColorStyle(row)}>
                        {row?.level}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={totalStatuses}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )
          }
        </React.Fragment>
      </div>
    );
  }
);

export default ElevatorHistoryDetails;
