import React from "react";
import classes from "./LiftDetailsInstallation.module.scss";

interface ILiftDetailsInstallationProps {
  liftData: LiftStatus.Schemas.ILift;
}

const LiftDetailsInstallation = ({
  liftData,
}: ILiftDetailsInstallationProps) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.leftColumn}>
        <p>
          Dossiernummer keurende instantie:{" "}
          <span>
            {liftData.lift_installation?.approving_authority_dossiernr || "-"}
          </span>
        </p>
        <p>
          Keuring volgens:{" "}
          <span>{liftData.lift_installation?.approval_standard || "-"}</span>
        </p>
        <p>
          Fabrieks-installatienummer:{" "}
          <span>
            {liftData.lift_installation?.factory_installationnr || "-"}
          </span>
        </p>
        <p>
          Jaar van ingebruikname:{" "}
          <span>
            {/*liftData.lift_installation?.comissioning_year wrong type*/ "-"}
          </span>
        </p>
        <p>
          Soort lift:{" "}
          <span>{liftData.lift_installation?.lift_type || "-"}</span>
        </p>
        <p>
          Brandweerlift:{" "}
          <span>
            {liftData.lift_installation?.firebrigade_lift !== undefined
              ? liftData.lift_installation?.firebrigade_lift
                ? "Ja"
                : "Nee"
              : "-"}
          </span>
        </p>
        <p>
          Nominale last in kg:{" "}
          <span>{liftData.lift_installation?.rated_load || "-"}</span>
        </p>
        <p>
          Aantal personen:{" "}
          <span>{liftData.lift_installation?.number_of_persons || "-"}</span>
        </p>
        <p>
          Nuttig vloeroppervlak m2:{" "}
          <span>{liftData.lift_installation?.floor_area || "-"}</span>
        </p>
        <p>
          Nominale snelheid m/s:{" "}
          <span>{liftData.lift_installation?.rated_speed || "-"}</span>
        </p>
      </div>

      <div className={classes.rightColumn}>
        <p>
          Nivelleer/-nastelsnelheid in m/s:{" "}
          <span>{liftData.lift_installation?.levelling_speed || "-"}</span>
        </p>
        <p>
          Snelheidsregeling type:{" "}
          <span>{liftData.lift_installation?.speedcontrol_type || "-"}</span>
        </p>
        <p>
          Plaats van aandrijving:{" "}
          <span>{liftData.lift_installation?.driving_position || "-"}</span>
        </p>
        <p>
          Hefhoogte in m:{" "}
          <span>{liftData.lift_installation?.lifting_height || "-"}</span>
        </p>
        <p>
          Aantal stopplaatsen:{" "}
          <span>{liftData.lift_installation?.stops || "-"}</span>
        </p>
        <p>
          Aantal schachttoegangen:{" "}
          <span>{liftData.lift_installation?.shaft_accesses || "-"}</span>
        </p>
        <p>
          Aantal kooitoegangen:{" "}
          <span>{liftData.lift_installation?.cage_accesses || "-"}</span>
        </p>
        <p>
          Soort besturing:{" "}
          <span>{liftData.lift_installation?.control_type || "-"}</span>
        </p>
        <p>
          Overzichtstekening nummer:{" "}
          <span>{liftData.lift_installation?.overview_plan_nr || "-"}</span>
        </p>
        <p>
          Installatieschema nummer:{" "}
          <span>
            {liftData.lift_installation?.installation_schema_nr || "-"}
          </span>
        </p>
        <p>
          Werkingsschema nummer:{" "}
          <span>
            {liftData.lift_installation?.functioning_schema_nr || "-"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default LiftDetailsInstallation;
