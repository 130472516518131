import { CircularProgress, makeStyles } from "@material-ui/core";
import BarChart from "Components/BarChart/BarChart";
import { useLiftCounterData } from "Providers/ElevatorMaintenanceServices/LiftCounterDataProvider";
import { useLiftCounterRunData } from "Providers/ElevatorMaintenanceServices/LiftRunCounterProvider";
import { LiftDetailsContext } from "Providers/LiftDetailsProvider";
import { LiftStatusStorage } from "Utils/Storage";
import { isArray, isNumber, round } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cssClasses from "./ElevatorStatisticsGraph.module.scss";
export interface IGraphDataEntry {
  value: number;
  label: string;
  partial: boolean;
}

const useStyles = makeStyles(() => ({
  graphContainer: {
    display: "flex",
    flexDirection: "row",
  },
  graphItem: {
    display: "inline-block",
    marginLeft: "42px",
  },
  graphLegend: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 15px",
  },
  graphLegendItem: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSize: "0.7rem",
    "& strong": {
      flex: 1,
    },
  },
}));

/**
 * Show barchart with weekly service counter statistics
 */
const ElevatorStatisticsGraph = ({
  liftId,
  from,
  to,
  onlyDaily,
  loading,
  width,
}: {
  liftId: number;
  from: string;
  to: string;
  onlyDaily?: boolean;
  loading?: boolean;
  width?: number;
}) => {
  const liftCounterData = useLiftCounterData();
  const liftCounterRunData = useLiftCounterRunData();
  const intl = useIntl();
  const [weeklyGraphData, setWeeklyGraphData] = React.useState<
    IGraphDataEntry[]
  >([]);
  const [yearlyGraphData, setYearlyGraphData] = React.useState<
    IGraphDataEntry[]
  >([]);
  const [dailyGraphData, setDailyGraphData] = React.useState<IGraphDataEntry[]>(
    []
  );
  const [expectedCurrentYearRuncounter, setExpectedCurrentYearRuncounter] =
    React.useState<number>();
  const [avgWeekRuncounter, setAvgWeekRuncounter] = useState<number>();
  const liftDetailsProvider = React.useContext(LiftDetailsContext);
  const [refetchInterval, setRefetchInterval] = useState(null);
  const classes = useStyles();

  // Grab expectedCurrentYearRuncounter
  //
  React.useEffect(() => {
    if (
      liftDetailsProvider?.response?.expected_current_year_runcounter !==
      expectedCurrentYearRuncounter
    ) {
      setExpectedCurrentYearRuncounter(
        liftDetailsProvider.response.expected_current_year_runcounter
      );
    }
  }, [liftDetailsProvider, expectedCurrentYearRuncounter]);

  // Grab avgWeekRuncounter
  //
  React.useEffect(() => {
    if (
      liftDetailsProvider?.response?.avg_week_runcounter !== avgWeekRuncounter
    ) {
      setAvgWeekRuncounter(liftDetailsProvider.response.avg_week_runcounter);
    }
  }, [liftDetailsProvider, avgWeekRuncounter]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRefetchInterval(true);
    }, 900000);

    // on destory
    return () => {
      setRefetchInterval(false);
      clearInterval(interval);
    };
  }, []);

  // Fetch data initially
  //
  React.useEffect(() => {
    liftCounterRunData
      .dispatchLiftCounterRunDataRequest({
        liftId,
        from: moment(new Date()).subtract(1, "days"),
      })
      .then((data) => {
        if (data && isArray(data)) {
          setDailyGraphData(
            data
              .filter((item) => isNumber(item.value))
              .map((data) => ({
                label: `${moment(data.value_at).format("HH:mm")}`,
                value: data.value ? data.value.toFixed(0) : 0,
                partial: data.partial,
              }))
          );
        }
      });

    liftCounterData
      .dispatchLiftCounterDataRequest({
        liftId,
        from: moment(from).startOf("year"),
        to: moment(to).add(1, "years").startOf("year"),
        type: "weekly",
      })
      .then((data) => {
        // Here we are adding fake data sets to the data array to be able
        // to display the labels of a whole year. If the current year
        // has no future data we want to create at least the labels
        // https://trello.com/c/DJOJsa5I
        //

        let weekNumber = 0;
        const weeksInYear = moment().weeksInYear();

        const yearlyWeeks = [];

        while (weekNumber < weeksInYear) {
          const weekFound = data.find(d => moment(d.value_at).week() === moment().week(weekNumber).week())

          const week = moment().week(weekNumber).format();

          if (weekNumber === weeksInYear) {
            break;
          }

          if (weekFound) {
            yearlyWeeks.push(weekFound);
          } else {
            yearlyWeeks.push({
              counter: 0,
              partial: false,
              value: null,
              value_at: week,
            });
          }
          weekNumber += 1;
        }


        if (data && isArray(data)) {

          const weeklyData = yearlyWeeks.map((data) => ({
            label: `${moment(data.value_at)
              .startOf("week")
              .format("DD MMM YY")} - ${moment
                .utc(data.value_at)
                .endOf("week")
                .format("DD MMM YY")}`,
            value: isNumber(data.counter) ? round(data.counter) : 0,
            partial: data.partial,
          }));

          // Set weekly data and right padd with 0 for all 52 weeks of the year
          //
          setWeeklyGraphData(weeklyData);
        }
      });

    liftCounterData
      .dispatchLiftCounterDataRequest({
        liftId,
        type: "yearly",
        from: "1",
        to: "2100-01-01", // everything... (api defaults to 'end of day' when not provided)
      })
      .then((data) => {
        const curYear = moment().year();
        if (data && isArray(data)) {
          setYearlyGraphData(
            data
              .filter((item) => isNumber(item.counter))
              .map((data) => {
                const year = moment.utc(data.value_at).year() - 1;
                return {
                  label: year,
                  value: isNumber(data.counter) ? data.counter.toFixed(0) : 0,
                  partial: data.partial && curYear !== year,
                };
              })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LiftStatusStorage.startDate, refetchInterval]);

  return (
    <React.Fragment>
      {loading ? (
        <div className={cssClasses.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div
          style={{
            width: width ? width + 6 : "100%",
            minHeight: "225px",
            marginTop: "16px",
          }}
        >
          {dailyGraphData.length > 0 && onlyDaily === true ? (
            <BarChart
              data={{
                labels: dailyGraphData.map((d) => d.label),
                datasets: [
                  {
                    label: intl.formatMessage({
                      id: "service.counter.daily",
                    }),
                    data: dailyGraphData.map((d) => d.value),
                    backgroundColor: dailyGraphData.map(() => "#86b29c"),
                    type: "bar",
                  },
                ],
              }}
              legend={{
                display: true,
              }}
            />
          ) : (
            <></>
          )}

          {weeklyGraphData.length > 0 && !onlyDaily ? (
            <BarChart
              data={{
                labels: weeklyGraphData.map((d) => d.label),
                datasets: [
                  {
                    label: intl.formatMessage({ id: "service.counter.weekly" }),
                    data: weeklyGraphData.map((d) => d.value),
                    backgroundColor: weeklyGraphData.map(() => "#86b29c"),
                    type: "bar",
                  },
                  {
                    label: intl.formatMessage({ id: "average_this_year" }),
                    data: weeklyGraphData.map(() => avgWeekRuncounter),
                    type: "line",
                    borderColor: "#929eaa",
                  },
                ],
              }}
              legend={{
                display: true,
              }}
            />
          ) : (
            <></>
          )}

          {yearlyGraphData.length > 0 && !onlyDaily ? (
            <div className={classes.graphContainer} style={{ marginTop: 30 }}>
              <div className={classes.graphItem} style={{ width: "70%" }}>
                <BarChart
                  barWidthPercentage={0.4}
                  data={{
                    labels: [
                      ...yearlyGraphData.map((entry) =>
                        entry.partial
                          ? `${entry.label} (${intl.formatMessage({
                            id: "estimate",
                          })})`
                          : entry.label
                      ),
                      `${intl.formatMessage({
                        id: "service.counter.estimate",
                      })} ${new Date().getFullYear()}`,
                    ],
                    datasets: [
                      {
                        label: intl.formatMessage({
                          id: "service.counter.yearly",
                        }),
                        data: [
                          ...yearlyGraphData.map((d) => d.value),
                          expectedCurrentYearRuncounter,
                          0,
                        ],
                        backgroundColor: [
                          ...yearlyGraphData.map((entry) =>
                            entry.partial ? "#929eaa" : "#86b29c"
                          ),
                          "var(--liftstatus-grey)",
                        ],
                      },
                    ],
                  }}
                  legend={{
                    display: true,
                  }}
                />
              </div>
              <div className={classes.graphLegend} style={{ width: "30%" }}>
                {yearlyGraphData.map((entry, index) => (
                  <div key={index} className={classes.graphLegendItem}>
                    <strong>
                      {entry.label}
                      {entry.partial && (
                        <>
                          &nbsp;(
                          <FormattedMessage id="estimate" />)
                        </>
                      )}
                      :
                    </strong>
                    <span>{entry.value}</span>
                  </div>
                ))}
                <div className={classes.graphLegendItem}>
                  <strong>
                    <FormattedMessage id="service.counter.estimate" />{" "}
                    {new Date().getFullYear()}:
                  </strong>
                  <span>
                    {expectedCurrentYearRuncounter}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ElevatorStatisticsGraph;
