import React, { ReactElement } from "react";
import { Card, CardContent, Button, Tooltip, CircularProgress } from "@material-ui/core";
import classes from "./SmallCard.module.scss";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


export interface ISmallCardProps {
  title: string;
  tooltip?: string;
  data: number | string | ReactElement;
  percent?: boolean;
  color?: string;
  hideDetailsNavigation?: boolean;
  showDetails?: Function;
  loading?: boolean;
}

const SmallCard = (props: ISmallCardProps) => {
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {props.loading ? (
          <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
            <React.Fragment>
              <div className={classes.title}>
                <p className={classes.titleText}>{props.title ? props.title : "No title"}</p>

                {
                  props.tooltip && (
                    <Tooltip className={classes.tooltip} title={props.tooltip} arrow>
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  )
                }
              </div>
              <h1 style={{ color: props.color ? props.color : "#3E7158" }}>
                {props.data}
                {props.percent ? "%" : null}
              </h1>
              { !props.hideDetailsNavigation &&
                <Button onClick={() => props.showDetails && props.showDetails()} className={classes.btn}>
                  BEKIJKEN <ArrowForwardIcon />
                </Button>
              }
            </React.Fragment>
          )}

      </CardContent>
    </Card>
  );
};

export default SmallCard;
