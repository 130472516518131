import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import { GlobalStateContext, IGlobalState } from "Providers/GlobalStateProvider";
import {
  useSenseModule
} from "Providers/SenseModuleProvider";
import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import classes from "./ElevatorCommands.module.scss";

interface IElevatorCommandsProps {
  expanded: boolean;
  onChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  defaultExpanded: boolean;
  expandIcon: any;
}

const ElevatorCommands = ({ expanded, onChange, defaultExpanded, expandIcon }: IElevatorCommandsProps) => {
  const [globalState] = useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const senseModuleProvider = useSenseModule();
  const [commands, setCommands] = useState(null);
  const alert = useAlert();

  useEffect(() => {
    if (senseModuleProvider?.senseModule) {
      const capability = senseModuleProvider.senseModule.capability;

      setCommands(globalState?.userOrganisationType?.match(/^(admin|main)$/) ? capability?.commands : {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.senseModule, globalState.userOrganisationType]);

  const executeCommand = async (command) => {

    try {
      const response = await senseModuleProvider.sendCommand({ command });

      console.log('[COMMANDS] Command result', response);

      alert.show('Commando verzoek is verstuurd', { type: 'success' });
    } catch (error) {
      console.error('[COMMANDS] Problem executing command', error);

      alert.show('Problem sending command', { type: 'error' });
    }
  };

  return (
    <>
      {senseModuleProvider?.loading || !senseModuleProvider?.socketConnected ? (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) :
        ((isNil(commands) || isEmpty(commands)) ? (<></>) : (
            <Accordion
              expanded={expanded}
              onChange={onChange}
              defaultExpanded={defaultExpanded}


            >
              <AccordionSummary expandIcon={expandIcon}>
                <h4 className={classes.column}>Acties</h4>
                <div className={classes.column}>
                  <h4 className={classes.subHeading}>
                    Installatie acties
                  </h4>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {commands.reset_contact && (
                  <>
                    <p className={classes.command}>
                      <h3>Reset de liftbesturing door het tijdelijk onderbreken van de voeding</h3>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth={true}
                        onClick={() => window.confirm('Weet u het zeker dat er een reset uitgevoerd moet worden op deze installatie?') && executeCommand('reset_contact_when_idle')}
                      >
                        <FormattedMessage id="command.reset" />
                      </Button>
                    </p>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
        ))
      }
    </>
  );
};

export default ElevatorCommands;
