import { Checkbox, FormControlLabel } from "@material-ui/core";
import {
  IDateRange
} from "Components/PeriodSelector/PeriodSelector";
import { isEmpty, isFunction } from "lodash";
import { EventSearchDataContext } from "Providers/EventDataProvider";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./EventsFilters.module.scss";


interface IEventsFilterCheckboxState {
  [key: string]: boolean;
}

export interface IEventsFiltersState {
  eventKey: string[];
  dateRange: IDateRange;
  openOnly?: boolean;
}

interface IEventsFiltersProps {
  /** Whenever the filters change, this will be called */
  onChange?: (change: IEventsFiltersState) => any;
  /** This will be used once the component is mounted to set its initial value. */
  currentState?: IEventsFiltersState;
}

const checkboxKeys = [
  "P-LIFT-EDNL-ST_BATTERY",
  "P-LIFT-EDNL-ST_LIFTDRIVE",
  "P-LIFT-EDNL-ST_EXTERNAL",
  "P-LIFT-SENSE-SENSORDATA-FAILURE_SENSOR",
  "P-LIFT-EDNL-ST_INSERVICE",
  "P-LIFT-EDNL-ST_MAINSSTATUS",
  "P-LIFT-EDNL-ST_BATTERYDEFECT",
  "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY",
  "P-LIFT-SERVICECOUNTER_MAINTENANCE_WARNING",
  "P-LIFT-SERVICECOUNTER_MAINTENANCE_ALERT",
  "S-LIFT-SERVICECOUNTER_MAINTENANCE_RESET",
  "P-LIFT-APPROVAL_REJECTED",
  "P-LIFT-APPROVAL_EXPIRED",
  "P-LIFT-SENSE-VISIT",
  "S-LIFT-ALARMCALL",
  "P-LIFT-EDNL-ST_ALARMSTATE",
  "S-LIFT-ALARMCALL_NOTANSWERED",
  "S-THD-INTERVAL-LIFT-EDNL-RUNCOUNTER",
  "P-THD-LIFT-RUNCOUNTER_WEEKLY",
  "P-LIFT-UNAVAILABLE",
  "P-THD-LIFT-ALARMCALLS_HOURLY",
  "P-THD-LIFT-ALARMCALLS_WEEKLY",
  "P-THD-LIFT-SMS-SMS_WEEKLY",
  "S-LIFT-GENERIC-ALARMCALL_IDENTIFICATION_FAILURE",
  "S-LIFT-EDNL-ALARMCALL_IDENTIFICATION_FAILURE",
  "S-LIFT-GENERIC-DATACALL_IDENTIFICATION_FAILURE",
  "S-LIFT-EDNL-DATACALL_IDENTIFICATION_FAILURE",
];



/** Some keys will be shown under one checkbox, the key in this object will represent  */
const combinedCheckboxKeys = [
  {
    label: "event.P-DATACALLS-PER-WEEK",
    keys: [
      "P-THD-LIFT-EDNL-DATACALLS_WEEKLY",
      "P-THD-LIFT-GENERIC-DATACALLS_WEEKLY",
    ],
  },
  {
    label: "event.S-LIFT-GENERIC-DATACALL",
    keys: [
      "S-LIFT-GENERIC-DATACALL",
      "S-LIFT-EDNL-DATACALL",
      "S-LIFT-SMS-DATACALL",
    ],
  },
  {
    label: "event.P-LIFT-EDNL-ST_PERIODIC",
    keys: [
      "P-LIFT-EDNL-ST_PERIODIC",
      "P-LIFT-GENERIC-ST_PERIODIC",
      "P-LIFT-SMS-ST_PERIODIC",
    ],
  },
  {
    label: "event.S-LIFT-LOG",
    keys: [
      "S-LIFT-LOG",
      "S-LIFT-LOG-OTHER",
      "S-LIFT-LOG-VISIT_PLANNED",
      "S-LIFT-LOG-VISIT_DONE",
    ],
  },
];


const EventsFiltersInternal = (props: IEventsFiltersProps) => {
  const eventDataProvider = useContext(EventSearchDataContext);
  const intl = useIntl();
  const currentEventFiltersState =
    LiftStatusStorage.eventFilters?.eventKey.reduce(
      (obj, arr) => ({ ...obj, [arr]: true }),
      {}
    ) ?? {};
  const [
    eventsCheckboxesState,
    setEventsCheckboxesState,
  ] = useState<IEventsFilterCheckboxState>(currentEventFiltersState);
  const [showClosedEventsState, setShowClosedEventsState] = useState<boolean>(
    !LiftStatusStorage.eventFilters?.openOnly
  );
  const [dateRangeState] = useState<IDateRange>({
    from: LiftStatusStorage.eventFilters?.dateRange?.from,
    to: LiftStatusStorage.eventFilters?.dateRange?.to,
  });
  const { onChange } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    const eventKey: string[] = Object.entries(eventsCheckboxesState)
      .filter(([_key, value]) => value === true)
      .map(([key]) => key);

    // We will communicate these changes with the parent
    //
    const eventsFilterState: IEventsFiltersState = {
      eventKey: eventKey,
      openOnly: !showClosedEventsState,
      dateRange: dateRangeState,
    };

    // This onChange will trigger parent state and react
    //
    if (isFunction(onChange)) {
      onChange(eventsFilterState);
    }

    LiftStatusStorage.eventFilters = eventsFilterState;

    setLoading(true);

    if (!eventKey.length) {
      setLoading(false);
    }

  }, [eventsCheckboxesState, showClosedEventsState, dateRangeState, onChange]);

  useEffect(() => {
    setLoading(eventDataProvider.response === "loading");
  }, [eventDataProvider.response]);

  const renderEventCheckboxes = () => {
    const normalCheckboxes = checkboxKeys.map((checkboxKey) => {
      return (
        <FormControlLabel
          className={classes.label}
          key={checkboxKey}
          control={
            <Checkbox
              checked={!!eventsCheckboxesState[checkboxKey]}
              disabled={loading}
              onChange={() => {
                setEventsCheckboxesState({
                  ...eventsCheckboxesState,
                  [checkboxKey]: !eventsCheckboxesState[checkboxKey],
                });
              }}
            />
          }
          label={intl.formatMessage({ id: `event.${checkboxKey}` })}
          labelPlacement="end"
          style={{ textAlign: "left" }}
        />
      );
    });

    const combinedCheckboxes = combinedCheckboxKeys.map(
      (combinedCheckboxKey) => {
        const matchingCheckboxState = Object.entries(
          eventsCheckboxesState
        ).filter(([key]) => combinedCheckboxKey.keys.includes(key));

        let checkboxState = false;

        // We will only do this if matchingCheckboxState is not empty, because running .every on empty arrays always returns true
        // see https://stackoverflow.com/questions/34137250/why-does-array-prototype-every-return-true-on-an-empty-array
        //
        if (!isEmpty(matchingCheckboxState)) {
          checkboxState = matchingCheckboxState.every(
            ([_key, value]) => value === true
          );
        }

        return (
          <FormControlLabel
            className={classes.label}
            style={{ textAlign: "left" }}
            key={combinedCheckboxKey.label}
            label={intl.formatMessage({ id: combinedCheckboxKey.label })}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={checkboxState}
                disabled={loading}
                onChange={() => {
                  const newCheckboxState: IEventsFilterCheckboxState = {};

                  combinedCheckboxKey.keys.forEach(
                    (key) => (newCheckboxState[key] = !checkboxState)
                  );

                  setEventsCheckboxesState({
                    ...eventsCheckboxesState,
                    ...newCheckboxState,
                  });
                }}
              />
            }
          ></FormControlLabel>
        );
      }
    );

    const checkboxes = [...normalCheckboxes, ...combinedCheckboxes];

    return checkboxes;
  };

  return (
    <div className={classes.root}>

      <FormControlLabel
        label={intl.formatMessage({ id: "event.filter.closed" })}
        control={
          <Checkbox
            checked={showClosedEventsState}
            onChange={() => setShowClosedEventsState((value) => !value)}
            disabled={loading}
          />
        }
      ></FormControlLabel>

      <h3>{intl.formatMessage({ id: "event.types" })}</h3>
      <div className={classes.eventCheckboxesContainer}>
        {renderEventCheckboxes()}
      </div>
    </div>
  );
};

const EventsFilters = (props: IEventsFiltersProps) => (
  <EventsFiltersInternal {...props} />
);

export default EventsFilters;
