import StatusIcon from "Components/StatusIcon/StatusIcon";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import classes from "./LiftDetailsEmergencyTelephoneSystem.module.scss";

interface ILiftDetailsEmergencyTelephoneSystemProps {
  liftData: LiftStatus.Schemas.ILift
}

const LiftDetailsEmergencyTelephoneSystem = ({ liftData }: ILiftDetailsEmergencyTelephoneSystemProps) => {

  // Formatting helpers
  //
  const formatBatteryLevel = (level: number): string => {
    let value = "-";

    if (level && !isNaN(level)) {
      value = `${(level / 100).toFixed(2)}v`;
    }

    return value;
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <h3>Nummers</h3>
        <p className={classes.divideText}>
          Telefoonnummer: <span>{liftData.phonenr ? `+${liftData.phonenr}` : "-"}</span>
        </p>
        <p className={classes.divideText}>
          Serienummer: <span>{liftData.number || "-"}</span>
        </p>
        <p className={classes.divideText}>
          Status type: <span>{liftData.type || "-"}</span>
        </p>
        <h3>Extra eerste alarm nummer</h3>
        <p className={classes.divideText}>
          Telefoonnummer:{" "}
          <span>
            {liftData.dialplan_exception_phone_number || "-"}
          </span>
        </p>
        <p className={classes.divideText}>
          Oproepduur:{" "}
          <span>
            {liftData.dialplan_exception_dial_time || "-"}
          </span>
        </p>
        <p className={classes.divideText}>
          Actief:{" "}
          <span>
            {liftData?.dialplan_exception_active ? (
              <FormattedMessage id="yes" />
            ) : (
                <FormattedMessage id="no" />
              )}
          </span>
        </p>

        {liftData.type === "edcard" && (
          <React.Fragment>
            <h3>Status</h3>
            <p className={classes.divideText}>
              Periodieke test:{" "}
              <StatusIcon status={liftData.state_edcard?.st_periodic as any} />
            </p>
            <p className={classes.divideText}>
              Accu:{" "}
              <StatusIcon status={liftData.state_edcard?.st_battery as any} />
            </p>
            <p className={classes.divideText}>
              Accu defect:{" "}
              <StatusIcon
                status={liftData.state_edcard?.batterydefect as any}
              />
            </p>
            <p className={classes.divideText}>
              Voeding:{" "}
              <StatusIcon status={liftData.state_edcard?.mainsstatus as any} />
            </p>
            <p className={classes.divideText}>
              Alarm status:{" "}
              <StatusIcon type="on_off" status={liftData.state_edcard?.st_alarmstate as any} />
            </p>
            {
              liftData.state_edcard?.versionassist > 6 && (
                <>
                  <p className={classes.divideText}>
                    Audio test:{" "}
                    <StatusIcon type="on_off" status={liftData.state_edcard?.audiotestfail as any} />
                  </p>
                  <p className={classes.divideText}>
                    Button test:{" "}
                    <StatusIcon type="on_off" status={liftData.state_edcard?.buttontestfail as any} />
                  </p>
                </>
              )
            }
            <p className={classes.divideText}>
              Accuspanning:{" "}
              <span>
                {formatBatteryLevel(liftData.state_edcard?.battery_level)}
              </span>
            </p>
            <p className={classes.divideText}>
              Variant spreekluistersysteem:{" "}
              <span className={classes.ucfirst}>{liftData.state_edcard?.variant}</span>
            </p>
            <p className={classes.divideText}>
              Versie spreekluistersysteem:{" "}
              <span>{liftData.state_edcard?.versionassist}</span>
            </p>
            <p className={classes.divideText}>
              Laatste contact:{" "}
              <span>{liftData.state_edcard?.last_contact ? moment(liftData.state_edcard?.last_contact).format("DD-MM-YYYY HH:mm") : ''}</span>
            </p>
          </React.Fragment>
        )}

        {liftData.type === "generic" && (
          <React.Fragment>
            <h3>Status</h3>
            <p>
              Periodieke test:{" "}
              <StatusIcon status={liftData.state_generic?.st_periodic as any} />
            </p>
            <p className={classes.divideText}>
              Laatste contact:{" "}
              <span>{liftData.state_generic?.last_contact ? moment(liftData.state_generic?.last_contact).format("DD-MM-YYYY HH:mm") : ''}</span>
            </p>
          </React.Fragment>
        )}

        {liftData.type === "sms" && (
          <React.Fragment>
            <h3>Status</h3>
            <p>
              Periodieke test:{" "}
              <StatusIcon status={liftData.state_sms?.st_periodic as any} />
            </p>
            <p className={classes.divideText}>
              Laatste contact:{" "}
              <span>{liftData.state_sms?.last_contact ? moment(liftData.state_sms?.last_contact).format("DD-MM-YYYY HH:mm") : ''}</span>
            </p>
          </React.Fragment>
        )}
      </div>

      <div className={classes.rightColumn}>
        {liftData.type === "edcard" ? (
          <React.Fragment>
            <h3>Geprogrammeerde alarm nummers</h3>
            {
              // 1 - 5 are voice / alarm numbers
              [1, 2, 3, 4, 5].map((nr) => {
                return (
                  <p key={nr}>
                    {nr}:{" "}
                    <span>
                      {liftData.state_edcard[`phonenr_${nr}`]}
                    </span>
                  </p>
                );
              })
            }
            <h3>Geprogrammeerde data nummers</h3>
            {
              // Position 6 are data numbers (7 is not in use anymore)
              [6].map((nr) => {
                return (
                  <p key={nr}>
                    {nr}:{" "}
                    <span>
                      {liftData.state_edcard[`phonenr_${nr}`]}
                    </span>
                  </p>
                );
              })
            }
            <h3>Module nummer</h3>
            <p>{liftData.state_edcard?.modulenr}</p>
          </React.Fragment>
        ) : (
            <></>
          )}
      </div>
    </div>
  )
}

export default LiftDetailsEmergencyTelephoneSystem;