import {
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import React, { useContext } from "react";
import classes from "./LiftDetailsOwnerOrganisations.module.scss";

interface ILiftDetailsOwnerOrganisationsProps {
  liftData: LiftStatus.Schemas.ILift;
}

const LiftDetailsOwnerOrganisations = ({
  liftData,
}: ILiftDetailsOwnerOrganisationsProps) => {
  const [globalState] = useContext<[IGlobalState, React.Dispatch<any>]>(
    GlobalStateContext
  );

  const getOrganisationName = (organisationId: number) => {
    if (organisationId) {
      const allOrganisations = globalState.organisationList;
      const searchResult = allOrganisations.find(
        (ou) => ou.id === organisationId
      );
      if (searchResult) {
        return searchResult.name;
      }
    }

    return organisationId;
  };

  return (
    <div className={classes.organisationsWrapper}>

        <p>
          Eigenaar: <span>{getOrganisationName(liftData.organisation_id)}</span>
        </p>
        <p>
          Beheerder:{" "}
          <span>
            {getOrganisationName(liftData.administrator_organisation_id)}
          </span>
        </p>
        <p>
          Adviseur:{" "}
          <span>{getOrganisationName(liftData.advice_organisation_id)}</span>
        </p>
        <p>
          Alarmafhandelaar:{" "}
          <span>{getOrganisationName(liftData.alarm_organisation_id)}</span>
        </p>
        <p>
          Contract:{" "}
          <span>
            {getOrganisationName(liftData.maintenancelift_organisation_id)}
          </span>
        </p>
        <p>
          Verantwoordelijke spreekluister:{" "}
          <span>
            {getOrganisationName(liftData.maintenanceintercom_organisation_id)}
          </span>
        </p>
        <p>
          Verantwoordelijke verbindin spreekluister:{" "}
          <span>
            {getOrganisationName(
              liftData.responsibleconnection_organisation_id
            )}
          </span>
        </p>
        <p>
          Verantwoordelijke monitoring:{" "}
          <span>
            {getOrganisationName(
              liftData.monitoring_organisation_id
            )}
          </span>
        </p>
        <p>
          Verantwoordelijke verbinding monitoring:{" "}
          <span>
            {getOrganisationName(
              liftData.responsibleconnectionmonitoring_organisation_id
            )}
          </span>
        </p>
    </div>
  );
};

export default LiftDetailsOwnerOrganisations;
