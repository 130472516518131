import React, { useContext, useEffect, useState } from "react";
import classes from "./Visit.module.scss";
import { useIntl } from "react-intl";
import moment from "moment";
import { isArray, find } from 'lodash';

import VisitPayload from '../../Utils/VisitPayload';
import { SenseConfigurationContext } from "Providers/SenseConfigurationProvider";

interface VisitProps {
  event?: LiftStatus.Schemas.IEvent
}

const Visit = (props: VisitProps) => {
  const [ visitPayload, setVisitPayload ] = useState( null );
  const [ senseLabelConfiguration, setSenseLabelConfiguration ] = useState(null);
  const intl = useIntl();
  const senseConfigurationProvider = useContext(SenseConfigurationContext);

  const payload = new VisitPayload();

  useEffect(() => {
    if ( !props.event ) {
      return;
    }

    payload.setPayload(props.event.payload);

    setVisitPayload(payload);

    senseConfigurationProvider.fetch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.event ] );

  useEffect(() => {
    if (
      !senseConfigurationProvider.results ||
      senseConfigurationProvider.results === 'loading' ||
      senseConfigurationProvider.results === 'error' ||
      !visitPayload
    ) {
      // Not there yet
      return;
    }

    const labelConfiguration = find(senseConfigurationProvider.results.items, { type: 'label', version: visitPayload.labelConfigurationVersion } );

    setSenseLabelConfiguration(labelConfiguration?.content_json?.Message?.Labels?.Set);

  }, [ senseConfigurationProvider.results, visitPayload ] );

  // Get the key from the label configuration of the Sense visit
  //
  const getLabelForKey = (key:string) => {
    if (!senseLabelConfiguration) {
      return key;
    }

    const lang = 'nl';

    const set = find(senseLabelConfiguration, { '@attributes': { lang }});
    const label = find(set?.Label, { '@attributes': { key }});

    return label?.SummaryTitle || label?.Title || key;
  }

  // Get the label of the Sense Visit question tree
  //
  const getLabelForAnswer = (question:string, answer:string) => {
    // The Q-.*-Activity pattern is the EDNL standard, but we need to support some ELC/DLR specific 'legacy' question trees too
    // Those are matched using their explicit question keys
    //
    if ( question.match(/^Q-(.*-Activity|ApprovalDone|InspectionDone|Mechanic-Failure|Mechanic-Maintenance)$/ )) {
      return moment.duration(answer, 'seconds').humanize();
    }

    if (answer?.startsWith('A-')) {
      return getLabelForKey(answer);
    }

    return answer;
  }

  return (
    <div className={classes.cardContainer}>
      <h4 className={classes.visitReason}>{`Bezoek: ${visitPayload?.visitReason} - Rol: ${visitPayload?.role && intl.formatMessage({ id: `visit.role.${visitPayload.role.toLowerCase()}`})}`}</h4>

      <p>
        Gestart op:{" "}
        <span>
          {moment(props.event.created_at).format('DD-MM-YYYY HH:mm')}
        </span>
      </p>
      <p>
        Beëindigd op:{" "}
        <span>
          {props.event.closed_at ? moment(props.event.closed_at).format('DD-MM-YYYY HH:mm') : 'Nog niet afgesloten'}
        </span>
      </p>
      <p>
        Status:{" "}
        <span>
          {visitPayload?.state && intl.formatMessage({ id: `visit.state.${visitPayload.state}`})}
        </span>
      </p>

      <hr />

      {isArray(visitPayload?.questions) ?
        visitPayload?.questions?.filter( ( question ) => {
          if ( question.QuestionKey.startsWith('ROLE-')) {
            return false;
          }
          return true;
        }).map( ( question: any, index: number) => {
          return (
            <p key={`question-${index}`}>
              {getLabelForKey(question.QuestionKey)}:{" "}
              <span>
                {getLabelForAnswer(question.QuestionKey, question.Answer)}
              </span>
            </p>
          );
        })
      : '' }

    </div>
  );
};

export default Visit;
