import React from "react";
import classes from "./StatusIcon.module.scss";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
export interface IStatusIconProps {
  title?: string;
  type?: 'status' | 'on_off' | undefined;
  status: 'U' | 'D' | null | undefined;
}

const StatusIcon = (props: IStatusIconProps) => {
  return (
      <span className={classes.root}>{
        props.type === 'on_off' ? (
          props.status === 'D' ? (<RadioButtonUncheckedIcon className={classes.statusOff}/>) : props.status === 'U' ? (
            <RadioButtonCheckedIcon className={classes.statusOn}/>
          ) : (
            <HelpOutlineIcon />
          )
        ) : (
          props.status === 'D' ? (<CheckCircleIcon className={classes.statusOk}/>) : props.status === 'U' ? (
            <CancelIcon className={classes.statusNotOk}/>
          ) : (
            <HelpOutlineIcon />
          )
        )
      }</span>
  );
};

export default StatusIcon;
