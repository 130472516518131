import { first, get, isNil, isNumber, keys, values } from 'lodash';
import { useSenseModule } from "Providers/SenseModuleProvider";
import React from "react";
import { FormattedMessage } from "react-intl";
import classes from "./LastStopIndicator.module.scss";

const LastStopIndicator = ( { className }: { className?: string } ) => {
  const [lastFloor, setLastFloor] = React.useState<null | number>(null);
  const senseModuleProvider = useSenseModule();

  const parseSensorPoint = React.useCallback((data: any) => {

    const key   = parseInt(first(keys(data)), 10);
    const value = parseInt(first(values(data)), 10);

    if (key === 514) {
      if (!isNaN(value)) {
        setLastFloor(value);
      } else {
        setLastFloor(null);
      }
    }
  }, []);

  // When ever the socket emits data handle the sensor data update
  //
  React.useEffect(() => {
    parseSensorPoint(get(senseModuleProvider.movementPoints, "message.data"));
  }, [senseModuleProvider.movementPoints, parseSensorPoint]);

  // Initial sensor values
  //
  React.useEffect(() => {
    const value = get(senseModuleProvider, 'senseModuleSensorData.values.514');
    if (isNumber(value)) {
      setLastFloor(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.senseModuleSensorData]);

  return (
    <div className={`${classes.container} ${isNil(className) ? '' : className}`}>
      <strong className={classes.label}><FormattedMessage id="elevator.last.stop" /></strong>
      <div className={`${classes.floorIndicator} ${classes.floorCurrentBackground}`}>88</div>
      <div className={`${classes.floorIndicator} ${classes.floorCurrentForeground}`}>{isNil(lastFloor) ? '' : (lastFloor + 1)}</div>
    </div>
  );
}

export default LastStopIndicator;