import classes from "./SmallCardsGrid.module.scss";
import React, { useContext } from "react";
import SmallCard from "Components/SmallCard/SmallCard";
import {
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface ISmallCardsGridProps {
  loading: boolean;
  liftStats: any;
  availabilityLiftRef?: any;
  liftDetailsPage: boolean;
  liftData?: LiftStatus.Schemas.ILift;
  viewIsCurrent?: boolean;
  liftFlowAvailable?: boolean;
  periodicalCounterData?: any;
}

const SmallCardsGrid = ({
  loading,
  liftStats,
  availabilityLiftRef,
  liftDetailsPage,
  liftData,
  viewIsCurrent,
  liftFlowAvailable,
  periodicalCounterData,
}: ISmallCardsGridProps) => {
  const [globalState] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const history = useHistory();
  const numberFormat = new Intl.NumberFormat(globalState.locale, {
    maximumFractionDigits: 2,
  });

  return (
    <React.Fragment>
      {!liftDetailsPage ? (
        /** DASHBOARD PAGE */
        <div className={classes.wrapper}>
          {viewIsCurrent ? (
            <>
              { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                <>
                <div className={classes.threeRow}>
                  <SmallCard
                    loading={loading}
                    data={numberFormat.format(liftStats?.liftUnavailable)}
                    title={"Liften onbeschikbaar"}
                    tooltip={"Het aantal op dit moment onbeschikbare liften"}
                    percent={false}
                    color={liftStats?.liftUnavailable > 0 ? "#F3776D" : "#86B29C"}
                    showDetails={() =>
                      history.push(`/stats-details/liftUnavailable`)
                    }
                  />

                  <SmallCard
                    loading={loading}
                    data={numberFormat.format(liftStats?.senseFailureSensor)}
                    title={"Technisch onbeschikbaar"}
                    tooltip={
                      "Het aantal op dit moment onbeschikbare liften vanwege een technische storing"
                    }
                    percent={false}
                    color={
                      liftStats?.senseFailureSensor > 0 ? "#F3776D" : "#86B29C"
                    }
                    showDetails={() =>
                      history.push(`/stats-details/senseFailureSensor`)
                    }
                  />

                  <SmallCard
                    loading={loading}
                    data={numberFormat.format(liftStats?.visits)}
                    title={"Bezoeken"}
                    tooltip={
                      "Het aantal liften waar op dit moment een bezoek gaande is"
                    }
                    percent={false}
                    color={liftStats?.visits > 0 ? "#F3776D" : "#86B29C"}
                    showDetails={() => history.push(`/stats-details/visits`)}
                  />
                </div>
                </>
              ) : (
                <></>
              )}

              <div className={classes.fourRow}>
                { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                  <>
                    <SmallCard
                      loading={loading}
                      data={numberFormat.format(
                        liftStats?.servicecounterMaintenanceAlert
                      )}
                      title={"Liften onderhoud onvoldoende"}
                      tooltip={
                        "Het aantal liften waarop op dit moment onderhoud uitgevoerd had moeten zijn"
                      }
                      percent={false}
                      color={
                        liftStats?.servicecounterMaintenanceAlert > 0
                          ? "#F3776D"
                          : "#86B29C"
                      }
                      showDetails={() =>
                        history.push(
                          `/stats-details/servicecounterMaintenanceAlert`
                        )
                      }
                    />
                    <SmallCard
                      loading={loading}
                      data={numberFormat.format(liftStats?.approvalRejected)}
                      title={"Liften afgekeurd"}
                      tooltip={
                        "Het aantal liften dat op dit moment afgekeurd is"
                      }
                      percent={false}
                      color={
                        liftStats?.approvalRejected > 0 ? "#F3776D" : "#86B29C"
                      }
                      showDetails={() =>
                        history.push(`/stats-details/approvalRejected`)
                      }
                    />
                    <SmallCard
                      loading={loading}
                      data={numberFormat.format(liftStats?.approvalExpired)}
                      title={"Liften verlopen keuring"}
                      tooltip={
                        "Het aantal liften waarvan op dit moment de keuring verlopen is"
                      }
                      percent={false}
                      color={
                        liftStats?.approvalExpired > 0 ? "#F3776D" : "#86B29C"
                      }
                      showDetails={() =>
                        history.push(`/stats-details/approvalExpired`)
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className={classes.twoRow}>
                <SmallCard
                  loading={loading}
                  data={numberFormat.format(liftStats?.intercomUnavailable)}
                  title={"Spreekluister onbeschikbaar"}
                  tooltip={
                    "Het aantal spreekluistersystemen waarvan de periodieke test op dit moment gefaald is"
                  }
                  percent={false}
                  color={
                    liftStats?.intercomUnavailable > 0 ? "#F3776D" : "#86B29C"
                  }
                  showDetails={() =>
                    history.push(`/stats-details/intercomUnavailable`)
                  }
                />

                <SmallCard
                  loading={loading}
                  data={numberFormat.format(liftStats?.senseNotConnected)}
                  title={"Sense module geen verbinding"}
                  tooltip={
                    "Het aantal liften waarvan de Sense module niet volledig verbonden is"
                  }
                  percent={false}
                  color={
                    liftStats?.senseNotConnected > 0 ? "#F3776D" : "#86B29C"
                  }
                  showDetails={() =>
                    history.push(`/stats-details/senseNotConnected`)
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.fourRow}>
                <SmallCard
                  loading={loading}
                  data={numberFormat.format(
                    liftStats?.liftAvailability?.percentage
                  )}
                  title={"Beschikbaarheid liften"}
                  tooltip={"De totale beschikbaarheid van de lift installaties"}
                  percent={true}
                  color={
                    liftStats?.liftAvailability?.percentage < 98
                      ? "#F3776D"
                      : liftStats?.liftAvailability?.percentage > 98 &&
                        liftStats?.liftAvailability?.percentage < 99
                      ? "#F3C46D"
                      : "#86B29C"
                  }
                  showDetails={() => {
                    if (availabilityLiftRef?.current !== undefined) {
                      availabilityLiftRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                />

                <SmallCard
                  loading={loading}
                  data={numberFormat.format(
                    liftStats?.liftAvailabilityPrimaryPeriod?.percentage
                  )}
                  title={"Beschikbaarheid - overdag"}
                  tooltip={
                    "De beschikbaarheid van de lift installaties tussen 7:00 en 19:00"
                  }
                  percent={true}
                  color={
                    liftStats?.liftAvailabilityPrimaryPeriod?.percentage < 98
                      ? "#F3776D"
                      : liftStats?.liftAvailabilityPrimaryPeriod?.percentage >
                          98 &&
                        liftStats?.liftAvailabilityPrimaryPeriod?.percentage <
                          99
                      ? "#F3C46D"
                      : "#86B29C"
                  }
                  showDetails={() => {
                    if (availabilityLiftRef?.current !== undefined) {
                      availabilityLiftRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                />

                <SmallCard
                  loading={loading}
                  data={numberFormat.format(
                    liftStats?.intercomAvailability?.percentage
                  )}
                  title={"Beschikbaarheid spreekluister"}
                  tooltip={"De beschikbaarheid van de spreekluistersystemen"}
                  percent={true}
                  color={
                    liftStats?.intercomAvailability?.percentage < 98
                      ? "#F3776D"
                      : liftStats?.intercomAvailability?.percentage > 98 &&
                        liftStats?.intercomAvailability?.percentage < 99
                      ? "#F3C46D"
                      : "#86B29C"
                  }
                  showDetails={() => {
                    if (availabilityLiftRef?.current !== undefined) {
                      availabilityLiftRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                />
              </div>

              <div className={classes.twoRow}>
                <SmallCard
                  loading={loading}
                  data={liftStats?.approvalRejected?.count}
                  title={"Aantal afkeuringen"}
                  tooltip={
                    "Aantal aaneengesloten periodes van afkeuringen gedurende deze periode inclusief afkeuringen uit de vorige periode die nog liepen tijdens de periode"
                  }
                  color={
                    liftStats?.approvalRejected?.count > 0 ? "#F3776D" : null
                  }
                  showDetails={() => {
                    history.push(`/stats-details/approvalRejected`);
                  }}
                />

                <SmallCard
                  loading={loading}
                  data={liftStats?.approvalExpired?.lift_count}
                  title={"Verlopen liftkeuringen"}
                  tooltip={"Het aantal unieke liften met een verlopen keuring"}
                  color={
                    liftStats?.approvalExpired?.count > 0 ? "#F3776D" : null
                  }
                  showDetails={() => {
                    history.push(`/stats-details/approvalExpired`);
                  }}
                />
              </div>

              <div className={classes.fourRow}>
                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceBeforeInterval?.count}
                  title={"Onderhoudsbezoek eerder"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is voor het bereiken van het rittenteller gebaseerde onderhoudsinterval"
                  }
                  color={"#F3C46D"}
                  showDetails={() => {
                    history.push(`/stats-details/maintenanceBeforeInterval`);
                  }}
                />
                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceOnInterval?.count}
                  title={"Onderhoudsbezoek op tijd"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is rond het rittenteller gebaseerde onderhoudsinterval"
                  }
                  color={"#86B29C"}
                  showDetails={() => {
                    history.push(`/stats-details/maintenanceOnInterval`);
                  }}
                />
                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceAfterInterval?.count}
                  title={"Onderhoudsbezoek later"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is later dan bereiken van het rittenteller gebaseerde onderhoudsinterval"
                  }
                  color={"#F3776D"}
                  showDetails={() => {
                    history.push(`/stats-details/maintenanceAfterInterval`);
                  }}
                />
                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceExpired?.lift_count}
                  title={"Interval overschreden"}
                  tooltip={
                    "Het aantal unieke liften waarbij het rittenteller gebaseerd onderhoudsinterval is overschreden"
                  }
                  color={"#F3776D"}
                  showDetails={() => {
                    history.push(`/stats-details/maintenanceExpired`);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        /** LIFTDETAILS PAGE **/
        <div className={classes.wrapper}>
          {viewIsCurrent ? (
            <>
              { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                <div className={classes.threeRow}>
                  <SmallCard
                    loading={loading}
                    data={liftStats?.liftUnavailable > 0 ? "Ja" : "Nee"}
                    title={"Lift onbeschikbaar?"}
                    tooltip={"Is deze lift op dit moment onbeschikbaar?"}
                    percent={false}
                    color={liftStats?.liftUnavailable > 0 ? "#F3776D" : "#86B29C"}
                    hideDetailsNavigation={true}
                  />

                  <SmallCard
                    loading={loading}
                    data={liftStats?.senseFailureSensor > 0 ? "Ja" : "Nee"}
                    title={"Technisch onbeschikbaar?"}
                    tooltip={
                      "Is deze lift op dit moment onbeschikbaar vanwege een technische storing?"
                    }
                    percent={false}
                    color={
                      liftStats?.senseFailureSensor > 0 ? "#F3776D" : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />

                  <SmallCard
                    loading={loading}
                    data={liftStats?.visits > 0 ? "Ja" : "Nee"}
                    title={"Bezoek?"}
                    tooltip={"Is er op dit moment een bezoek gaande?"}
                    percent={false}
                    color={liftStats?.visits > 0 ? "#F3776D" : "#86B29C"}
                    hideDetailsNavigation={true}
                  />
                </div>
              ) : (
                <></>
              )}

              { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                <div className={classes.threeRow}>
                  <SmallCard
                    loading={loading}
                    data={
                      liftStats?.servicecounterMaintenanceAlert > 0
                        ? "Ja"
                        : "Nee"
                    }
                    title={"Onderhoud onvoldoende?"}
                    tooltip={
                      "Heeft deze lift op dit moment periodiek onderhoud nodig?"
                    }
                    percent={false}
                    color={
                      liftStats?.servicecounterMaintenanceAlert > 0
                        ? "#F3776D"
                        : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />

                  <SmallCard
                    loading={loading}
                    data={liftStats?.approvalRejected > 0 ? "Ja" : "Nee"}
                    title={"Lift afgekeurd?"}
                    tooltip={"Is de lift op dit moment afgekeurd?"}
                    percent={false}
                    color={
                      liftStats?.approvalRejected > 0 ? "#F3776D" : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />

                  <SmallCard
                    loading={loading}
                    data={liftStats?.approvalExpired > 0 ? "Ja" : "Nee"}
                    title={"Verlopen keuring?"}
                    tooltip={
                      "Is de keuring van de lift op dit moment verlopen?"
                    }
                    percent={false}
                    color={
                      liftStats?.approvalExpired > 0 ? "#F3776D" : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className={classes.twoRow}>
                <SmallCard
                  loading={loading}
                  data={liftStats?.intercomUnavailable > 0 ? "Ja" : "Nee"}
                  title={"Spreekluister onbeschikbaar?"}
                  tooltip={
                    "Is het spreekluistersysteem op dit moment beschikbaar?"
                  }
                  percent={false}
                  color={
                    liftStats?.intercomUnavailable > 0 ? "#F3776D" : "#86B29C"
                  }
                  hideDetailsNavigation={true}
                />

                {liftFlowAvailable ? (
                  <SmallCard
                    loading={loading}
                    data={liftStats?.senseNotConnected > 0 ? "Ja" : "Nee"}
                    title={"Sensemodule geen verbinding?"}
                    tooltip={
                      "Is de Sense module van deze installatie niet volledig verbonden?"
                    }
                    percent={false}
                    color={
                      liftStats?.senseNotConnected > 0 ? "#F3776D" : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <>
              <div className={classes.fourRow}>
                <SmallCard
                  data={numberFormat.format(
                    liftStats?.liftAvailability?.percentage
                  )}
                  title={"Beschikbaarheid"}
                  loading={loading}
                  tooltip={"De totale beschikbaarheid van de lift installatie"}
                  percent={true}
                  color={
                    liftStats?.liftAvailability?.percentage < 98
                      ? "#F3776D"
                      : liftStats?.liftAvailability?.percentage > 98 &&
                        liftStats?.liftAvailability?.percentage < 99
                      ? "#F3C46D"
                      : "#86B29C"
                  }
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={numberFormat.format(
                    liftStats?.liftAvailabilityPrimaryPeriod?.percentage
                  )}
                  title={"Beschikbaarheid - overdag"}
                  tooltip={
                    "De beschikbaarheid van de lift installaties tussen 7:00 en 19:00"
                  }
                  percent={true}
                  hideDetailsNavigation={true}
                  color={
                    liftStats?.liftAvailabilityPrimaryPeriod?.percentage < 98
                      ? "#F3776D"
                      : liftStats?.liftAvailabilityPrimaryPeriod?.percentage >
                          98 &&
                        liftStats?.liftAvailabilityPrimaryPeriod?.percentage <
                          99
                      ? "#F3C46D"
                      : "#86B29C"
                  }
                />

                {liftData?.type ? (
                  <SmallCard
                    data={liftStats?.intercomAvailability?.percentage.toFixed(
                      2
                    )}
                    title={"Beschikbaarheid spreekluister"}
                    loading={loading}
                    tooltip={
                      "De totale beschikbaarheid van het spreekluistersystemen van de lift"
                    }
                    percent={true}
                    color={
                      liftStats?.intercomAvailability?.percentage < 98
                        ? "#F3776D"
                        : liftStats?.intercomAvailability?.percentage > 98 &&
                          liftStats?.intercomAvailability?.percentage < 99
                        ? "#F3C46D"
                        : "#86B29C"
                    }
                    hideDetailsNavigation={true}
                  />
                ) : (
                  () => null
                )}

                {periodicalCounterData ? (
                  <SmallCard
                    loading={loading}
                    data={
                      (periodicalCounterData.items[0]?.sum / 1000).toFixed(1) +
                      " kWh"
                    }
                    title={"Energie verbruik"}
                    tooltip={
                      "Totaal energie verbruik van installatie voor de geselecteerde periode"
                    }
                    percent={false}
                    hideDetailsNavigation={true}
                  />
                ) : (
                  () => null
                )}
              </div>

              <div className={classes.fourRow}>
                <SmallCard
                  loading={loading}
                  data={
                    liftStats?.approvalRejected?.count === 0 ? (
                      <FormattedMessage id="no" />
                    ) : (
                      <FormattedMessage id="yes" />
                    )
                  }
                  title={"Is afgekeurd geweest?"}
                  tooltip={
                    "Is de lift tijdens de geselecteerde periode afgekeurd (geweest)?"
                  }
                  color={
                    liftStats?.approvalRejected?.count === 0 ? "" : "#F3776D"
                  }
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={
                    liftStats?.approvalExpired?.lift_count === 0 ? (
                      <FormattedMessage id="no" />
                    ) : (
                      <FormattedMessage id="yes" />
                    )
                  }
                  title={"Keuring verlopen geweest?"}
                  tooltip={
                    "Is de lift installatie gedurende deze periode afgekeurd geweest"
                  }
                  color={
                    liftStats?.approvalExpired?.count === 0 ? "" : "#F3776D"
                  }
                  hideDetailsNavigation={true}
                />
              </div>

              <div className={classes.fiveRow}>
                <SmallCard
                  loading={loading}
                  data={
                    liftStats?.maintenanceBeforeInterval?.count +
                    liftStats?.maintenanceOnInterval?.count +
                    liftStats?.maintenanceAfterInterval?.count
                  }
                  title={"Totaal onderhoudsbezoeken"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is"
                  }
                  color={"#86B29C"}
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceBeforeInterval?.count}
                  title={"Onderhoudsbezoek eerder"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is voor het bereiken van het rittenteller gebaseerde onderhoudsinterval"
                  }
                  color={
                    liftStats?.maintenanceBeforeInterval?.count === 0
                      ? "#86B29C"
                      : "#F3C46D"
                  }
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceOnInterval?.count}
                  title={"Onderhoudsbezoek op tijd"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is rond het rittenteller gebasseerde onderhoudsinterval"
                  }
                  color={"#86B29C"}
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={liftStats?.maintenanceAfterInterval?.count}
                  title={"Onderhoudsbezoek later"}
                  tooltip={
                    "Het aantal uitgevoerde onderhoudsbezoeken wat uitgevoerd is later dan bereiken van het rittenteller gebasseerde onderhoudsinterval"
                  }
                  color={
                    liftStats?.maintenanceAfterInterval?.count === 0
                      ? "#86B29C"
                      : "#F3776D"
                  }
                  hideDetailsNavigation={true}
                />

                <SmallCard
                  loading={loading}
                  data={
                    liftStats?.maintenanceExpired?.lift_count === 0 ? (
                      <FormattedMessage id="no" />
                    ) : (
                      <FormattedMessage id="yes" />
                    )
                  }
                  title={"Liftonderhoud later"}
                  tooltip={
                    "Het totaal aantal aaneengesloten periodes binnen de geselecteerde periode waarbij het rittenteller gebasseerd onderhoudsinterval is overschreden"
                  }
                  color={
                    liftStats?.maintenanceExpired?.lift_count === 0
                      ? ""
                      : "#F3776D"
                  }
                  hideDetailsNavigation={true}
                />
              </div>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SmallCardsGrid;
