import ComparePeriods from 'Pages/ComparePeriods/ComparePeriods';
import Dashboard from 'Pages/Dashboard/Dashboard';
import OrganisationReport from 'Pages/OrganisationReport/OrganisationReport';
import StatsDetails from 'Pages/StatsDetails/StatsDetails';
import Lifts from 'Pages/Lifts/Lifts';
import Login from 'Pages/Login/Login';
import PasswordRecovery from 'Pages/PasswordRecovery/PasswordRecovery';
import React from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { Route, Switch} from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import AlarmCalls from 'Pages/AlarmCalls/AlarmCalls';
import EventsPage from 'Pages/EventsPage/EventsPage';
import { GlobalStateContext, IGlobalState } from 'Providers/GlobalStateProvider';
import CurrentCalls from 'Pages/CurrentCalls/CurrentCalls';

import SenseConfigurationProvider from "Providers/SenseConfigurationProvider";
import EventPage from 'Pages/EventPage/EventPage';
import Subscriptions from 'Pages/Subscriptions/Subscriptions';

const Routes = () => {
  const [globalState] = React.useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  moment.locale(globalState.locale);

  return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <Route path="/password-reset/:token" component={PasswordRecovery} />
        <PrivateRoutes>
          <SenseConfigurationProvider>
            <Route path="/compare-periods" component={ComparePeriods} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/organisation-report" component={OrganisationReport} />
            <Route path="/current-calls" component={CurrentCalls} />
            <Route path="/lifts/:liftId?" component={Lifts} />
            <Route path="/alarm-calls" component={AlarmCalls} />
            <Route path="/subscriptions" component={Subscriptions}/>
            <Route path="/stats-details/:type/:lower?/:upper?" component={StatsDetails} />
            <Route path="/events" component={EventsPage} exact={true} />
            <Route path="/events/:eventId" component={EventPage} exact={true}/>
          </SenseConfigurationProvider>
        </PrivateRoutes>
      </Switch>
  );
};

export default Routes;
