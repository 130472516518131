import React, { useState } from "react";
import merge from "deepmerge";
import { IntlProvider } from "react-intl";
import messages_en from "../Assets/i18n/en.json";
import messages_nl from "../Assets/i18n/nl.json";

export interface ITranslationProvider {
  children: React.ReactNode;
}
export interface ILocaleContext {
  locale: string;
  changeLocale: TChangeLocale;
}

type TChangeLocale = (localeInput?: string | undefined) => void;

// merge.all is used to make sure we fallback to en when keys are missing.
//
const messages = {
  "nl-NL": merge.all([messages_en, messages_nl]),
  "en-GB": messages_en,
  "en-US": messages_en,
};

const loadedLanguages = ["en-GB", "en-US", "nl-NL"];
/** Contexts */
export const LocaleContext = React.createContext({} as ILocaleContext);

export default function TranslationProvider({
  children,
}: ITranslationProvider) {
  const defaultLng = "nl-NL";
  /** Set Locale to browser default on start */
  const [locale, setLocale] = useState(defaultLng);

  // Defaults to nl-NL if window lang is not in list of loaded langs
  //
  if (loadedLanguages.indexOf(window.navigator.language) === -1) {
    // TODO: this will result in infinite loops for unknown locales.
    // For example, browser configured at en-NL results in an error from React because causing to many
    // renders as a result of below `setLocale()` call
    // setLocale(defaultLng);
  }

  const ChangeLocale = (localeInput?: string) => {
    if (localeInput) {
      setLocale(localeInput);
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, changeLocale: ChangeLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLng}
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
