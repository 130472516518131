import { IEventsFiltersState } from "Components/EventsFilters/EventsFilters";
import { ITimeSelection } from "Components/PeriodSelector/PeriodSelector";

export class LiftStatusStorage {
  static storeKeyliftstatusOrganisation = "lifstatus-organisation";
  static storeKeyUserToken = "liftstatus-user-token";
  static storageKeyStartDate = "liftstatus-start-date";
  static storeKeyEndDate = "liftstatus-end-date";
  static storeKeyPeriod = "liftstatus-period";
  static storeKeyEventFilters = "liftstatus-event-filters";
  static storeHistoryCheckboxes = "liftstatus-history-checkboxes";

  static get historyCheckBoxes(): string {
    return localStorage.getItem(this.storeHistoryCheckboxes);
  }

  static set historyCheckBoxes(obj: string) {
    localStorage.setItem(this.storeHistoryCheckboxes, obj);
  }

  static set lockedLift(lockedLift: string) {
    localStorage.setItem("locked-lift", lockedLift);
  }

  static removeLockedLift() {
    localStorage.removeItem("locked-lift");
  }

  static get lockedLiftId() {
    return localStorage.getItem("locked-lift");
  }

  static get organisationId(): number | null {
    const organisationId = localStorage.getItem(
      this.storeKeyliftstatusOrganisation
    );
    return !organisationId || organisationId === "null"
      ? null
      : parseInt(organisationId, 10);
  }

  static set organisationId(organisationId: number) {
    localStorage.setItem(
      this.storeKeyliftstatusOrganisation,
      `${organisationId}`
    );
  }

  static get userToken(): string {
    return localStorage.getItem(this.storeKeyUserToken);
  }

  static set userToken(token: string) {
    localStorage.setItem(this.storeKeyUserToken, token);
  }

  static get startDate(): string {
    return localStorage.getItem(this.storageKeyStartDate);
  }

  static set startDate(date: string) {
    localStorage.setItem(this.storageKeyStartDate, date);
  }

  static get endDate(): string {
    return localStorage.getItem(this.storeKeyEndDate);
  }

  static set endDate(date: string) {
    localStorage.setItem(this.storeKeyEndDate, date);
  }

  static get period(): ITimeSelection {
    const selection = localStorage.getItem(this.storeKeyPeriod);

    if (selection) {
      return JSON.parse(selection);
    } else {
      return null;
    }
  }

  static set period(selection: ITimeSelection) {
    if (selection) {
      localStorage.setItem(this.storeKeyPeriod, JSON.stringify(selection));
    }
  }

  static set eventFilters(newEventData: IEventsFiltersState) {
    try {
      localStorage.setItem(
        this.storeKeyEventFilters,
        JSON.stringify(newEventData)
      );
    } catch (error) {
      console.error(
        `[storage] error storing ${this.storeKeyEventFilters} in localStorage`,
        error
      );
    }
  }

  static get eventFilters(): IEventsFiltersState | null {
    try {
      const eventFiltersString = localStorage.getItem(
        this.storeKeyEventFilters
      );

      if (eventFiltersString) {
        const eventFilters = JSON.parse(eventFiltersString);

        return eventFilters;
      }
    } catch (error) {
      console.error(
        `[storage] error retrieving ${this.storeKeyEventFilters} from localStorage. Resetting this corrupt localStorage item.`,
        error
      );

      localStorage.removeItem(this.storeKeyEventFilters);
    }
    return null;
  }
}
