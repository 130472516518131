import { SenseModuleContext } from 'Providers/SenseModuleProvider';
import React, { Suspense } from 'react';
import { Canvas } from 'react-three-fiber';
import * as THREE from 'three';
import { useContextBridge } from 'Utils/Helpers';
import Elevator3d from './Elevator3d';

const Elevator3dScene: React.FunctionComponent = () => {

  const [height] = React.useState(500);
  const [width] = React.useState(500);
  const ContextBridge = useContextBridge(SenseModuleContext);

  return (
      <Canvas
        style={{
          width,
          height,
        }}
        camera={{
          zoom: 1.5,
          fov: 45,
          aspect: width / height,
          near: 1,
          far: 1000,
          position: [6.723246296205774, 5.6726894912676205, 4.7558966742579125],
        }}
        pixelRatio={window.devicePixelRatio}
      >
        <directionalLight
          intensity={0.8}
          color={new THREE.Color().setHex(0xeeeeee)}
          position={[-0.33, 0.39, 0.37]}
        />
        <ambientLight
          intensity={0.3}
          color={new THREE.Color().setHex(0xcccccc)}
        />
          <Suspense fallback={<mesh />}>
            <ContextBridge>
              <Elevator3d />
            </ContextBridge>
          </Suspense>
      </Canvas>
  );
};

export default Elevator3dScene;
