import { makeStyles } from "@material-ui/core";
import React from "react";
import { get, isNil, first, keys, values, isNumber } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSenseModule } from "Providers/SenseModuleProvider";

// We still use some material styles here
//
const componentStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    textAlign: "center",
    width: "115px",
    zIndex: 100,
  },
  label: {
    textTransform: "uppercase",
    fontFamily: "Verdana,Geneva,Tahoma,sans-serif",
    fontSize: ".6rem",
  },
  floorIndicator: {
    fontFamily: "Digital-7 Mono",
    fontSize: "7rem",
    lineHeight: "7rem",
    margin: 0,
    width: "107px",
    position: "absolute",
    left: 0,
    top: "10px",
    textAlign: "right",
  },
  floorCurrentBackground: {
    color: "#f5f5f5",
  },
  floorCurrentForeground: {
    color: "#85b19b",
  },
}));

const CurrentStopIndicator = ({ className }: { className?: string }) => {
  const classes = componentStyles();
  const [currentFloor, setCurrentFloor] = React.useState<null | number>(null);
  const senseModuleProvider = useSenseModule();
  const parseSensorPoint = React.useCallback((data: any) => {
    const key = parseInt(first(keys(data)), 10);
    const value = parseInt(first(values(data)), 10);

    if (key === 515) {
      if (!isNaN(value)) {
        setCurrentFloor(value); // Zero based numbers so add one
      } else {
        setCurrentFloor(null);
      }
    }
  }, []);

  // Initial sensor values
  //
  React.useEffect(() => {
    const value = get(senseModuleProvider, "senseModuleSensorData.values.515");
    if (isNumber(value)) {
      setCurrentFloor(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senseModuleProvider.senseModuleSensorData]);

  // When ever the socket emits data handle the sensor data update
  //
  React.useEffect(() => {
    parseSensorPoint(get(senseModuleProvider.movementPoints, "message.data"));
  }, [senseModuleProvider.movementPoints, parseSensorPoint]);

  return (
    <div
      className={`${classes.container} ${isNil(className) ? "" : className}`}
    >
      <strong className={classes.label}>
        <FormattedMessage id="elevator.current.stop" />
      </strong>
      <div
        className={`${classes.floorIndicator} ${classes.floorCurrentBackground}`}
      >
        88
      </div>
      <div
        className={`${classes.floorIndicator} ${classes.floorCurrentForeground}`}
      >
        {isNil(currentFloor) ? "" : currentFloor + 1}
      </div>
    </div>
  );
};

export default CurrentStopIndicator;
